import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, IconButton } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import { getTree } from "../../api";

import { treeResult } from "../../store/Tree/actions";
import { AppState } from "../../store/rootReducer";

import { RenderTree } from "./components/RenderTree";

import {
  Container,
  TreeBody,
  TreeHeader,
  Title,
  TreeView,
  LoadingTreeView,
  DownTree,
} from "./styles";

export const Tree = () => {
  const dispatch = useDispatch();

  const { tree } = useSelector((state: AppState) => state.tree);

  const [showTree, setShowTree] = useState(true);

  useEffect(() => {
    getTree().then((response) => {
      dispatch(treeResult(response.data));
    });
  }, [dispatch]);

  const treeClick = () => {
    setShowTree(!showTree);
  };

  return (
    <Container width={showTree ? "350px" : "40px"}>
      <TreeBody display={showTree ? "flex" : "none"}>
        <TreeHeader>
          <Title>ÁRVORE</Title>

          <IconButton
            style={{
              fontSize: "10px",
              padding: "6px",
              color: "#4CA6A8",
              width: 40,
            }}
            onClick={treeClick}
          >
            <ArrowBack />
          </IconButton>
        </TreeHeader>
        {tree.length === 0 ? (
          <LoadingTreeView>
            <CircularProgress
              style={{
                color: "#4CA6A8",
                margin: "0 auto",
                marginTop: "50px",
                overflow: "none",
              }}
              size={50}
            />
          </LoadingTreeView>
        ) : (
          <TreeView>
            <RenderTree data={tree} />
          </TreeView>
        )}
      </TreeBody>

      <DownTree display={showTree ? "none" : "flex"}>
        <IconButton
          style={{ fontSize: "10px", padding: "6px", color: "#4CA6A8" }}
          onClick={treeClick}
        >
          <ArrowForward />
        </IconButton>
      </DownTree>
    </Container>
  );
};
