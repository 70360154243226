import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr;
  margin-bottom: 10px;
  gap: 10px;

  .textarea {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .drag {
      height: 100%;
      width: 850px;
      border-radius: 8px;
      border: 2px dashed #7f8898;

      div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .double-button {
        display: flex;
        gap: 2px;

        button {
          width: 14px;
          height: 30px;
          margin-top: 0;
          border-radius: 3px;
        }
      }

      button {
        width: 30px;
        height: 30px;
        margin-top: 0;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .duration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    gap: 10px;
    background-color: #e8ebed;
    border-radius: 10px;

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #7f8898;
    }

    .duration-input {
      display: flex;
      align-items: center;
      gap: 3px;

      span {
        font-size: 14px;
        color: #7f8898;
      }
    }

    .detail-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      p {
        font-size: 10px;
        color: #7f8898;
        text-align: center;
        margin-left: 8px;
      }
    }
  }
`;

interface InputProps {
  error: boolean;
}

export const Input = styled.input`
  text-align: center;
  width: 30px;
  height: 30px;
  border: ${(props: InputProps) => (props.error ? "2px solid red" : "0")};
  border-radius: 5px;
  background: #fff;
`;
