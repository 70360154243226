import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../store/rootReducer";
import { setDate } from "../../../../store/Graphic/actions";

import { ComponentData } from "../../../../models/ComponentData";

import {
  datePickerDay,
  datePickerHour,
  datePickerMonth,
  datePickerWeek,
} from "../../../../utils/buttonsDatePicker";

import MockCaldeira1Hourly from "../../../../mocks/caldeira1Hourly.json";
import MockCaldeira2Hourly from "../../../../mocks/caldeira2Hourly.json";
import MockCaldeira3Hourly from "../../../../mocks/caldeira3Hourly.json";
import MockCaldeira4Hourly from "../../../../mocks/caldeira4Hourly.json";

import MockCaldeira1Daily from "../../../../mocks/caldeira1Daily.json";
import MockCaldeira2Daily from "../../../../mocks/caldeira2Daily.json";
import MockCaldeira3Daily from "../../../../mocks/caldeira3Daily.json";
import MockCaldeira4Daily from "../../../../mocks/caldeira4Daily.json";

import MockCaldeira1Weekly from "../../../../mocks/caldeira1Weekly.json";
import MockCaldeira2Weekly from "../../../../mocks/caldeira2Weekly.json";
import MockCaldeira3Weekly from "../../../../mocks/caldeira3Weekly.json";
import MockCaldeira4Weekly from "../../../../mocks/caldeira4Weekly.json";

import MockCaldeira1Monthly from "../../../../mocks/caldeira1Monthly.json";
import MockCaldeira2Monthly from "../../../../mocks/caldeira2Monthly.json";
import MockCaldeira3Monthly from "../../../../mocks/caldeira3Monthly.json";
import MockCaldeira4Monthly from "../../../../mocks/caldeira4Monthly.json";

import { GraphicEquipment } from "../../../../components/GraphicEquipment";
import { BasePicker } from "../../../../components/BasePicker";

import { Container, BasesAndTitle, Bases, Filter, Title } from "./styles";
import { PerformanceDatePicker } from "../../../../components/PerformanceDatePicker";

export const PerformanceEquipment = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  const dispatch = useDispatch();

  const initialDate = useSelector(
    (state: AppState) => state.graphic.date.initial
  );
  const finalDate = useSelector((state: AppState) => state.graphic.date.final);

  const initialState = "24h";
  const [dateClicked, setDateClicked] = useState(initialState);

  const [baseClicked, setBaseClicked] = useState("hora");
  const [datePicker, setDatePicker] = useState(datePickerHour);

  const handleDatePicker = (value: string) => {
    dispatch(setDate(`now-${value}`, "now"));
  };

  const handleDateColor = (value: string) => {
    setDateClicked(value);
  };

  const handleBaseColor = (value: string) => {
    setBaseClicked(value);
  };

  useEffect(() => {
    tabAccessed("PERFORMANCE");
  });

  useEffect(() => {
    dispatch(setDate(`now-${initialState}`, "now"));
  }, [dispatch, initialState]);

  useEffect(() => {
    if (baseClicked === "hora") {
      setDatePicker(datePickerHour);
      setDateClicked("24h");
      dispatch(setDate(`now-${initialState}`, "now"));
    } else if (baseClicked === "dia") {
      setDatePicker(datePickerDay);
      setDateClicked("7d");
      dispatch(setDate(`now-7d`, "now"));
    } else if (baseClicked === "semana") {
      setDatePicker(datePickerWeek);
      setDateClicked("1M");
      dispatch(setDate(`now-1M`, "now"));
    } else if (baseClicked === "mes") {
      setDatePicker(datePickerMonth);
      setDateClicked("3M");
      dispatch(setDate(`now-3M`, "now"));
    }
  }, [baseClicked, dispatch]);

  const dataGraphicHourly = () => {
    if (componentName === "Caldeira 1") {
      return MockCaldeira1Hourly;
    } else if (componentName === "Caldeira 2") {
      return MockCaldeira2Hourly;
    } else if (componentName === "Caldeira 3") {
      return MockCaldeira3Hourly;
    } else {
      return MockCaldeira4Hourly;
    }
  };

  const dataGraphicDaily = () => {
    if (componentName === "Caldeira 1") {
      return MockCaldeira1Daily;
    } else if (componentName === "Caldeira 2") {
      return MockCaldeira2Daily;
    } else if (componentName === "Caldeira 3") {
      return MockCaldeira3Daily;
    } else {
      return MockCaldeira4Daily;
    }
  };

  const dataGraphicWeekly = () => {
    if (componentName === "Caldeira 1") {
      return MockCaldeira1Weekly;
    } else if (componentName === "Caldeira 2") {
      return MockCaldeira2Weekly;
    } else if (componentName === "Caldeira 3") {
      return MockCaldeira3Weekly;
    } else {
      return MockCaldeira4Weekly;
    }
  };

  const dataGraphicMonthly = () => {
    if (componentName === "Caldeira 1") {
      return MockCaldeira1Monthly;
    } else if (componentName === "Caldeira 2") {
      return MockCaldeira2Monthly;
    } else if (componentName === "Caldeira 3") {
      return MockCaldeira3Monthly;
    } else {
      return MockCaldeira4Monthly;
    }
  };

  return (
    <Container>
      <Title>{componentName}</Title>
      <Filter>
        <BasesAndTitle>
          <Bases>
            <p>Bases da regressão: </p>
            <BasePicker
              baseClicked={baseClicked}
              handleBaseColor={handleBaseColor}
            />
          </Bases>
        </BasesAndTitle>
        <PerformanceDatePicker
          data={datePicker}
          handleDatePicker={handleDatePicker}
          handleDateColor={handleDateColor}
          dateClicked={dateClicked}
        />
      </Filter>

      {baseClicked === "hora" && (
        <GraphicEquipment
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={dataGraphicHourly()}
          base={"hora"}
        />
      )}

      {baseClicked === "dia" && (
        <GraphicEquipment
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={dataGraphicDaily()}
          base={baseClicked}
        />
      )}

      {baseClicked === "semana" && (
        <GraphicEquipment
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={dataGraphicWeekly()}
          base={baseClicked}
        />
      )}

      {baseClicked === "mes" && (
        <GraphicEquipment
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={dataGraphicMonthly()}
          base={baseClicked}
        />
      )}
    </Container>
  );
};
