export const pciAndCoeficientInitialSimulation = [
  {
    title: "PCI",
    table: [
      {
        name: "Caldeira 1",
        cells: [
          {
            name: "C1PCIGAC",
            value: "0",
          },

          {
            name: "C1PCIGAF",
            value: "0",
          },

          {
            name: "C1PCIGCO",
            value: "0",
          },
          {
            name: "C1PCIOleo",
            value: "0",
          },

        ],
      },
      {
        name: "Caldeira 2",
        cells: [
          {
            name: "C2PCIGAC",
            value: "0",
          },
          {
            name: "C2PCIGAF",
            value: "0",
          },
          {
            name: "C2PCIGCO",
            value: "0",
          },
          {
            name: "C2PCIOleo",
            value: "0",
          },
        ],
      },
      {
        name: "Caldeira 3",
        cells: [
          {
            name: "C3PCIGAC",
            value: "0",
          },
          {
            name: "C3PCIGAF",
            value: "0",
          },
          {
            name: "C3PCIGCO",
            value: "0",
          },
          {
            name: "C3PCIOleo",
            value: "0",
          },
        ],
      },
      {
        name: "Caldeira 4",
        cells: [
          {
            name: "C4PCIGAC",
            value: "0",
          },
          {
            name: "C4PCIGAF",
            value: "0",
          },
          {
            name: "C4PCIGCO",
            value: "0",
          },
          {
            name: "C4PCIGN",
            value: "0",
          },
        ],
      },
    ],
  },
  {
    title: "COEFFICIENT",
    table: [
      {
        name: "Caldeira 1",
        cells: [
          {
            name: "C1COEFGAC",
            value: "0",
          },
          {
            name: "C1COEFGAF",
            value: "0",
          },
          {
            name: "C1COEFGCO",
            value: "0",
          },
          {
            name: "C1COEFOleo",
            value: "0",
          },
        ],
      },
      {
        name: "Caldeira 2",
        cells: [
          {
            name: "C2COEFGAC",
            value: "0",
          },
          {
            name: "C2COEFGAF",
            value: "0",
          },
          {
            name: "C2COEFGCO",
            value: "0",
          },
          {
            name: "C2COEFOleo",
            value: "0",
          },
        ],
      },
      {
        name: "Caldeira 3",
        cells: [
          {
            name: "C3COEFGAC",
            value: "0",
          },
          {
            name: "C3COEFGAF",
            value: "0",
          },
          {
            name: "C3COEFGCO",
            value: "0",
          },
          {
            name: "C3COEFOleo",
            value: "0",
          },
        ],
      },
      {
        name: "Caldeira 4",
        cells: [
          {
            name: "C4COEFGAC",
            value: "0",
          },
          {
            name: "C4COEFGAF",
            value: "0",
          },
          {
            name: "C4COEFGCO",
            value: "0",
          },
          {
            name: "C4COEFGN",
            value: "0",
          },
        ],
      },
    ],
  },
];

export const pciAndCoeficientInitialDecisionMaking = [
  {
    title: "PCI",
    table: [
      {
        name: "Caldeira 1",
        cells: [
          {
            name: "C1PCIGAC",
            value: 0,
          },
          {
            name: "C1PCIGAF",
            value: 0,
          },
          {
            name: "C1PCIGCO",
            value: 0,
          },
          {
            name: "C1PCIOleo",
            value: 0,
          },
        ],
      },
      {
        name: "Caldeira 2",
        cells: [
          {
            name: "C2PCIGAC",
            value: 0,
          },
          {
            name: "C2PCIGAF",
            value: 0,
          },
          {
            name: "C2PCIGCO",
            value: 0,
          },
          {
            name: "C2PCIOleo",
            value: 0,
          },
        ],
      },
      {
        name: "Caldeira 3",
        cells: [
          {
            name: "C3PCIGAC",
            value: 0,
          },
          {
            name: "C3PCIGAF",
            value: 0,
          },
          {
            name: "C3PCIGCO",
            value: 0,
          },
          {
            name: "C3PCIOleo",
            value: 0,
          },
        ],
      },
      {
        name: "Caldeira 4",
        cells: [
          {
            name: "C4PCIGAC",
            value: 0,
          },
          {
            name: "C4PCIGAF",
            value: 0,
          },
          {
            name: "C4PCIGCO",
            value: 0,

          },
          {
            name: "C4PCIGN",
            value: 0,
          },
        ],
      },
    ],
  },
  {
    title: "COEFFICIENT",
    table: [
      {
        name: "Caldeira 1",
        cells: [
          {
            name: "C1COEFGAC",
            value: 0,
          },
          {
            name: "C1COEFGAF",
            value: 0,
          },
          {
            name: "C1COEFGCO",
            value: 0,
          },
          {
            name: "C1COEFOleo",
            value: 0,
          },
          {
            name: "C1R2",
            value: 0,
          },
        ],
      },
      {
        name: "Caldeira 2",
        cells: [
          {
            name: "C2COEFGAC",
            value: 0,
          },
          {
            name: "C2COEFGAF",
            value: 0,
          },
          {
            name: "C2COEFGCO",
            value: 0,
          },
          {
            name: "C2COEFOleo",
            value: 0,
          },
          {
            name: "C2R2",
            value: 0,
          },
        ],
      },
      {
        name: "Caldeira 3",
        cells: [
          {
            name: "C3COEFGAC",
            value: 0,
          },
          {
            name: "C3COEFGAF",
            value: 0,
          },
          {
            name: "C3COEFGCO",
            value: 0,
          },
          {
            name: "C3COEFOleo",
            value: 0,
          },
          {
            name: "C3R2",
            value: 0,
          },
        ],
      },
      {
        name: "Caldeira 4",
        cells: [
          {
            name: "C4COEFGAC",
            value: 0,
          },
          {
            name: "C4COEFGAF",
            value: 0,
          },
          {
            name: "C4COEFGCO",
            value: 0,
          },
          {
            name: "C4COEFGN",
            value: 0,
          },
          {
            name: "C4R2",
            value: 0,
          },
        ],
      },
    ],
  },
];
