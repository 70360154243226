import { NotificationData, RuleActionData, RuleData } from "../../models/NotificationData";

export interface NotificationRuleState {
  notifications: NotificationData[];
  typeNotification: string;
  rules: RuleData[];
  actionRule: ActionRuleProps;
}

export interface ActionRuleProps {
  id: number | undefined;
  typeAction: string;
  rule: RuleActionData;
}

export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const SET_TYPE_NOTIFICATIONS = "SET_TYPE_NOTIFICATIONS";
export const LIST_RULES = "LIST_RULES";
export const ACTION_RULE = "ACTION_RULE";

export interface ListNotifications {
  type: typeof LIST_NOTIFICATIONS;
  payload: NotificationData[];
}

export interface SetTypeNotifications {
  type: typeof SET_TYPE_NOTIFICATIONS;
  payload: string;
}

export interface ListRules {
  type: typeof LIST_RULES;
  payload: RuleData[];
}

export interface ActionRule {
  type: typeof ACTION_RULE;
  payload: ActionRuleProps;
}

export type NotificationsAndRuleDispatchTypes =
  | ListNotifications
  | SetTypeNotifications
  | ListRules
  | ActionRule;
