import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  h1 {
    font-size: 20px;
  }
`;
