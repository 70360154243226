import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 300,
      position: "absolute",
      zIndex: 1,
      right: "30px",
      top: 0,
      marginTop: "20px",
    },
  })
);
