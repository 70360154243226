export const equipmentOptions = [
  {
    value: "Caldeiras",
    label: "Caldeiras",
  },
  { value: "C1", label: "Caldeira 1" },
  { value: "C2", label: "Caldeira 2" },
  { value: "C3", label: "Caldeira 3" },
  { value: "C4", label: "Caldeira 4" },
];

export const variableOptions = {
  Caldeiras: [
    { value: "CONSUMOESPTOTAL", label: "Consumo Específico Total" },
    { value: "VAZAOGNTOTAL", label: "Vazão - GN Total" },
  ],
  C1: [
    { value: "C1PCIGCO", label: "PCI - GCO" },
    { value: "C1PCIGAF", label: "PCI - GAF" },
    { value: "C1PCIOL", label: "PCI - Óleo" },
    { value: "C1PCIMISTOGCO", label: "PCI Misto - GCO" },
    { value: "C1VAZAOGCO", label: "Vazão - GCO" },
    { value: "C1VAZAOGAF", label: "Vazão - GAF" },
    { value: "C1VAZAOGAC", label: "Vazão - GAC" },
    { value: "C1VAZAOOL", label: "Vazão - Óleo" },
    { value: "C1ENERGIAGCO", label: "Energia - GCO" },
    { value: "C1ENERGIAGAF", label: "Energia - GAF" },
    { value: "C1ENERGIAGAC", label: "Energia - GAC" },
    { value: "C1ENERGIAOL", label: "Energia - Óleo" },
    { value: "C1COEFGCO_HORARIO", label: "Coeficiente Horário - GCO" },
    { value: "C1COEFGAF_HORARIO", label: "Coeficiente Horário - GAF" },
    { value: "C1COEFGAC_HORARIO", label: "Coeficiente Horário - GAC" },
    { value: "C1COEFOL_HORARIO", label: "Coeficiente Horário - Óleo" },
    { value: "C1COEFGCO_DIARIO", label: "Coeficiente Diário - GCO" },
    { value: "C1COEFGAF_DIARIO", label: "Coeficiente Diário - GAF" },
    { value: "C1COEFGAC_DIARIO", label: "Coeficiente Diário - GAC" },
    { value: "C1COEFOL_DIARIO", label: "Coeficiente Diário - Óleo" },
    { value: "C1COEFGCO_SEMANAL", label: "Coeficiente Semanal - GCO" },
    { value: "C1COEFGAF_SEMANAL", label: "Coeficiente Semanal - GAF" },
    { value: "C1COEFGAC_SEMANAL", label: "Coeficiente Semanal - GAC" },
    { value: "C1COEFOL_SEMANAL", label: "Coeficiente Semanal - Óleo" },
    { value: "C1COEFGCO_MENSAL", label: "Coeficiente Mensal - GCO" },
    { value: "C1COEFGAF_MENSAL", label: "Coeficiente Mensal - GAF" },
    { value: "C1COEFGAC_MENSAL", label: "Coeficiente Mensal - GAC" },
    { value: "C1COEFOL_MENSAL", label: "Coeficiente Mensal - Óleo" },
    { value: "C1R2_HORARIO", label: "R² Horário" },
    { value: "C1R2_DIARIO", label: "R² Diário" },
    { value: "C1R2_SEMANAL", label: "R² Semanal" },
    { value: "C1R2_MENSAL", label: "R² Mensal" },
    {
      value: "C1VAZAOVAPOR_PRED_HORARIA",
      label: "Vazão de Vapor Horária Predita",
    },
    {
      value: "C1VAZAOVAPOR_PRED_DIARIA",
      label: "Vazão de Vapor Diária Predita",
    },
    {
      value: "C1VAZAOVAPOR_PRED_SEMANAL",
      label: "Vazão de Vapor Semanal Predita",
    },
    {
      value: "C1VAZAOVAPOR_PRED_MENSAL",
      label: "Vazão de Vapor Mensal Predita",
    },
    { value: "C1ESTABGCO", label: "Estabilizador - GCO" },
    { value: "C1VAZAOVAPOR", label: "Vazão de Vapor Principal" },
    { value: "C1O2", label: "Concentração de O2" },
    { value: "C1CONSUMOESP", label: "Consumo Específico" },
  ],
  C2: [
    { value: "C2PCIGCO", label: "PCI - GCO" },
    { value: "C2PCIGAF", label: "PCI - GAF" },
    { value: "C2PCIOL", label: "PCI - Óleo" },
    { value: "C2PCIMISTOGCO", label: "PCI Misto - GCO" },
    { value: "C2VAZAOGCO", label: "Vazão - GCO" },
    { value: "C2VAZAOGAF", label: "Vazão - GAF" },
    { value: "C2VAZAOGAC", label: "Vazão - GAC" },
    { value: "C2VAZAOOL", label: "Vazão - Óleo" },
    { value: "C2ENERGIAGCO", label: "Energia - GCO" },
    { value: "C2ENERGIAGAF", label: "Energia - GAF" },
    { value: "C2ENERGIAGAC", label: "Energia - GAC" },
    { value: "C2ENERGIAOL", label: "Energia - Óleo" },
    { value: "C2COEFGCO_HORARIO", label: "Coeficiente Horário - GCO" },
    { value: "C2COEFGAF_HORARIO", label: "Coeficiente Horário - GAF" },
    { value: "C2COEFGAC_HORARIO", label: "Coeficiente Horário - GAC" },
    { value: "C2COEFOL_HORARIO", label: "Coeficiente Horário - Óleo" },
    { value: "C2COEFGCO_DIARIO", label: "Coeficiente Diário - GCO" },
    { value: "C2COEFGAF_DIARIO", label: "Coeficiente Diário - GAF" },
    { value: "C2COEFGAC_DIARIO", label: "Coeficiente Diário - GAC" },
    { value: "C2COEFOL_DIARIO", label: "Coeficiente Diário - Óleo" },
    { value: "C2COEFGCO_SEMANAL", label: "Coeficiente Semanal - GCO" },
    { value: "C2COEFGAF_SEMANAL", label: "Coeficiente Semanal - GAF" },
    { value: "C2COEFGAC_SEMANAL", label: "Coeficiente Semanal - GAC" },
    { value: "C2COEFOL_SEMANAL", label: "Coeficiente Semanal - Óleo" },
    { value: "C2COEFGCO_MENSAL", label: "Coeficiente Mensal - GCO" },
    { value: "C2COEFGAF_MENSAL", label: "Coeficiente Mensal - GAF" },
    { value: "C2COEFGAC_MENSAL", label: "Coeficiente Mensal - GAC" },
    { value: "C2COEFOL_MENSAL", label: "Coeficiente Mensal - Óleo" },
    { value: "C2R2_HORARIO", label: "R² Horário" },
    { value: "C2R2_DIARIO", label: "R² Diário" },
    { value: "C2R2_SEMANAL", label: "R² Semanal" },
    { value: "C2R2_MENSAL", label: "R² Mensal" },
    {
      value: "C2VAZAOVAPOR_PRED_HORARIA",
      label: "Vazão de Vapor Horária Predita",
    },
    {
      value: "C2VAZAOVAPOR_PRED_DIARIA",
      label: "Vazão de Vapor Diária Predita",
    },
    {
      value: "C2VAZAOVAPOR_PRED_SEMANAL",
      label: "Vazão de Vapor Semanal Predita",
    },
    {
      value: "C2VAZAOVAPOR_PRED_MENSAL",
      label: "Vazão de Vapor Mensal Predita",
    },
    { value: "C2ESTABGCO", label: "Estabilizador - GCO" },
    { value: "C2VAZAOVAPOR", label: "Vazão de Vapor Principal" },
    { value: "C2O2", label: "Concentração de O2" },
    { value: "C2CONSUMOESP", label: "Consumo Específico" },
  ],
  C3: [
    { value: "C3PCIGCO", label: "PCI - GCO" },
    { value: "C3PCIGAF", label: "PCI - GAF" },
    { value: "C3PCIOL", label: "PCI - Óleo" },
    { value: "C3PCIMISTOGCO", label: "PCI Misto - GCO" },
    { value: "C3VAZAOGCO", label: "Vazão - GCO" },
    { value: "C3VAZAOGAF", label: "Vazão - GAF" },
    { value: "C3VAZAOGAC", label: "Vazão - GAC" },
    { value: "C3VAZAOOL", label: "Vazão - Óleo" },
    { value: "C3ENERGIAGCO", label: "Energia - GCO" },
    { value: "C3ENERGIAGAF", label: "Energia - GAF" },
    { value: "C3ENERGIAGAC", label: "Energia - GAC" },
    { value: "C3ENERGIAOL", label: "Energia - Óleo" },
    { value: "C3COEFGCO_HORARIO", label: "Coeficiente Horário - GCO" },
    { value: "C3COEFGAF_HORARIO", label: "Coeficiente Horário - GAF" },
    { value: "C3COEFGAC_HORARIO", label: "Coeficiente Horário - GAC" },
    { value: "C3COEFOL_HORARIO", label: "Coeficiente Horário - Óleo" },
    { value: "C3COEFGCO_DIARIO", label: "Coeficiente Diário - GCO" },
    { value: "C3COEFGAF_DIARIO", label: "Coeficiente Diário - GAF" },
    { value: "C3COEFGAC_DIARIO", label: "Coeficiente Diário - GAC" },
    { value: "C3COEFOL_DIARIO", label: "Coeficiente Diário - Óleo" },
    { value: "C3COEFGCO_SEMANAL", label: "Coeficiente Semanal - GCO" },
    { value: "C3COEFGAF_SEMANAL", label: "Coeficiente Semanal - GAF" },
    { value: "C3COEFGAC_SEMANAL", label: "Coeficiente Semanal - GAC" },
    { value: "C3COEFOL_SEMANAL", label: "Coeficiente Semanal - Óleo" },
    { value: "C3COEFGCO_MENSAL", label: "Coeficiente Mensal - GCO" },
    { value: "C3COEFGAF_MENSAL", label: "Coeficiente Mensal - GAF" },
    { value: "C3COEFGAC_MENSAL", label: "Coeficiente Mensal - GAC" },
    { value: "C3COEFOL_MENSAL", label: "Coeficiente Mensal - Óleo" },
    { value: "C3R2_HORARIO", label: "R² Horário" },
    { value: "C3R2_DIARIO", label: "R² Diário" },
    { value: "C3R2_SEMANAL", label: "R² Semanal" },
    { value: "C3R2_MENSAL", label: "R² Mensal" },
    {
      value: "C3VAZAOVAPOR_PRED_HORARIA",
      label: "Vazão de Vapor Horária Predita",
    },
    {
      value: "C3VAZAOVAPOR_PRED_DIARIA",
      label: "Vazão de Vapor Diária Predita",
    },
    {
      value: "C3VAZAOVAPOR_PRED_SEMANAL",
      label: "Vazão de Vapor Semanal Predita",
    },
    {
      value: "C3VAZAOVAPOR_PRED_MENSAL",
      label: "Vazão de Vapor Mensal Predita",
    },
    { value: "C3ESTABGCO", label: "Estabilizador - GCO" },
    { value: "C3VAZAOVAPOR", label: "Vazão de Vapor Principal" },
    { value: "C3O2", label: "Concentração de O2" },
    { value: "C3CONSUMOESP", label: "Consumo Específico" },
  ],
  C4: [
    { value: "C4PCIGCO", label: "PCI - GCO" },
    { value: "C4PCIGAF", label: "PCI - GAF" },
    { value: "C4PCIGN", label: "PCI - GN" },
    { value: "C4PCIMISTOGCO", label: "PCI Misto - GCO" },
    { value: "C4VAZAOGCO", label: "Vazão - GCO" },
    { value: "C4VAZAOGAF", label: "Vazão - GAF" },
    { value: "C4VAZAOGAC", label: "Vazão - GAC" },
    { value: "C4VAZAOGN", label: "Vazão - GN" },
    { value: "C4ENERGIAGCO", label: "Energia - GCO" },
    { value: "C4ENERGIAGAF", label: "Energia - GAF" },
    { value: "C4ENERGIAGAC", label: "Energia - GAC" },
    { value: "C4ENERGIAGN", label: "Energia - GN" },
    { value: "C4COEFGCO_HORARIO", label: "Coeficiente Horário - GCO" },
    { value: "C4COEFGAF_HORARIO", label: "Coeficiente Horário - GAF" },
    { value: "C4COEFGAC_HORARIO", label: "Coeficiente Horário - GAC" },
    { value: "C4COEFGN_HORARIO", label: "Coeficiente Horário - GN" },
    { value: "C4COEFGCO_DIARIO", label: "Coeficiente Diário - GCO" },
    { value: "C4COEFGAF_DIARIO", label: "Coeficiente Diário - GAF" },
    { value: "C4COEFGAC_DIARIO", label: "Coeficiente Diário - GAC" },
    { value: "C4COEFGN_DIARIO", label: "Coeficiente Diário - GN" },
    { value: "C4COEFGCO_SEMANAL", label: "Coeficiente Semanal - GCO" },
    { value: "C4COEFGAF_SEMANAL", label: "Coeficiente Semanal - GAF" },
    { value: "C4COEFGAC_SEMANAL", label: "Coeficiente Semanal - GAC" },
    { value: "C4COEFGN_SEMANAL", label: "Coeficiente Semanal - GN" },
    { value: "C4COEFGCO_MENSAL", label: "Coeficiente Mensal - GCO" },
    { value: "C4COEFGAF_MENSAL", label: "Coeficiente Mensal - GAF" },
    { value: "C4COEFGAC_MENSAL", label: "Coeficiente Mensal - GAC" },
    { value: "C4COEFGN_MENSAL", label: "Coeficiente Mensal - GN" },
    { value: "C4R2_HORARIO", label: "R² Horário" },
    { value: "C4R2_DIARIO", label: "R² Diário" },
    { value: "C4R2_SEMANAL", label: "R² Semanal" },
    { value: "C4R2_MENSAL", label: "R² Mensal" },
    {
      value: "C4VAZAOVAPOR_PRED_HORARIA",
      label: "Vazão de Vapor Horária Predita",
    },
    {
      value: "C4VAZAOVAPOR_PRED_DIARIA",
      label: "Vazão de Vapor Diária Predita",
    },
    {
      value: "C4VAZAOVAPOR_PRED_SEMANAL",
      label: "Vazão de Vapor Semanal Predita",
    },
    {
      value: "C4VAZAOVAPOR_PRED_MENSAL",
      label: "Vazão de Vapor Mensal Predita",
    },
    { value: "C4ESTABGCO", label: "Estabilizador - GCO" },
    { value: "C4VAZAOVAPOR", label: "Vazão de Vapor Principal" },
    { value: "C4O2", label: "Concentração de O2" },
    { value: "C4CONSUMOESP", label: "Consumo Específico" },
  ],
};

export const arithmeticOperators = [
  { value: "+", label: "Adição" },
  { value: "-", label: "Subtração" },
  { value: "*", label: "Multiplicação" },
  { value: "/", label: "Divisão" },
  { value: "**", label: "Potência" },
  { value: "%", label: "Módulo" },
];

export const logicalOperators = [
  { value: "and", label: "E" },
  { value: "or", label: "OU" },
];

export const relationalOperators = [
  { value: "<", label: "Menor que" },
  { value: ">", label: "Maior que" },
  { value: "==", label: "Igual a" },
  { value: "!=", label: "Diferente de" },
  { value: "<=", label: "Menor ou igual a" },
  { value: ">=", label: "Maior ou igual a" },
];
