import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 22px;
  border-radius: 10px;
  padding-bottom: 15px;

  .param-content,
  .operator-content {
    display: flex;
    height: 170px;
    gap: 10px;
    background: #e8ebed;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
  }

  .const-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e8ebed;
    height: 170px;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    gap: 30px;
  }

  .typeOperator {
    .separator {
      width: 100%;
      height: 100%;
    }
  }

  .param .changeBreak,
  .param .typeParam,
  .param .changeParam,
  .operator .typeOperator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .separator {
      border: 0.3px solid #7f8898;
      border-radius: 5px;

      padding: 10px;

      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    label {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #7f8898;
      }
    }

    input[type="radio"] {
      width: 20px;
      height: 20px;
    }
  }

  .operador .changeOperator,
  .param .changeParam {
    label {
      justify-content: space-between;
    }
  }

  .param .changeParam {
    display: flex;
    gap: 25px;
  }

  .changeBreak {
    .buttons {
      display: flex;
      gap: 5px;

      button {
        background: transparent;
        border: 1px solid #7f8898;
        color: #7f8898;
        width: 30px;
        height: 20px;
        border-radius: 3px;
        font-size: 10px;
        margin-top: 0;
      }

      .active {
        background: #7f8898;
        color: #fff;
        width: 30px;
        height: 20px;
        border-radius: 3px;
        font-size: 10px;
        margin-top: 0;
      }
    }

    p {
      text-align: center;
      width: 100%;
      font-size: 10px;
      font-weight: 400;
      color: #08a8ba;
      background: #fff;
      border-radius: 3px;
      padding: 8px 0;
      margin-top: 10px;
    }
  }

  .operator .changeOperator {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    .separator {
      border: 0.3px solid #7f8898;
      border-radius: 5px;

      padding: 20px 10px;
    }

    p {
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      color: #08a8ba;
      background: #fff;
      border-radius: 3px;
      padding: 5px 0;
    }
  }

  .disableChangeBreak {
    display: none;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: #7f8898;
  }

  input,
  select {
    width: 100%;
    height: 30px;

    border: 0;
    border-radius: 5px;

    padding: 5px;
  }

  select {
    width: 100px;
  }

  .selectTotal {
    width: 200px;
  }

  .adicionar {
    width: 100%;
    height: 37px;
    border: 0;
    border-radius: 0px 0px 10px 10px;
    background: linear-gradient(90deg, #5fc787 0%, #1cb1c4 100%);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 0px;
  }
`;
