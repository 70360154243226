import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { dateSimulation } from "../../../../../../store/Simulation/actions";

import { ComponentSimulationData } from "../../../../../../models/ComponentData";

import { SimulationDatePicker } from "../../../../../../components/SimulationDatePicker";
import { GradientButton } from "../../../../../../components/GradientButton";

import { TablesSimulation1 } from "../../../../../../components/TablesSimulation1";
import { TablesSimulation2 } from "../../../../../../components/TablesSimulation2";

import { Buttons, Container, Filter, Title, TitleSimulation } from "./styles";
import { lastWeekPeriodOfTime } from "../../../../../../utils/dateManager";

export const PciAndCoeficientTable = ({
  componentName,
  handleTab,
}: ComponentSimulationData) => {
  const dispatch = useDispatch();

  const initialState = "InitialDate";
  const [dateClicked, setDateClicked] = useState(initialState);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    const arrayDatePeriod =  lastWeekPeriodOfTime();

    dispatch(dateSimulation(arrayDatePeriod[0].toString(), arrayDatePeriod[1].toString()));
  }, [dispatch]);

  const handleDatePicker = (value: string) => {
    const arrayDatePeriod = lastWeekPeriodOfTime();

    dispatch(dateSimulation(arrayDatePeriod[0].toString(), arrayDatePeriod[1].toString()));
  };

  const handleDateColor = (value: string) => {
    setDateClicked(value);
  };

  const handleTransfer = () => {
    setLoading2(true);
    setTimeout(() => {
      setLoading2(false);
    }, 2000);
  };

  const handleLoader = (value: boolean) => {
    setLoading(value);
  };

  return (
    <Container>
      <Filter>
        <Title>{componentName}</Title>
        <SimulationDatePicker
          handleDatePicker={handleDatePicker}
          handleDateColor={handleDateColor}
          dateClicked={dateClicked}
        />
      </Filter>

      <TitleSimulation>CONFIGURAÇÃO DO MODELO</TitleSimulation>

      <TablesSimulation1 handleLoader={handleLoader} loading={loading2} />
      <GradientButton onClick={handleTransfer} disabled={loading}>
        Transferir
      </GradientButton>
      <TablesSimulation2 loading={loading2} />

      <Buttons>
        <div></div>
        <GradientButton onClick={() => handleTab("entries")} disabled={loading}>
          Continuar
        </GradientButton>
      </Buttons>
    </Container>
  );
};
