import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as AlertIcon } from "../../assets/alertIcon.svg";
import { RuleActionData } from "../../models/NotificationData";
import { actionRule } from "../../store/Alert/actions";
import { AppState } from "../../store/rootReducer";

import { Container } from "./styles";

export const CreateAlert = () => {
  const dispatch = useDispatch();

  const { rule, typeAction, id } = useSelector(
    (state: AppState) => state.notificationRule.actionRule
  );

  const frequencyOptions = [
    { value: "minute", label: "Minuto" },
    { value: "hourly", label: "Horária" },
    { value: "daily", label: "Diária" },
    { value: "weekly", label: "Semanal" },
    { value: "monthly", label: "Mensal" },
  ];
  const [ruleData, setRuleData] = useState<RuleActionData>(rule);

  const handleCriticality = (value: string) => {
    setRuleData({
      ...ruleData,
      criticality: value,
    });
  };

  const handleTitle = (value: string) => {
    setRuleData({
      ...ruleData,
      title: value,
    });
  };

  const handleMessage = (value: string) => {
    setRuleData({
      ...ruleData,
      message: value,
    });
  };

  const handleFrequency = (value: string) => {
    setRuleData({
      ...ruleData,
      frequency: value,
    });
  };

  useEffect(() => {
    dispatch(
      actionRule({
        id: id,
        typeAction: typeAction,
        rule: {
          criticality: ruleData.criticality,
          title: ruleData.title,
          message: ruleData.message,
          expression: rule.expression,
          duration: rule.duration,
          variables: rule.variables,
          functions: rule.functions,
          frequency: ruleData.frequency || "minute"
        },
      })
    );
  }, [
    dispatch,
    ruleData,
    typeAction,
    rule.expression,
    rule.duration,
    rule.variables,
    rule.functions,
    rule.frequency,
    id,
  ]);

  return (
    <Container>
      <div className="criticality">
        <h3>Criticidade</h3>
        <label>
          <input
            id="Baixa"
            name="base"
            type="radio"
            value="Baixa"
            defaultChecked={ruleData.criticality === "Baixa"}
            onClick={(e) => handleCriticality(e.currentTarget.value)}
          />
          <p>
            Baixa
            <AlertIcon width="15px" height="15px" fill="#009933" />
          </p>
        </label>
        <label>
          <input
            id="Media"
            name="base"
            type="radio"
            value="Media"
            defaultChecked={ruleData.criticality === "Media"}
            onClick={(e) => handleCriticality(e.currentTarget.value)}
          />
          <p>
            Média
            <AlertIcon width="15px" height="15px" fill="#FF6600" />
          </p>
        </label>
        <label>
          <input
            id="Alta"
            name="base"
            type="radio"
            value="Alta"
            defaultChecked={ruleData.criticality === "Alta"}
            onClick={(e) => handleCriticality(e.currentTarget.value)}
          />
          <p>
            Alta
            <AlertIcon width="15px" height="15px" fill="#FF0000" />
          </p>
        </label>
      </div>

      <div className="message">
        <h3>Frequência</h3>
        <select
          name="frequency"
          id="frequency"
          defaultValue={ruleData.frequency}
          defaultChecked
          onChange={(e) => {
            handleFrequency(e.target.value);
          }}
        >
          {frequencyOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>

      <div className="title">
        <h3>Título</h3>
        <textarea
          value={ruleData.title}
          onChange={(e) => handleTitle(e.currentTarget.value)}
          maxLength={20}
        />
      </div>

      <div className="message">
        <h3>Mensagem</h3>
        <textarea
          value={ruleData.message}
          onChange={(e) => handleMessage(e.currentTarget.value)}
          maxLength={108}
        />
      </div>
    </Container>
  );
};
