import axios from "axios";

import { getTokenFromFirebase } from "./firebase";

export const api = axios.create({
  baseURL: process.env.REACT_APP_TWIN_CORE_API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = await getTokenFromFirebase();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const getTree = () => api.get("/tree/Gerdau");

export const getTags = (identifierTree: string) =>
  api.get(`/tag/list/${identifierTree}`);

export const getCoeficientAndPCI = (initial: string, final: string) =>
  api.get(
    `/simulation/tables?startDate=${initial}&endDate=${final}&company=Gerdau`
  );

export const getCoeficient = (initial: string, final: string) =>
  api.get(
    `/simulation/coefficient?startDate=${initial}&endDate=${final}&company=Gerdau`
  );

export const getPCI = (initial: string, final: string) =>
  api.get(
    `/simulation/pci?startDate=${initial}&endDate=${final}&company=Gerdau`
  );

export const getResultSimulation = (data: any) =>
  api.post("/simulation/calculate", data);

export const getPriorizationRealTime = (data: any) =>
  api.post("/priorization/realtime", data);

export const getOptimizationRealTime = (data: any) =>
  api.post("/optimization/realtime", data);

export const getOptimizationDemand = (data: any) =>
  api.post("/optimization/demand", data);

export const createRule = (identifierTree: string, data: any) =>
  api.post(`/rule/create/${identifierTree}`, data);

export const updateRule = async (idRule: number, data: any) => {
  return await api.put(`/rule/update/${idRule}`, data);
};

export const deleteRule = (idRule: number) =>
  api.delete(`/rule/delete/${idRule}`);

export const getRules = (identifierTree: string) =>
  api.get(`/rule/findAll/${identifierTree}`);

export const updateNotification = async (idNotification: number, data: any) => {
  return await api.put(`/notification/update/${idNotification}`, data);
};

export const updateNotificationDone = async (
  idNotification: number,
  data: any
) => {
  return await api.put(`/notificationHistory/update/${idNotification}`, data);
};

export const deleteNotification = (idNotification: number) =>
  api.delete(`/notification/delete/${idNotification}`);

export const getNotifications = (identifierTree: string) =>
  api.get(`/notification/findAll/${identifierTree}`);

export const getNotificationsDone = (identifierTree: string) =>
  api.get(`/notificationHistory/findAll/${identifierTree}`);
