import styled from "styled-components";
import { supportedDevices } from "../../styles/supportedDevices";

interface ContainerProps {
  active: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: bold;

  text-align: center;

  background: ${({ active }) =>
    active
      ? "linear-gradient(95.44deg, #61C885 0.73%, #1AB1C5 100.24%)"
      : "transparent"};
  color: ${({ active }) => (active ? "#fff" : "#606060")};

  cursor: pointer;
  padding: 15px;
  height: 25px;
  border-radius: 8px;

  &:hover {
    color: #fff;
    background: linear-gradient(95.44deg, #61c885 0.73%, #1ab1c5 100.24%);
  }

  @media ${supportedDevices.tablet} {
    width: 150px;
    font-size: 12px;
  }
`;
