import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { Button, CircularProgress } from "@material-ui/core";

import { signIn } from "../../../../store/Auth/actions";
import { AppState } from "../../../../store/rootReducer";

import { validateLogin } from "../../../../validations/Login";
import { InputError } from "../../../../validations/typeValidation";

import { HorizontalCollapse } from "../../../../components/Animations";
import { CustomTextField } from "../../../../components/CustomTextField";
import { GradientButton } from "../../../../components/GradientButton";
import { Spacer } from "../../../../components/Spacer";
import { useStyles } from "../../../../components/AlertError";

import { ButtonsContainer } from "../../styles";
import { LoginTitle } from "./styles";
import { Alert } from "@material-ui/lab";

export interface UserLoginState {
  email: string;
  password: string;
}

export const FormLogin: React.FC = () => {
  const dispatch = useDispatch();

  const classesError = useStyles();

  const { errorMessage } = useSelector((state: AppState) => state.auth);

  const [user, setUser] = useState<UserLoginState>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<(InputError | undefined)[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  function onFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const newErrors = validateLogin(user);
    setIsLoading(true);
    if (newErrors.length === 0) {
      const { email, password } = user;
      dispatch(signIn(email, password));
      setIsLoading(false);
    } else {
      setErrors(newErrors);
      setIsLoading(false);
    }
  }

  function handleChange(
    fieldName: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setUser({ ...user, [fieldName]: event.target.value });
  }

  function cleanError(index: number) {
    const newErrors = [...errors];
    newErrors[index] = undefined;
    setErrors(newErrors);
  }

  return (
    <>
      <form onSubmit={onFormSubmit} data-testid="loginForm">
        <LoginTitle>Login</LoginTitle>
        <CustomTextField
          name="email"
          label="Email"
          placeholder="example@email.com"
          handleChange={handleChange}
          type="email"
          value={user.email}
          error={errors[0]?.message}
          cleanError={() => cleanError(0)}
        />
        <CustomTextField
          name="password"
          label="Senha"
          placeholder="********"
          error={errors[1]?.message}
          value={user.password}
          handleChange={handleChange}
          type="password"
          cleanError={() => cleanError(1)}
        />
        <ButtonsContainer>
          <HorizontalCollapse width={isLoading ? "20%" : "100%"}>
            <GradientButton type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress color="secondary" size={25} />
              ) : (
                "entrar"
              )}
            </GradientButton>
          </HorizontalCollapse>
          <Spacer height={15} />
          <Link to="esqueciMinhaSenha">
            <Button variant="outlined">esqueceu sua senha?</Button>
          </Link>
        </ButtonsContainer>
      </form>

      {errorMessage === "" ? (
        ""
      ) : (
        <div className={classesError.root}>
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
};
