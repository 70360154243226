import React from "react";

import { createStyles, makeStyles } from "@material-ui/core";

import { PerformanceDatePickerProps } from "../../models/GraphicData";

import { PerformanceDateRange } from "../PerformanceDateRange";

import { Button, Group } from "./styles";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: "55px",
      height: "35px",
      backgroundColor: "rgba(230, 232, 235, 0.5)",
      color: "rgba(0, 0, 0, 0.5)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
    },
    buttonClicked: {
      width: "55px",
      height: "35px",
      background: "linear-gradient(95.44deg, #61C885 0.73%, #1AB1C5 100.24%)",
      color: "#fff",
      border: "1px solid #61C885",
      borderRadius: "5px",
    },
  })
);

export const PerformanceDatePicker = ({
  data,
  dateClicked,
  handleDatePicker,
  handleDateColor,
}: PerformanceDatePickerProps) => {
  const classes = useStyles();

  return (
    <Group>
      {data.map((button, index) => {
        return (
          <Button
            key={index}
            className={
              dateClicked === button.value
                ? classes.buttonClicked
                : classes.button
            }
            onClick={() => {
              handleDatePicker(button.value);
              handleDateColor(button.value);
            }}
          >
            {button.label}
          </Button>
        );
      })}
      <PerformanceDateRange
        clickDateRange={handleDateColor}
        dateClicked={dateClicked}
        typeDateRange="complet"
      />
    </Group>
  );
};
