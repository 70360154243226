import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerFailure = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;

  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  color: #fff;
  height: 30px;
  margin-left: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
`;

export const ButtonClose = styled.button`
  width: 44px;
  height: 37px;
  margin-right: 20px;
  font-size: 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  background-image: linear-gradient(to right, #19b0a0, #009ee2);

  cursor: pointer;
`;

export const TitleFailure = styled.h1`
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #ffffff;

  margin-top: 300px;
  margin-left: 150px;
  margin-right: 150px;
`;

export const Grid = styled.div`
  display: flex;
  gap: 40px;
`;

export const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overflow: hidden;

    font-size: 22px;

    thead {
      color: #fff;
      border-radius: 30px;

      tr {
        border: 1px solid #ffffff;
        border-radius: 30px;

        th {
          background: none;
          padding: 3px;
          text-align: center;
          padding: 10px;

          border: 1px solid #ffffff;
          font-weight: 600;
        }
      }
    }

    tbody {
      color: #ffffff;

      tr {
        border: 1px solid #ffffff;

        td {
          padding: 7px;
          text-align: center;
          border: 1px solid #ffffff;

          font-weight: 400;

          padding: 18px;

          &:nth-child(1) {
            width: 400px;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 300px;
          }
        }
      }
    }
  }

  @media (min-width: 1400px) {
    table {
      font-size: 30px;
    }
  }
`;

export const TitleTable = styled.h1`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: #ffffff;

  @media (min-width: 1400px) {
    font-size: 30px;
  }
`;

export const Table2 = styled.table`
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  padding-bottom: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overflow: hidden;

    font-size: 22px;

    thead {
      color: #fff;
      border-radius: 30px;

      tr {
        border: 1px solid #ffffff;
        border-radius: 30px;

        th {
          background: none;
          padding: 3px;
          text-align: center;
          padding: 10px;

          border: 1px solid #ffffff;
          font-weight: 600;
        }
      }
    }

    tbody {
      color: #ffffff;

      tr {
        border: 1px solid #ffffff;

        td {
          padding: 7px;
          text-align: center;
          border: 1px solid #ffffff;

          font-weight: 400;

          padding: 10px;

          &:nth-child(1) {
            width: 400px;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 300px;
          }
        }
      }
    }
  }

  @media (min-width: 1400px) {
    table {
      font-size: 30px;
    }
  }
`;
