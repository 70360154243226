import styled from "styled-components";

interface ContainerProps {
  criticality: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;

  display: flex;
  border-radius: 10px;
  gap: 15px;
  align-items: center;

  background: ${(props) =>
    props.criticality === "Baixa"
      ? "#DEEDE3"
      : props.criticality === "Media"
      ? "#FFE5D3"
      : "#FFE3E3"};

  hr {
    height: 100%;
    background: ${(props) =>
      props.criticality === "Baixa"
        ? "#009933"
        : props.criticality === "Media"
        ? "#FF6600"
        : "#FF0000"};
    width: 10px;
    border-radius: 10px 0 0 10px;
    border: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      font-size: 14px;
      font-weight: 700;
      color: #7f8898;
    }

    p {
      width: 250px;
      font-size: 12px;
      font-weight: 400;
      color: #7f8898;
      word-wrap: break-word;
    }
  }

  .circle {
    color: ${(props) =>
      props.criticality === "low"
        ? "#009933"
        : props.criticality === "medium"
        ? "#FF6600"
        : "#FF0000"};
  }

  .exclamation {
    color: ${(props) =>
      props.criticality === "low" ? "#A6E3BA" : (
        props.criticality === "medium" ? "#FFC49D" : "#FEB4B4")};
  }
`;
