import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  div {
    input[type="file"] {
      display: none;
    }

    a {
      background-image: linear-gradient(to right, #19b0a0, #009ee2);
      font-size: 11px;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      margin: 10px;
      padding: 6px 20px;
      text-decoration: none;
    }

    label {
      background-image: linear-gradient(to right, #19b0a0, #009ee2);
      font-size: 11px;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      margin: 10px;
      padding: 6px 20px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 20px;
`;

export const TitleSimulation = styled.h1`
  font-size: 20px;
  text-align: center;
  margin: 30px 0;
  color: #08a8ba;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`;
