import { Dispatch } from "redux";
import { TagProps } from "../../models/GraphicData";

import {
  TagsAnalyzeTypes,
  TAGS_RESULT,
  TAGS_SELECTED1,
  TAGS_SELECTED2,
} from "./types";

export const tagsResult = (tags: TagProps[]) => {
  return (dispatch: Dispatch<TagsAnalyzeTypes>) => {
    dispatch({
      type: TAGS_RESULT,
      payload: tags,
    });
  };
};

export const tagsSelected1 = (tagName: TagProps[]) => {
  return (dispatch: Dispatch<TagsAnalyzeTypes>) => {
    dispatch({
      type: TAGS_SELECTED1,
      payload: tagName,
    });
  };
};

export const tagsSelected2 = (tagName: TagProps[]) => {
  return (dispatch: Dispatch<TagsAnalyzeTypes>) => {
    dispatch({
      type: TAGS_SELECTED2,
      payload: tagName,
    });
  };
};
