import { TreeData } from "../../models/TreeData";

import {
  VisualizesDispatchTypes,
  NodeVisualizeState,
  NODE_VISUALIZE,
  TAB_VISUALIZE,
} from "./types";

const initialState: NodeVisualizeState = {
  node: {} as TreeData,
  tab: "",
};

export const NodeVisualizeReducer = (
  state = initialState,
  action: VisualizesDispatchTypes
) => {
  switch (action.type) {
    case NODE_VISUALIZE: {
      return {
        ...state,
        node: action.payload,
      };
    }
    case TAB_VISUALIZE: {
      return {
        ...state,
        tab: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
