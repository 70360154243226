import styled from "styled-components";
import { supportedDevices } from "../../styles/supportedDevices";

interface WidthContainer {
  width: string;
}

interface SetDisplay {
  display: string;
}

export const Container = styled.section<WidthContainer>`
  display: none;

  transition: width 0.4s, padding 0.4s;

  @media ${supportedDevices.laptop} {
    display: flex;
    flex-direction: column;

    width: ${({ width }) => width};
    height: 100%;

    background: #ffffff;

    border-radius: 6px;
  }
`;

export const TreeBody = styled.div<SetDisplay>`
  display: ${({ display }) => display};
  flex-direction: column;

  overflow: hidden;
`;

export const TreeHeader = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
`;

export const Title = styled.h1`
  padding: 10px 20px 10px;

  font-size: 1em;
  color: #4ca6a8;
  text-align: center;
`;

export const TreeView = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden auto;

  padding: 10px 0 0 10px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9ca8ba;
    border-radius: 6px;
    margin-left: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9ca8ba;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px 0px #f0f0f0;
  }
`;

export const LoadingTreeView = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;
`;

export const DownTree = styled.div<SetDisplay>`
  display: ${({ display }) => display};
`;
