import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@material-ui/core";

import { getResultSimulation } from "../../../../../../api";

import {
  calculateItems,
  entriesValue,
  pciCoeficientValue,
  resultSimulation,
} from "../../../../../../store/Simulation/actions";
import { AppState } from "../../../../../../store/rootReducer";

import { ComponentSimulationData } from "../../../../../../models/ComponentData";

import { pciAndCoeficientInitialSimulation } from "../../../../../../utils/pciAndCoeficientInitial";

import { GradientButton } from "../../../../../../components/GradientButton";
import { GraphicSimulation } from "../../../../../../components/GraphicSimulation";

import {
  Container,
  Container2,
  Container3,
  Filter,
  Title,
  Buttons,
  LoadingDiv,
  TitleSimulation,
} from "./styles";

export const ResultSimulation = ({
  componentName,
  handleTab,
}: ComponentSimulationData) => {
  const dispatch = useDispatch();
  const { pciCoeficient, entries, result } = useSelector(
    (state: AppState) => state.simulation
  );

  const [errorReq, setErrorReq] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    if (pciCoeficient.length > 0 || entries.length > 0) {
      const pci = pciCoeficient[0];
      const coeficient = pciCoeficient[1];

      const entriesTimeZone = entries.map((entry) => {
        return {
          title: entry.title,
          table: entry.table.map((table) => {
            return {
              name: table.name,
              cells: table.cells.map((cell) => {
                if (cell.name === "Tempo") {
                  const date = cell.value.split("/");
                  return {
                    name: cell.name,
                    value: `${new Date(
                      `${date[1]}/${date[0]}/${date[2]}`
                    ).toUTCString()}`,
                  };
                } else {
                  return {
                    name: cell.name,
                    value: cell.value,
                  };
                }
              }),
            };
          }),
        };
      });

      const entry = entriesTimeZone[0];

      const result = [];
      result.push(pci, coeficient, entry);

      getResultSimulation(JSON.stringify(result))
        .then((response) => {
          setLoading(false);
          dispatch(resultSimulation(response.data));
        })
        .catch(() => {
          setLoading(false);
          setErrorReq(true);
        });
    }
  }, [dispatch, entries, pciCoeficient]);

  if (loading) {
    return (
      <Container2>
        <Filter>
          <Title>{componentName}</Title>
          <div></div>
        </Filter>

        <LoadingDiv>
          <h1>Simulando...</h1>
          <CircularProgress />
        </LoadingDiv>
      </Container2>
    );
  }

  if (errorReq || result !== "POST Response OK") {
    return (
      <Container3>
        <Filter>
          <Title>{componentName}</Title>
          <div></div>
        </Filter>

        <TitleSimulation>
          Infelizmente ocorreu um erro no cálculo, por favor faça novamente a
          simulação
        </TitleSimulation>

        <Buttons>
          <GradientButton
            onClick={() => {
              handleTab("entries");
              dispatch(resultSimulation(""));
            }}
          >
            Voltar
          </GradientButton>
          <GradientButton
            onClick={() => {
              handleTab("initial");
              dispatch(pciCoeficientValue(pciAndCoeficientInitialSimulation));
              dispatch(entriesValue([]));
              dispatch(calculateItems(true, true));
              dispatch(resultSimulation(""));
            }}
          >
            Nova Simulação
          </GradientButton>
        </Buttons>
      </Container3>
    );
  }

  return (
    <Container>
      <Filter>
        <Title>{componentName}</Title>
        <div></div>
      </Filter>

      <TitleSimulation>ANÁLISE DE RESULTADOS</TitleSimulation>

      <GraphicSimulation />

      <Buttons>
        <GradientButton
          onClick={() => {
            handleTab("entries");
            dispatch(resultSimulation(""));
          }}
        >
          Voltar
        </GradientButton>
        <GradientButton
          onClick={() => {
            handleTab("initial");
            dispatch(pciCoeficientValue(pciAndCoeficientInitialSimulation));
            dispatch(entriesValue([]));
            dispatch(calculateItems(true, true));
            dispatch(resultSimulation(""));
          }}
        >
          Nova Simulação
        </GradientButton>
      </Buttons>
    </Container>
  );
};
