import React from "react";

import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import { FormLogin } from "./components/FormLogin";
import { ForgotPassword } from "./components/ForgotPassword";

export const RouterLogin = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/login`} component={FormLogin} />
      <Route
        path={`${match.path}/esqueciMinhaSenha`}
        component={ForgotPassword}
      />
      <Redirect exact from={`${match.path}`} to={`${match.path}/login`} />
    </Switch>
  );
};
