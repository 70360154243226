import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  table {
    height: calc(100% - 120px);
    overflow-y: auto;
    width: 100%;
    margin-top: 20px;
    background: #e8ebed;
    border-radius: 16px;
    padding: 15px 20px 10px 20px;

    thead {
      th {
        padding: 0 10px 5px 10px;
        color: #000;
        font-weight: 500;
        font-size: 12px;
      }
    }

    tbody {
      background-color: #ffffff;

      tr {
        border: 0;
        text-align: center;
        font-size: 12px;

        td {
          padding: 12px 16px;
        }

        & + tr {
          padding-top: 5px;
        }
      }
    }
  }

  .input {
    width: 100%;
    background: red;
  }
`;
