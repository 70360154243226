import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { actionRule } from "../../../../store/Alert/actions";

import { ComponentData } from "../../../../models/ComponentData";

import { RulesTable } from "../../../../components/RulesTable";
import { ModalAddAlert } from "../../../../components/ModalAlert";

import { Container, Button } from "./styles";
import { AppState } from "../../../../store/rootReducer";
import { createRule, deleteRule, getRules, updateRule } from "../../../../api";
import { RuleActionData } from "../../../../models/NotificationData";
import { ErrorAlert } from "../../../../components/ErrorAlert";

export const Rules = ({ componentName, tabAccessed }: ComponentData) => {
  const dispatch = useDispatch();

  useEffect(() => {
    tabAccessed("PREDIÇÃO DE FALHAS");
  }, [tabAccessed]);

  const { identifier } = useSelector(
    (state: AppState) => state.nodeVisualize.node
  );

  const [rules, setRules] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  //const [openListError, setOpenListError] = useState(false);
  const [openAddError, setOpenAddError] = useState(false);
  const [openEditError, setOpenEditError] = useState(false);
  const [openDeleteError, setOpenDeleteError] = useState(false);

  useEffect(() => {
    async function listRules() {
      setRules([]);
      setTimeout(async () => {
        await getRules(identifier)
          .then((response) => {
            const { data } = response;
            const dataSort = data.sort((a: any, b: any) => a.id - b.id);
            setRules(dataSort);
          })
          .catch((error) => {
            //setOpenListError(true);
          });
        setLoading(false);
      }, 1000);

      setLoading(true);
    }
    listRules();
  }, [dispatch, identifier]);

  const [modalAlertOpen, setModalAlertOpen] = useState(false);

  const toggleModalAlert = () => {
    setModalAlertOpen(!modalAlertOpen);
  };

  const handleActionRule = (
    type: string,
    rule: RuleActionData,
    id?: number
  ) => {
    if (type === "add") {
      createRule(identifier, [
        {
          title: rule.title,
          message: rule.message,
          expression: rule.expression,
          variables: rule.variables,
          functions: rule.functions,
          criticality: rule.criticality,
          duration: rule.duration,
          frequency: rule.frequency
        },
      ])
        .then((response) => {
          setRules([]);
          setTimeout(async () => {
            await getRules(identifier).then((response) => {
              const { data } = response;
              const dataSort = data.sort((a: any, b: any) => a.id - b.id);
              setRules(dataSort);
            });
            setLoading(false);
          }, 100);

          setLoading(true);
        })
        .catch((error) => {
          setOpenAddError(true);
        });
    } else {
      if (id)
        updateRule(id, [
          {
            title: rule.title,
            message: rule.message,
            expression: rule.expression,
            variables: rule.variables,
            functions: rule.functions,
            criticality: rule.criticality,
            duration: rule.duration,
            frequency: rule.frequency
          },
        ])
          .then((response) => {
            setRules([]);
            setTimeout(async () => {
              await getRules(identifier).then((response) => {
                const { data } = response;
                const dataSort = data.sort((a: any, b: any) => a.id - b.id);
                setRules(dataSort);
              });
              setLoading(false);
            }, 1000);

            setLoading(true);
          })
          .catch((error) => {
            setOpenEditError(true);
          });
    }
  };

  const handleDeleteRule = (id: number) => {
    deleteRule(id)
      .then((response) => {
        setRules([]);
        setTimeout(async () => {
          await getRules(identifier).then((response) => {
            const { data } = response;
            const dataSort = data.sort((a: any, b: any) => a.id - b.id);
            setRules(dataSort);
          });
          setLoading(false);
        }, 1000);

        setLoading(true);
      })
      .catch((error) => {
        setOpenDeleteError(true);
      });
  };

  const handleAlertClose = (type: string) => {
    if (type === "add") {
      setOpenAddError(false);
    } else if (type === "edit") {
      setOpenEditError(false);
    } else if (type === "delete") {
      setOpenDeleteError(false);
    } else {
      //setOpenListError(false);
    }
  };

  return (
    <Container>
      <h1>{componentName}</h1>

      <RulesTable
        loading={loading}
        openModal={toggleModalAlert}
        rules={rules}
        handleDeleteRule={handleDeleteRule}
      />

      <ModalAddAlert
        setIsOpen={toggleModalAlert}
        isOpen={modalAlertOpen}
        handleActionRule={handleActionRule}
      />

      <Button
        type="button"
        onClick={() => {
          dispatch(
            actionRule({
              typeAction: "add",
              id: undefined,
              rule: {
                criticality: "Baixa",
                title: "",
                message: "",
                expression: "",
                duration: "00:00:00",
                variables: "",
                functions: "",
                frequency: ""
              },
            })
          );
          toggleModalAlert();
        }}
      >
        NOVA REGRA
      </Button>

      {/* {openListError && (
        <ErrorAlert
          open={openListError}
          setOpenAlert={() => handleAlertClose("list")}
          message="Não possui regras para exibir"
        />
      )} */}

      {openAddError && (
        <ErrorAlert
          open={openAddError}
          setOpenAlert={() => handleAlertClose("add")}
          message="Ocorreu um erro ao tentar adicionar uma nova regra, por favor verifique sua expressão criada."
        />
      )}

      {openEditError && (
        <ErrorAlert
          open={openEditError}
          setOpenAlert={() => handleAlertClose("edit")}
          message="Ocorreu um erro ao tentar editar a regra, por favor verifique sua expressão criada."
        />
      )}

      {openDeleteError && (
        <ErrorAlert
          open={openDeleteError}
          setOpenAlert={() => handleAlertClose("delete")}
          message="Ocorreu um erro ao tentar excluir a regra, por favor tente novamente."
        />
      )}
    </Container>
  );
};
