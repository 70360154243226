import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import LogoImg from "../../assets/nexumtwin.png";

import { setInterval } from "timers";

import { getPriorizationRealTime } from "../../api";

import { AppState } from "../../store/rootReducer";
import { GradientButton } from "../GradientButton";

import {
  Cell,
  PriorizationRes,
  TablePriorization,
} from "../../models/PriorizationRes";

import {
  CellFuelLimits,
  FuelLimits,
  TableFuelLimits,
} from "../../models/OptimizationData";

import { priorizationModal } from "../../utils/decisionMaking";

import {
  Container,
  ButtonClose,
  Title,
  Table,
  Infos,
  Info,
  Buttons,
  Logo,
} from "./styles";

interface PriorizationModalProps {
  handleClose: () => void;
  data: PriorizationRes[];
}

export const PriorizationModal = ({
  handleClose,
  data,
}: PriorizationModalProps) => {
  const { pciCoeficient, fuelValue } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  const [priorization, setPriorization] = useState<PriorizationRes[]>(data);

  useEffect(() => {
    const result = [pciCoeficient[0], pciCoeficient[1]];
    setInterval(() => {
      getPriorizationRealTime(JSON.stringify(result))
        .then((response) => {
          setPriorization(response.data);
        })
        .catch((error) => {});
    }, 60000);
  }, [pciCoeficient]);

  const [showingLimits, setShowingLimits] = useState(false);

  const showingLimitsUpHandler = () => {
    setShowingLimits(true);
  };
  const showingLimitsDownHandler = () => {
    setShowingLimits(false);
  };

  const limitsInfo = (tableName: string, fuelLimitsName: string[]) => {
    return (
      <>
        {fuelValue.map(
          ({ title, table }: FuelLimits) =>
            title === tableName &&
            table.map(
              ({ cells }: TableFuelLimits) =>
                cells !== undefined &&
                cells.map(({ upper2, fuel, equipment }: CellFuelLimits) => {
                  if (fuelLimitsName.includes(fuel)) {
                    return (
                      <td key={fuel + equipment} className="numbers">
                        {upper2.toString().replace(".", ",")}
                      </td>
                    );
                  }
                  return null;
                })
            )
        )}
      </>
    );
  };

  const valuesInfo = (tableName: string, fuel: string) => {
    return (
      <>
        {priorization.map(
          ({ title, table }: PriorizationRes) =>
            title === tableName &&
            table.map(
              ({ name, cells }: TablePriorization) =>
                name === fuel &&
                cells !== undefined &&
                cells.map(({ value, name }: Cell) => (
                  <td key={value + name} className="numbers">
                    {value.toString().replace(".", ",")}
                  </td>
                ))
            )
        )}
      </>
    );
  };

  const priority = (fuel: string) => {
    return (
      <>
        {priorization.map(
          ({ title, table }: PriorizationRes) =>
            title === "priority" &&
            table.map(
              ({ cells, name }: TablePriorization) =>
                name === fuel &&
                cells !== undefined &&
                cells.map(({ value, colorHex, name }: Cell) => (
                  <td
                    className="prioridade"
                    style={{
                      backgroundColor: colorHex,
                      color: "white",
                    }}
                    key={value + name}
                  >
                    {value.toString().replace(".", ",")}
                  </td>
                ))
            )
        )}
      </>
    );
  };

  const totalFlowRate = (fuel: string) => {
    return priorization.map(
      ({ table, title }: PriorizationRes) =>
        title === "flow_rate" &&
        table.map(
          ({ name, total }: TablePriorization) =>
            name === fuel && total?.toString().replace(".", ",")
        )
    );
  };

  const steamProductionEquipmentsModel = (tableName: string) => {
    return priorization.map(
      ({ table, title }: PriorizationRes) =>
        title === tableName &&
        table.map(
          ({ name, value }: TablePriorization, i) =>
            name.includes("Caldeira") && (
              <td
                key={value + name}
                style={{ fontWeight: "bold", color: dynamicStyleForModel()[i] }}
              >
                {value?.toString().replace(".", ",")}
              </td>
            )
        )
    );
  };

  const steamProductionEquipmentsReal = (tableName: string) => {
    return priorization.map(
      ({ table, title }: PriorizationRes) =>
        title === tableName &&
        table.map(
          ({ name, value }: TablePriorization) =>
            name.includes("Caldeira") && (
              <td key={value + name} style={{ fontWeight: "bold" }}>
                {value?.toString().replace(".", ",")}
              </td>
            )
        )
    );
  };

  const dynamicStyleForModel = () => {
    const boilerValuesFitered = (tableName: string) => {
      for (let i = 0; i < priorization.length; i++) {
        if (priorization[i].title.includes(tableName)) {
          const table = priorization[i].table;
          const boilerTableValue = table.map(
            ({ name, value }: TablePriorization) =>
              name.includes("Caldeira") && value
          );
          const boilerTableValuesFiltereds = boilerTableValue.filter(Boolean);
          return boilerTableValuesFiltereds;
        }
      }
    };
    const modelValues = boilerValuesFitered("steam_production_model");
    const realValues = boilerValuesFitered("steam_production_real");

    const differenceIdentifier = (value1: any, value2: any) => {
      const differenceArray: any = [];
      for (let i = 0; i < value1.length; i++) {
        let difference: any = value1[i] - value2[i];
        differenceArray.push(parseFloat(difference.toFixed(3)));
      }
      return differenceArray;
    };
    const modelRealDifference = differenceIdentifier(modelValues, realValues);
    const checkColor = (array: any) => {
      const colorsArray: any = [];
      for (let i = 0; i < array.length; i++) {
        let current = array[i];
        if (current < 0) {
          current = current * -1;
        }
        if (current <= 5) {
          colorsArray.push("#007C05");
        } else if (current <= 8) {
          colorsArray.push("#BFA519");
        } else {
          colorsArray.push("#FF0000");
        }
      }
      return colorsArray;
    };
    return checkColor(modelRealDifference);
  };

  return (
    <Container>
      <div>
        <Logo>
          <img src={LogoImg} alt="" />
        </Logo>
        <Title>OPERAÇÃO EM TEMPO REAL</Title>
        <Buttons>
          <div>
            <GradientButton
              onMouseLeave={showingLimitsDownHandler}
              onMouseUp={showingLimitsDownHandler}
              onMouseDown={() => showingLimitsUpHandler()}
              title="Pressione para ver os limites"
            >
              Limites
            </GradientButton>
            <ButtonClose onClick={() => handleClose()}>X</ButtonClose>
          </div>
        </Buttons>
      </div>

      <Table>
        <thead>
          <tr>
            <th className="comb">Combustível</th>
            <th className="param">Parâmetro</th>
            <th>Caldeira 1</th>
            <th>Caldeira 2</th>
            <th>Caldeira 3</th>
            <th>Caldeira 4</th>
            <th className="totalFlow">Vazão Total</th>
          </tr>
        </thead>
        <tbody>
          {priorizationModal.map((item) => (
            <React.Fragment key={item.fuelName + Math.floor(Math.random())}>
              <tr>
                <td
                  className="combInfo"
                  rowSpan={showingLimits === true ? 5 : 4}
                >
                  {item.fuelName === "OLGN" ? "Óleo/GN" : item.fuelName}
                </td>
                <td>CONS. ESP. [Mcal/ton]</td>
                {valuesInfo(item.table1, item.fuelName)}
                <td rowSpan={showingLimits === true ? 5 : 4}>
                  {totalFlowRate(item.fuelName)}
                </td>
              </tr>
              <tr>
                <td>VAZÃO [kNm³/h]</td>
                {valuesInfo(item.table2, item.fuelName)}
              </tr>
              {showingLimits && (
                <tr>
                  <td>LIMITE SUPERIOR [kNm³/h]</td>
                  {limitsInfo(item.tableL, [
                    item.fuelNameLimit,
                    item.fuelNameLimit2 || "",
                  ])}
                </tr>
              )}
              <tr>
                <td>ORDEM ATUAL</td>
                {valuesInfo(item.table3, item.fuelName)}
              </tr>
              <tr>
                <td className="prioridade">PRIORIDADE</td>
                {priority(item.fuelName)}
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td className="prioridade" colSpan={2}>
              PRODUÇÃO DE VAPOR MODELO [ton/h]
            </td>
            {steamProductionEquipmentsModel("steam_production_model")}
          </tr>
          <tr>
            <td className="prioridade" colSpan={2}>
              PRODUÇÃO DE VAPOR REAL [ton/h]
            </td>
            {steamProductionEquipmentsReal("steam_production_real")}
          </tr>
        </tbody>
      </Table>

      <Infos>
        {priorization.map(
          ({ table, title }: PriorizationRes) =>
            title === "steam_production_model" &&
            table.map(({ name, value }: TablePriorization) =>
              name.includes("Caldeira") ? null : (
                <Info key={name + value}>
                  <p>
                    Total Produção de Vapor {name}:{" "}
                    {value?.toString().replace(".", ",")} ton/h
                  </p>
                </Info>
              )
            )
        )}
        {priorization.map(
          ({ table, title }: PriorizationRes) =>
            title === "steam_production_real" &&
            table.map(({ name, value }: TablePriorization) =>
              name.includes("Caldeira") ? null : (
                <Info key={name + value}>
                  <p>
                    Total Produção de Vapor {name}:{" "}
                    {value?.toString().replace(".", ",")} ton/h
                  </p>
                </Info>
              )
            )
        )}
      </Infos>
    </Container>
  );
};
