import styled from "styled-components";

export const Group = styled.div`
  display: flex;
  gap: 5px;
`;

export const Button = styled.button`
  cursor: pointer;
`;
