import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRule } from "../../store/Alert/actions";
import { AppState } from "../../store/rootReducer";
import {
  arithmeticOperators,
  equipmentOptions,
  logicalOperators,
  relationalOperators,
  variableOptions,
} from "../../utils/expression";
import { Container } from "./styles";

export const CreateExpression = () => {
  const dispatch = useDispatch();

  const { typeAction, rule, id } = useSelector(
    (state: AppState) => state.notificationRule.actionRule
  );

  const [typeParam, setTypeParam] = useState("actual");
  const [equipment, setEquipment] = useState("Caldeiras");
  const [variable, setVariable] = useState("CONSUMOESPTOTAL");

  const [unit, setUnit] = useState("month");
  const [interval, setInterval] = useState(1);

  const [typeOperator, setTypeOperator] = useState("arithmetic");
  const [operator, setOperator] = useState("+");

  const [constant, setConstant] = useState("0");

  const [expression, setExpression] = useState("");
  const [variables, setVariables] = useState("");
  const [functions, setFunctions] = useState("");

  const handleAddParam = () => {
    const param = `${variable}`;
    if (rule.expression === "") {
      setExpression(param);
      setFunctions(`${rule.functions}`);
      if (rule.variables === "") {
        setVariables(param);
      } else {
        setVariables(`${rule.variables},${param}`);
      }
    } else {
      setExpression(`${rule.expression} ${param}`);
      setFunctions(`${rule.functions}`);
      if (rule.variables === "") {
        setVariables(param);
      } else {
        if (!rule.variables.includes(param)) {
          setVariables(`${rule.variables},${param}`);
        } else {
          setVariables(rule.variables);
        }
      }
    }
  };

  const handleAddFunction = () => {
    const functionParam = `${typeParam}(${variable},${interval},${unit})`;

    if (Number(interval) > 0) {
      if (rule.expression === "") {
        setExpression(functionParam);
        setVariables(`${rule.variables}`);
        if (rule.functions === "") {
          setFunctions(functionParam);
        } else {
          setFunctions(`${rule.functions};${functionParam}`);
        }
      } else {
        setVariables(`${rule.variables}`);
        setExpression(`${rule.expression} ${functionParam}`);

        if (rule.functions === "") {
          setFunctions(functionParam);
        } else {
          if (!rule.functions.includes(functionParam)) {
            setFunctions(`${rule.functions};${functionParam}`);
          } else {
            setFunctions(rule.functions);
          }
        }
      }
    }
  };

  const handleAddOperator = () => {
    if (rule.expression === "") {
      setExpression(operator);
      setVariables(`${rule.variables}`);
      setFunctions(`${rule.functions}`);
    } else {
      setExpression(`${rule.expression} ${operator}`);
      setVariables(`${rule.variables}`);
      setFunctions(`${rule.functions}`);
    }
  };

  const handleAddConstant = () => {
    if (rule.expression === "") {
      setExpression(constant);
      setVariables(`${rule.variables}`);
      setFunctions(`${rule.functions}`);
    } else {
      setExpression(`${rule.expression} ${constant}`);
      setVariables(`${rule.variables}`);
      setFunctions(`${rule.functions}`);
    }
  };

  useEffect(() => {
    dispatch(
      actionRule({
        id: id,
        typeAction: typeAction,
        rule: {
          criticality: rule.criticality,
          title: rule.title,
          message: rule.message,
          expression: expression,
          duration: rule.duration,
          variables: variables,
          functions: functions,
          frequency: rule.frequency,
        },
      })
    );
  }, [
    dispatch,
    typeAction,
    rule.expression,
    rule.criticality,
    rule.title,
    rule.message,
    expression,
    rule.duration,
    rule.variables,
    rule.functions,
    rule.frequency,
    variables,
    functions,
    id,
  ]);

  return (
    <Container>
      <div className="param">
        <div className="param-content">
          <div className="typeParam">
            <h3>Tipo</h3>
            <div className="separator">
              <label>
                <input
                  id="actual"
                  name="param"
                  type="radio"
                  value="actual"
                  defaultChecked
                  onClick={() => setTypeParam("actual")}
                />
                <p>Atual</p>
              </label>
              <label>
                <input
                  id="media"
                  name="param"
                  type="radio"
                  value="media"
                  onClick={() => setTypeParam("media")}
                />
                <p>Média</p>
              </label>
              <label>
                <input
                  id="taxaVariacao"
                  name="param"
                  type="radio"
                  value="taxaVariacao"
                  onClick={() => setTypeParam("taxaVariacao")}
                />
                <p>Taxa de Variação</p>
              </label>
              <label>
                <input
                  id="acumulado"
                  name="param"
                  type="radio"
                  value="acumulado"
                  onClick={() => setTypeParam("acumulado")}
                />
                <p>Acumulado</p>
              </label>
            </div>
          </div>
          <div className="changeParam">
            <h3>Parâmetro</h3>
            <div className="separator">
              <label>
                <p>Equipamento</p>
                <select
                  className={typeParam === "actual" ? "selectTotal" : ""}
                  name="equipment"
                  id="equipment"
                  onChange={(e) => {
                    setEquipment(e.target.value);
                    if (e.target.value !== "Caldeiras") {
                      setVariable(`${e.target.value}PCIGCO`);
                    } else {
                      setVariable(variableOptions.Caldeiras[0].value);
                    }
                  }}
                >
                  {equipmentOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                <p>Variável</p>
                <select
                  className={typeParam === "actual" ? "selectTotal" : ""}
                  name="variable"
                  id="variable"
                  onChange={(e) => setVariable(e.target.value)}
                >
                  {equipment === "Caldeiras" &&
                    variableOptions.Caldeiras.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === variable}
                      >
                        {item.label}
                      </option>
                    ))}
                  {equipment === "C1" &&
                    variableOptions.C1.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === variable}
                      >
                        {item.label}
                      </option>
                    ))}
                  {equipment === "C2" &&
                    variableOptions.C2.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === variable}
                      >
                        {item.label}
                      </option>
                    ))}
                  {equipment === "C3" &&
                    variableOptions.C3.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === variable}
                      >
                        {item.label}
                      </option>
                    ))}
                  {equipment === "C4" &&
                    variableOptions.C4.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === variable}
                      >
                        {item.label}
                      </option>
                    ))}
                </select>
              </label>
            </div>
          </div>

          <div
            className={
              typeParam === "actual" ? "disableChangeBreak" : "changeBreak"
            }
          >
            <h3>Intervalo</h3>
            <div className="separator">
              <div className="buttons">
                <button
                  className={unit === "month" ? "active" : ""}
                  onClick={() => setUnit("month")}
                >
                  mês
                </button>
                <button
                  className={unit === "d" ? "active" : ""}
                  onClick={() => setUnit("d")}
                >
                  dia
                </button>
                <button
                  className={unit === "h" ? "active" : ""}
                  onClick={() => setUnit("h")}
                >
                  hora
                </button>
                <button
                  className={unit === "min" ? "active" : ""}
                  onClick={() => setUnit("min")}
                >
                  min
                </button>
                <button
                  className={unit === "s" ? "active" : ""}
                  onClick={() => setUnit("s")}
                >
                  seg
                </button>
              </div>
              <input
                type="number"
                onChange={(e) => setInterval(Number(e.target.value))}
                defaultValue={interval}
                min={1}
              />
            </div>
            <p>{`${typeParam}(${variable},${interval},${unit})`}</p>
          </div>
        </div>
        {typeParam === "actual" ? (
          <button type="button" className="adicionar" onClick={handleAddParam}>
            ADICIONAR
          </button>
        ) : (
          <button
            type="button"
            className="adicionar"
            onClick={handleAddFunction}
          >
            ADICIONAR
          </button>
        )}
      </div>

      <div className="operator">
        <div className="operator-content">
          <div className="typeOperator">
            <h3>Tipo</h3>
            <div className="separator">
              <label>
                <input
                  id="arithmetic"
                  name="operator"
                  type="radio"
                  value="arithmetic"
                  defaultChecked
                  onClick={() => {
                    setTypeOperator("arithmetic");
                    setOperator("+");
                  }}
                />
                <p>Aritmético</p>
              </label>
              <label>
                <input
                  id="logic"
                  name="operator"
                  type="radio"
                  value="logic"
                  onClick={() => {
                    setTypeOperator("logic");
                    setOperator("and");
                  }}
                />
                <p>Lógico</p>
              </label>
              <label>
                <input
                  id="relational"
                  name="operator"
                  type="radio"
                  value="relational"
                  onClick={() => {
                    setTypeOperator("relational");
                    setOperator("<");
                  }}
                />
                <p>Relacional</p>
              </label>
            </div>
          </div>

          <div className="changeOperator">
            <h3>Operador</h3>
            <div className="separator">
              <select
                className={typeParam === "actual" ? "selectTotal" : ""}
                name=""
                id=""
                onChange={(e) => setOperator(e.target.value)}
              >
                {typeOperator === "arithmetic"
                  ? arithmeticOperators.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))
                  : typeOperator === "logic"
                  ? logicalOperators.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))
                  : relationalOperators.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
              </select>
            </div>
            <p className="previewOperator">{operator}</p>
          </div>
        </div>

        <button type="button" className="adicionar" onClick={handleAddOperator}>
          ADICIONAR
        </button>
      </div>

      <div className="const">
        <div className="const-content">
          <h3>Constante</h3>
          <input
            value={constant}
            type="number"
            placeholder="800.00"
            onChange={(e) => setConstant(e.target.value)}
          />
        </div>
        <button type="button" className="adicionar" onClick={handleAddConstant}>
          ADICIONAR
        </button>
      </div>
    </Container>
  );
};
