import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { signOut } from "../../store/Auth/actions";

import LogoNexumTwin from "../../assets/nexumtwin.png";

import { Container, Logo } from "./styles";

export const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const signOutClick = () => {
    dispatch(signOut());
    history.push("/");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Logo
        onClick={() => {
          if (location.pathname === "dashboard/initial") {
            history.push("/");
            history.goBack();
          } else history.push("/dashboard/initial");
        }}
      >
        <img src={LogoNexumTwin} alt="" />
      </Logo>

      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon style={{ color: "#fff", fontSize: 40 }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={signOutClick}>Sair</MenuItem>
      </Menu>
    </Container>
  );
};
