import { lastWeekPeriodOfTime } from "../../utils/dateManager";
import {
  fuelRealLimitsInitial,
  fuelDemandLimitsInitial,
  flowLimitsInitial,
  fuelLimitsInitial,
} from "../../utils/decisionMaking";
import { pciAndCoeficientInitialDecisionMaking } from "../../utils/pciAndCoeficientInitial";
import {
  PCI_COEFICIENT_VALUE_DECISION_MAKING,
  CALCULATE_ITEMS,
  SET_DATE_DECISION_MAKING,
  DecisionMakingState,
  DecisionMakingDispatchTypes,
  FLOW_VALUE_DECISION_MAKING,
  FUEL_VALUE_DECISION_MAKING,
  FUEL_VALUE_REAL_DECISION_MAKING,
  FUEL_VALUE_DEMAND_DECISION_MAKING,
} from "./types";

const date = new Date().setMinutes(0, 0, 0);
const arrayDatePeriod =  lastWeekPeriodOfTime();

const initialState: DecisionMakingState = {
  calculateItems: {
    coef: true,
    pci: true,
  },
  pciCoeficient: pciAndCoeficientInitialDecisionMaking,
  date: {
    initial: arrayDatePeriod[0].toString(),
    final: arrayDatePeriod[1].toString(),
  },
  fuelValue: fuelLimitsInitial,
  flowValue: flowLimitsInitial,
  fuelDemand: fuelDemandLimitsInitial,
  fuelReal: fuelRealLimitsInitial,
};

export const DecisionMakingReducer = (
  state = initialState,
  action: DecisionMakingDispatchTypes
) => {
  switch (action.type) {
    case CALCULATE_ITEMS: {
      return {
        ...state,
        calculateItems: action.payload,
      };
    }
    case PCI_COEFICIENT_VALUE_DECISION_MAKING: {
      return {
        ...state,
        pciCoeficient: action.payload,
      };
    }
    case SET_DATE_DECISION_MAKING: {
      return {
        ...state,
        date: action.payload,
      };
    }
    case FUEL_VALUE_DECISION_MAKING: {
      return {
        ...state,
        fuelValue: action.payload,
      };
    }
    case FLOW_VALUE_DECISION_MAKING: {
      return {
        ...state,
        flowValue: action.payload,
      };
    }
    case FUEL_VALUE_REAL_DECISION_MAKING: {
      return {
        ...state,
        fuelReal: action.payload,
      };
    }
    case FUEL_VALUE_DEMAND_DECISION_MAKING: {
      return {
        ...state,
        fuelDemand: action.payload,
      };
    }
    default:
      return state;
  }
};
