import { TreeData } from "../../models/TreeData";

export interface NodeVisualizeState {
  node: TreeData;
  tab: string;
}

export const NODE_VISUALIZE = "NODE_VISUALIZE";
export const TAB_VISUALIZE = "TAB_VISUALIZE";

export interface NodeVisualize {
  type: typeof NODE_VISUALIZE;
  payload: TreeData;
}

export interface TabVisualize {
  type: typeof TAB_VISUALIZE;
  payload: string;
}

export type VisualizesDispatchTypes = NodeVisualize | TabVisualize;
