import { UserLoginState } from "../../pages/Login/components/FormLogin";
import { InputError } from "../typeValidation";

export function validateLogin(field: UserLoginState): InputError[] {
  const errors: InputError[] = [];

  if (!field.email) {
    const message = "Digite seu email!";
    errors[0] = { field: "email", message };
  }

  if (!field.password) {
    const message = "Digite sua senha!";
    errors[1] = { field: "senha", message };
  }

  return errors;
}
