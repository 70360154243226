import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 20px;

  && .search-input {
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-underline:after,
    .MuiInput-underline:before {
      border: none;
    }

    & .MuiInputBase-input {
      margin-top: 5px;
    }
  }
`;
