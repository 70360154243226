
import { pciAndCoeficientInitialSimulation } from "../../utils/pciAndCoeficientInitial";
import {
  ENTRIES_VALUE,
  SET_DATE_SIMULATION,
  SimulationDispatchTypes,
  SimulationState,
  PCI_COEFICIENT_VALUE,
  RESULT_VALUE,
  CALCULATE_ITEMS,
} from "./types";
import { lastWeekPeriodOfTime } from "../../utils/dateManager";

const date = new Date().setMinutes(0, 0, 0);
const arrayDatePeriod =  lastWeekPeriodOfTime();

const initialState: SimulationState = {
  calculateItems: {
    coef: true,
    pci: true,
  },
  entries: [
    {
      title: "FLOW_RATE",
      table: [],
    },
  ],
  pciCoeficient: pciAndCoeficientInitialSimulation,
  result: "",
  date: {
    initial: arrayDatePeriod[0].toString(),
    final: arrayDatePeriod[1].toString(),
  },
  errorSetDateSimulation: "",
};

export const SimulationReducer = (
  state = initialState,
  action: SimulationDispatchTypes
) => {
  switch (action.type) {
    case CALCULATE_ITEMS: {
      return {
        ...state,
        calculateItems: action.payload,
      };
    }
    case PCI_COEFICIENT_VALUE: {
      return {
        ...state,
        pciCoeficient: action.payload,
      };
    }
    case ENTRIES_VALUE:
      return {
        ...state,
        entries: [
          {
            title: "FLOW_RATE",
            table: action.payload,
          },
        ],
      };
    case SET_DATE_SIMULATION:
      return {
        ...state,
        date: action.payload,
      };
    case RESULT_VALUE: {
      return {
        ...state,
        result: action.payload,
      };
    }
    default:
      return state;
  }
};
