import styled from "styled-components";

export const Title = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #9ca8ba;
  font-weight: bold;
  font-size: 24px;
`;
