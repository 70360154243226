import { Dispatch } from "redux";
import {
  FlowLimits,
  FuelDemandReal,
  FuelLimits,
} from "../../models/OptimizationData";
import { TableDataDecisionMaking } from "../../models/TableData";
import {
  PCI_COEFICIENT_VALUE_DECISION_MAKING,
  CALCULATE_ITEMS,
  DecisionMakingDispatchTypes,
  SET_DATE_DECISION_MAKING,
  FUEL_VALUE_DECISION_MAKING,
  FLOW_VALUE_DECISION_MAKING,
  FUEL_VALUE_REAL_DECISION_MAKING,
  FUEL_VALUE_DEMAND_DECISION_MAKING,
} from "./types";

export const calculateItemsDecisionMaking = (
  hasPci: boolean,
  hasCoef: boolean
) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: CALCULATE_ITEMS,
      payload: {
        pci: hasPci,
        coef: hasCoef,
      },
    });
  };
};

export const pciCoeficientValueDecisionMaking = (
  pciCoeficient: TableDataDecisionMaking[]
) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: PCI_COEFICIENT_VALUE_DECISION_MAKING,
      payload: pciCoeficient,
    });
  };
};

export const dateDecisionMaking = (initial: string, final: string) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: SET_DATE_DECISION_MAKING,
      payload: {
        initial,
        final,
      },
    });
  };
};

export const fuelValueDecisionMaking = (value: FuelLimits[]) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: FUEL_VALUE_DECISION_MAKING,
      payload: value,
    });
  };
};

export const flowValueDecisionMaking = (value: FlowLimits[]) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: FLOW_VALUE_DECISION_MAKING,
      payload: value,
    });
  };
};

export const fuelValueRealDecisionMaking = (value: FuelDemandReal[]) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: FUEL_VALUE_REAL_DECISION_MAKING,
      payload: value,
    });
  };
};

export const fuelValueDemandDecisionMaking = (value: FuelDemandReal[]) => {
  return (dispatch: Dispatch<DecisionMakingDispatchTypes>) => {
    dispatch({
      type: FUEL_VALUE_DEMAND_DECISION_MAKING,
      payload: value,
    });
  };
}
