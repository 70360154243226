import { TREE, TreeDispatchTypes, TreeState } from "./types";

const initialState: TreeState = {
  tree: [],
};

export const TreeReducer = (
  state = initialState,
  action: TreeDispatchTypes
) => {
  switch (action.type) {
    case TREE:
      return {
        tree: action.payload,
      };
    default:
      return state;
  }
};
