import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { TableData } from "../../models/TableData";

import { AppState } from "../../store/rootReducer";
import { pciCoeficientValue } from "../../store/Simulation/actions";

import { Container, Table, Tables, Title } from "./styles";

interface TablesSimulation2Props {
  loading: boolean;
}

export const TablesSimulation2 = ({ loading }: TablesSimulation2Props) => {
  const dispatch = useDispatch();
  const { pciCoeficient } = useSelector((state: AppState) => state.simulation);

  const [pciCoeficientUpdated, setPciCoeficientUpdated] =
    React.useState<TableData[]>(pciCoeficient);

  const handleChange = (value: string, profile: string) => {
    const updatePciCoeficient: TableData[] = pciCoeficient.map(
      ({ title, table }) => ({
        title,
        table: table.map(({ name, cells }) => ({
          name,
          cells: cells.map((cell) =>
            profile === cell.name
              ? {
                ...cell,
                value: value
                  ? value.includes(",")
                    ? value.replace(",", ".")
                    : value
                  : "0",
              }
              : cell
          ),
        })),
      })
    );
    setPciCoeficientUpdated(updatePciCoeficient);
  };

  useEffect(() => {
    dispatch(pciCoeficientValue(pciCoeficientUpdated));
  }, [dispatch, pciCoeficientUpdated]);

  const createRowFuelPCI = (cells: any) => {
    return (
      <>
        <th key={cells[2].name}>
          <input
            type="text"
            name={cells[2].name}
            defaultValue={
              cells[2].value === null ? "-" : Number(cells[2].value).toFixed(0)
            }
            onChange={(e) =>
              handleChange(e.currentTarget.value, e.currentTarget.name)
            }
          />
        </th>
        <th key={cells[1].name}>
          <input
            type="text"
            name={cells[1].name}
            defaultValue={
              cells[1].value === null ? "-" : Number(cells[1].value).toFixed(0)
            }
            onChange={(e) =>
              handleChange(e.currentTarget.value, e.currentTarget.name)
            }
          />
        </th>
        <th key={cells[0].name}>
          <input
            type="text"
            name={cells[0].name}
            defaultValue={
              cells[0].value === null ? "-" : Number(cells[0].value).toFixed(0)
            }
            onChange={(e) =>
              handleChange(e.currentTarget.value, e.currentTarget.name)
            }
          />
        </th>
        <th key={cells[3].name}>
          <input
            type="text"
            name={cells[3].name}
            defaultValue={
              cells[3].value === null ? "-" : Number(cells[3].value).toFixed(0)
            }
            onChange={(e) =>
              handleChange(e.currentTarget.value, e.currentTarget.name)
            }
          />
        </th>
      </>
    );
  };

  const createRowFuelCoef = (cells: any) => {
    return (
      <>
        {cells[2].name !== "Rsquared" && (
          <th key={cells[2].name}>
            <input
              type="text"
              name={cells[2].name}
              defaultValue={
                cells[2].value === null
                  ? "-"
                  : Number(cells[2].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(e.currentTarget.value, e.currentTarget.name)
              }
            />
          </th>
        )}
        {cells[1].name !== "Rsquared" && (
          <th key={cells[1].name}>
            <input
              type="text"
              name={cells[1].name}
              defaultValue={
                cells[1].value === null
                  ? "-"
                  : Number(cells[1].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(e.currentTarget.value, e.currentTarget.name)
              }
            />
          </th>
        )}
        {cells[0].name !== "Rsquared" && (
          <th key={cells[0].name}>
            <input
              type="text"
              name={cells[0].name}
              defaultValue={
                cells[0].value === null
                  ? "-"
                  : Number(cells[0].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(e.currentTarget.value, e.currentTarget.name)
              }
            />
          </th>
        )}
        {cells[3].name !== "Rsquared" && (
          <th key={cells[3].name}>
            <input
              type="text"
              name={cells[3].name}
              defaultValue={
                cells[3].value === null
                  ? "-"
                  : Number(cells[3].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(e.currentTarget.value, e.currentTarget.name)
              }
            />
          </th>
        )}
      </>
    );
  };

  if (loading || pciCoeficient.length === 0) {
    return (
      <Container>
        <CircularProgress
          style={{ color: "#4CA6A8", margin: "0 auto", marginTop: "50px" }}
          size={50}
        />
      </Container>
    );
  }

  return (
    <Tables>
      <Container key={pciCoeficient[0].title}>
        <Title>
          {pciCoeficient[0].title === "PCI" ? "PCI" : "COEFICIENTE"}
        </Title>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>GCO</th>
              <th>GAF</th>
              <th>GAC</th>
              <th>Oleo/GN</th>
            </tr>
          </thead>
          <tbody>
            {pciCoeficient[0].table.map((combustivel, index) => (
              <tr key={index}>
                <th>{combustivel.name}</th>
                {createRowFuelPCI(combustivel.cells)}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Container key={pciCoeficient[1].title}>
        <Title>
          {pciCoeficient[1].title === "PCI" ? "PCI" : "COEFICIENTE"}
        </Title>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>GCO</th>
              <th>GAF</th>
              <th>GAC</th>
              <th>Oleo/GN</th>
            </tr>
          </thead>
          <tbody>
            {pciCoeficient[1].table.map((combustivel, index) => (
              <tr key={index}>
                <th>{combustivel.name}</th>
                {createRowFuelCoef(combustivel.cells)}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Tables>
  );
};
