import React from "react";

import { Switch, BrowserRouter, Route } from "react-router-dom";

import { Dashboard } from "./pages/Dashboard";
import { Login } from "./pages/Login";
import { Initial } from "./pages/Initial";

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Initial} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/auth" component={Login} />
      </Switch>
    </BrowserRouter>
  );
};
