export const datePickerAnalyse = [
  { value: "24h", label: "24H" },
  { value: "2d", label: "2D" },
  { value: "7d", label: "7D" },
  { value: "1M", label: "1M" },
];

export const datePickerHour = [
  { value: "24h", label: "24H" },
  { value: "7d", label: "7D" },
  { value: "1M", label: "1M" },
];

export const datePickerDay = [
  { value: "7d", label: "7D" },
  { value: "1M", label: "1M" },
  { value: "3M", label: "3M" },
];

export const datePickerWeek = [
  { value: "1M", label: "1M" },
  { value: "3M", label: "3M" },
];

export const datePickerMonth = [{ value: "3M", label: "3M" }];

export const buttonsDatePickerSimulation = [
  { value: "1h", label: "1H" },
  { value: "24h", label: "24H" },
  { value: "7d", label: "7D" },
  { value: "1M", label: "1M" },
];
