import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ComponentData } from "../../../../models/ComponentData";

import { AppState } from "../../../../store/rootReducer";

import { AlertTable } from "../../../../components/AlertTable";
import { NavigationTypeAlert } from "../../../../components/NavigationTypeAlert";

import { Container } from "./styles";
import {
  getNotifications,
  getNotificationsDone,
  updateNotification,
  updateNotificationDone,
} from "../../../../api";
import { NotificationData } from "../../../../models/NotificationData";
import { ErrorAlert } from "../../../../components/ErrorAlert";
import { formatDateTimeNotifications } from "../../../../utils/formatDateToDateRange";

export const Alerts = ({ componentName, tabAccessed }: ComponentData) => {
  const dispatch = useDispatch();

  const { identifier } = useSelector(
    (state: AppState) => state.nodeVisualize.node
  );

  const { typeNotification } = useSelector(
    (state: AppState) => state.notificationRule
  );

  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const [loading, setLoading] = useState(false);

  //const [openListError, setOpenListError] = useState(false);
  const [openUpdateError, setOpenUpdateError] = useState(false);

  useEffect(() => {
    tabAccessed("ALERTAS");
  }, [tabAccessed]);

  useEffect(() => {
    async function listNotifications() {
      setNotifications([]);
      setTimeout(async () => {
        if (typeNotification === "Encerrada") {
          await getNotificationsDone(identifier)
            .then((response) => {
              const { data } = response;
              const dataSorted = data.sort(
                (a: NotificationData, b: NotificationData) =>
                  formatDateTimeNotifications(b.lastNotificationTimestamp) -
                  formatDateTimeNotifications(a.lastNotificationTimestamp)
              );
              setNotifications(dataSorted);
            })
            .catch((error) => {
              //setOpenListError(true);
            });
        } else {
          await getNotifications(identifier)
            .then((response) => {
              const { data } = response;
              const dataSorted = data.sort(
                (a: NotificationData, b: NotificationData) =>
                  formatDateTimeNotifications(b.lastNotificationTimestamp) -
                  formatDateTimeNotifications(a.lastNotificationTimestamp)
              );
              const newNotifications = dataSorted.filter(
                (notification: NotificationData) =>
                  notification.status === typeNotification
              );
              setNotifications(newNotifications);
            })
            .catch((error) => {
              //setOpenListError(true);
            });
        }
        setLoading(false);
      }, 1000);

      setLoading(true);
    }
    listNotifications();
  }, [dispatch, identifier, typeNotification]);

  const handleUpdateNotification = async (
    id: number,
    status: string,
    comment: string
  ) => {
    if (typeNotification !== "Encerrada") {
      await updateNotification(id, [
        {
          comment,
          status,
        },
      ])
        .then((response) => {
          setNotifications([]);
          setTimeout(() => {
            getNotifications(identifier).then((response) => {
              const { data } = response;
              const dataSorted = data.sort(
                (a: NotificationData, b: NotificationData) =>
                  formatDateTimeNotifications(b.lastNotificationTimestamp) -
                  formatDateTimeNotifications(a.lastNotificationTimestamp)
              );
              const newNotifications = dataSorted.filter(
                (notification: NotificationData) =>
                  notification.status === typeNotification
              );
              setNotifications(newNotifications);
            });
            setLoading(false);
          }, 1000);

          setLoading(true);
        })
        .catch((error) => {
          setOpenUpdateError(true);
        });
    } else {
      await updateNotificationDone(id, [
        {
          comment,
          status,
        },
      ]).then((response) => {
        setNotifications([]);
        setTimeout(() => {
          getNotificationsDone(identifier).then((response) => {
            const { data } = response;
            const dataSorted = data.sort(
              (a: NotificationData, b: NotificationData) =>
                formatDateTimeNotifications(b.lastNotificationTimestamp) -
                formatDateTimeNotifications(a.lastNotificationTimestamp)
            );
            setNotifications(dataSorted);
          });
          setLoading(false);
        }, 1000);

        setLoading(true);
      });
    }
  };

  return (
    <Container>
      <h1>{componentName}</h1>
      <NavigationTypeAlert />

      <AlertTable
        loading={loading}
        notifications={notifications}
        onClickSelect={handleUpdateNotification}
      />

      {/* {openListError && (
        <ErrorAlert
          open={openListError}
          setOpenAlert={() => setOpenListError(false)}
          message="Não possui alertas para exibir"
        />
      )} */}

      {openUpdateError && (
        <ErrorAlert
          open={openUpdateError}
          setOpenAlert={() => setOpenUpdateError(false)}
          message="Ocorreu um erro ao tentar editar a regra, por favor tente novamente."
        />
      )}
    </Container>
  );
};
