import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ContainerFailure = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;

  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Logo = styled.div`
  color: #fff;
  height: 30px;
  margin-left: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
`;

export const ButtonClose = styled.button`
  width: 44px;
  height: 37px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  background-image: linear-gradient(to right, #19b0a0, #009ee2);

  cursor: pointer;
`;

export const TitleFailure = styled.h1`
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #ffffff;

  margin-top: 300px;
  margin-left: 150px;
  margin-right: 150px;
`;

export const PosOptmizationMessage = styled.h1`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  margin-top: 32px;
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    width: 100%;
    border-spacing: 0;
    overflow: hidden;

    thead {
      color: #fff;
      border-radius: 30px;

      tr {
        border: 1px solid #ffffff;
        border-radius: 30px;

        th {
          background: none;
          padding: 3px;
          text-align: center;
          padding: 5px;

          font-size: 20px;

          border: 1px solid #ffffff;
          font-weight: 600;
        }

        .no-border {
          border-bottom: none;
        }

        .no-border2 {
          border-top: none;
        }
      }
    }

    tbody {
      color: #ffffff;

      tr {
        border: 1px solid #ffffff;

        td {
          padding: 7px;
          text-align: center;
          border: 1px solid #ffffff;

          font-weight: 400;

          padding: 5px;

          font-size: 20px;

          &:nth-child(1) {
            width: 230px;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11) {
            width: 120px;
          }
        }
      }
    }
  }

  .sympleTable {
    tbody {
      tr {
        td {
          padding: 5px;

          font-size: 16px;

          &:nth-child(1) {
            width: 115px;
          }
        }
      }
    }
  }
`;

export const TitleTable = styled.h1`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;

  .tables {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Table2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overflow: hidden;

    thead {
      color: #fff;
      border-radius: 30px;

      tr {
        border: 1px solid #ffffff;
        border-radius: 30px;

        th {
          background: none;
          text-align: center;

          font-size: 18px;

          border: 1px solid #ffffff;
          font-weight: 600;

          width: 200px;
        }
      }
    }

    tbody {
      color: #ffffff;

      tr {
        border: 1px solid #ffffff;

        td {
          text-align: center;
          border: 1px solid #ffffff;

          font-weight: 400;

          font-size: 18px;

          &:nth-child(1) {
            width: 200px;
          }

          &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
            width: 220px;
          }
        }
      }
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  gap: 39px;
`;

export const Info = styled.div`
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: bold;

  text-align: center;

  margin: 0 auto;

  gap: 8px;

  h1 {
    font-size: 18px;
    color: #ffffff;
  }

  div {
    background: #4b4848;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    width: 297px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-align: center;

      color: #ffffff;
    }
  }

  &:first-child {
    div {
      background: #d4d4d4;
      border: 1px solid #d4d4d4;

      p {
        color: #3a3a3a;
      }
    }

    .red {
      background: #ff0000;
      border: 1px solid #ff0000;

      p {
        color: #fff;
      }
    }
  }
`;
