import React from "react";

import { Tooltip, createStyles, makeStyles } from "@material-ui/core";
import RestoreIcon from "@material-ui/icons/Restore";

import { SimulationDatePickerProps } from "../../models/GraphicData";

import { SimulationDateRange } from "../SimulationDateRange";

import { useDispatch } from "react-redux";

import { calculateItems, dateSimulation } from "../../store/Simulation/actions";

import {
  dateDecisionMaking,
} from "../../store/DecisionMaking/actions";

import { Button, Group } from "./styles";
import { lastWeekPeriodOfTime } from "../../utils/dateManager";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: "55px",
      height: "35px",
      backgroundColor: "rgba(230, 232, 235, 0.5)",
      color: "rgba(0, 0, 0, 0.5)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
    },
    buttonClicked: {
      width: "55px",
      height: "35px",
      background: "linear-gradient(95.44deg, #61C885 0.73%, #1AB1C5 100.24%)",
      color: "#fff",
      border: "1px solid #61C885",
      borderRadius: "5px",
    },
    tooltipSpan:{
      fontSize: "12px"
    },
  })
);

export const SimulationDatePicker = ({
  dateClicked,
  handleDatePicker,
  handleDateColor,
}: SimulationDatePickerProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Group>
      <Tooltip
            title={<span className={classes.tooltipSpan} >Regressão Última Semana</span>}>
        <Button
          className={
            dateClicked === "InitialDate" ? classes.buttonClicked : classes.button
          }
          onClick={() => {
            handleDatePicker("InitialDate");
            handleDateColor("InitialDate");
            dispatch(calculateItems(true, true));
          }}
        >
          
          <RestoreIcon />
        </Button>
      </Tooltip>
      <SimulationDateRange
        clickDateRange={handleDateColor}
        dateClicked={dateClicked}
        typeDateRange="hour"
      />
      <SimulationDateRange
        clickDateRange={handleDateColor}
        dateClicked={dateClicked}
        typeDateRange="day"
      />
      <SimulationDateRange
        clickDateRange={handleDateColor}
        dateClicked={dateClicked}
        typeDateRange="week"
      />
      <SimulationDateRange
        clickDateRange={handleDateColor}
        dateClicked={dateClicked}
        typeDateRange="month"
      />
    </Group>
  );
};
