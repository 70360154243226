import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress, Modal } from "@material-ui/core";

import { getOptimizationRealTime } from "../../../../../../api";

import { AppState } from "../../../../../../store/rootReducer";
import { fuelValueRealDecisionMaking } from "../../../../../../store/DecisionMaking/actions";

import { FuelDemandReal } from "../../../../../../models/OptimizationData";

import { ErrorAlert } from "../../../../../../components/ErrorAlert";
import { GradientButton } from "../../../../../../components/GradientButton";
import { RealTimeOptimizationModal } from "../../../../../../components/RealTimeOptimizationModal";

import { Container, Title, Table, Buttons, Input } from "./styles";

const style = {
  width: "100%",
  height: "100%",
  bgcolor: "#3A3A3A",
  overflow: "hidden auto",
  p: 4,
};

type NonValidData = {
  validation_model: boolean;
  validations: Validations[];

}

type Validations = {
  message: string;
}


interface RealTimeLimitOptimizationProps {
  componentName: string;
  handleTab: (value: string) => void;
}

export const RealTimeLimitOptimization = ({
  componentName,
  handleTab,
}: RealTimeLimitOptimizationProps) => {
  const dispatch = useDispatch();

  const { pciCoeficient, fuelValue, flowValue, fuelReal } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  const [realTimeLimits, setRealTimeLimits] =
    useState<FuelDemandReal[]>(fuelReal);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [loader, setLoader] = useState(false);

  const [dataRealTime, setDataRealTime] = useState([]);

  const [error, setError] = useState(false);

  const [errorModelValidation, setErrorModelValidation] = useState({
    error: false,
    message: "",
  });

  const [posValidationMessage, setPosValidationMessage] = useState("");
  
  const handlePosValidationMessage = (messages: any) => {

    let finalMessage = "";
    messages.forEach((message: string) => {
      finalMessage += message;
    });

    setPosValidationMessage(finalMessage);
  };


  const handleMinRealTimeLimits = (value: number, fuel: string) => {
    if (value < 0) {
      setRealTimeLimits(
        realTimeLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  lower: value,
                  alertLower: true,
                };
              }
              return item;
            }),
          };
        })
      );
    } else {
      setRealTimeLimits(
        realTimeLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  lower: value,
                  alertLower: false,
                };
              }
              return item;
            }),
          };
        })
      );
    }
  };

  const handleMaxRealTimeLimits = (value: number, fuel: string) => {
    if (value < 0) {
      setRealTimeLimits(
        realTimeLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  upper: value,
                  alertUpper: true,
                };
              }
              return item;
            }),
          };
        })
      );
    } else {
      setRealTimeLimits(
        realTimeLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  upper: value,
                  alertUpper: false,
                };
              }
              return item;
            }),
          };
        })
      );
    }
  };

  const clickOptimizationButton = () => {
    if (
      realTimeLimits[0].table.every(
        (item) => !item.alertLower && !item.alertUpper
      )
    ) {
      setLoader(true);
      dispatch(fuelValueRealDecisionMaking(realTimeLimits));

      const pciCoefFinal = pciCoeficient.map((item) => {
        if (item.title === "PCI") {
          return {
            ...item,
            table: item.table.map((item) => {
              return {
                ...item,
                cells: item.cells.map((item) => {
                  return {
                    ...item,
                    name: item.name.includes("C4")
                      ? item.name.replace("OleoGN", "GN")
                      : item.name.replace("OleoGN", "OL"),
                  };
                }),
              };
            }),
          };
        } else {
          return {
            ...item,
            table: item.table.map((item) => {
              return {
                ...item,
                cells: item.cells
                  .map((item) => {
                    return {
                      ...item,
                      name: item.name.includes("C4")
                        ? item.name.replace("OleoGN", "GN")
                        : item.name.replace("OleoGN", "OL"),
                    };
                  })
                  .slice(0, item.cells.length - 1),
              };
            }),
          };
        }
      });

      const fuelValueFinal = fuelValue.map((item) => {
        return {
          ...item,
          table: item.table.map((item) => {
            return {
              ...item,
              cells: item.cells.map(({ name, lower1, upper1 , lower2, upper2 }) => {
                return {
                  name,
                  lower1,
                  upper1,
                  lower2,
                  upper2,
                };
              }),
            };
          }),
        };
      });

      const flowValueFinal = flowValue.map((item) => {
        return {
          ...item,
          table: item.table.map(({ name, upper, lower, considered }) => {
            return {
              name,
              upper,
              lower,
              considered,
            };
          }),
          maxDifference: item.maxDifference.map(({ value }) => {
            return {
              value,
            }
          }),
        };
      });

      const fuelDemandRealFinal = realTimeLimits.map((item) => {
        return {
          ...item,
          table: item.table.map(({ name, upper, lower }) => {
            return {
              name,
              upper,
              lower,
            };
          }),
        };
      });

      const result = [
        pciCoefFinal[0],
        pciCoefFinal[1],
        fuelValueFinal[0],
        flowValueFinal[0],
        fuelDemandRealFinal[0],
      ];

      getOptimizationRealTime(JSON.stringify(result))
        .then((response) => {
          const {data} = response;
          console.log(data)
          if (!data[0].validation_model) {
            setLoader(false);
            setOpen(false);
            data[0].validations?.forEach((item: any) => {
              setErrorModelValidation({
                error: true,
                message: item.message,
              });
            });
            setError(false);
          } else {
            handlePosValidationMessage(data[0].optimization.pos_validation.message)
            setDataRealTime(data);
            setLoader(false);
            setOpen(true);
            setError(false);
            setErrorModelValidation({
              error: false,
              message: "Mensagem de Sucesso",
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          setError(true);
          setOpen(false);
          console.log(error)
          setErrorModelValidation({
            error: false,
            message: "Mensagem de Erro",
          });
        });
    } else {
      setOpenAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleAlertCloseError = () => {
    setError(false);
  };

  return (
    <Container>
      <Title>LIMITES EM TEMPO REAL</Title>
      <div>
        <Table>
          <thead>
            <tr>
              <th colSpan={3} className="title-table">
                DISPONIBILIDADE DOS COMBUSTÍVEIS
              </th>
            </tr>
            <tr>
              <th>COMBUSTÍVEL</th>
              <th>BANDA INFERIOR</th>
              <th>BANDA SUPERIOR</th>
            </tr>
          </thead>

          <tbody>
            {realTimeLimits.map((item) =>
              item.table.map((item) => (
                <tr key={item.fuel + item.unit}>
                  <td>{item.fuel === "Oleo" ? "Óleo" : item.fuel}</td>
                  <td>
                    <Input
                      type="number"
                      error={item.alertLower}
                      defaultValue={item.lower}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleMinRealTimeLimits(value, item.fuel);
                      }}
                    />
                    <span>{item.unit}</span>
                  </td>
                  <td>
                    <Input
                      type="number"
                      error={item.alertUpper}
                      defaultValue={item.upper}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleMaxRealTimeLimits(value, item.fuel);
                      }}
                    />
                    <span>{item.unit}</span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <GradientButton
          style={{ width: "100%" }}
          onClick={() => clickOptimizationButton()}
        >
          {loader ? (
            <CircularProgress
              style={{ color: "white", width: "25px", height: "25px" }}
            />
          ) : (
            "OTIMIZAR"
          )}
        </GradientButton>
      </div>

      <Buttons>
        <GradientButton
          onClick={() => {
            dispatch(fuelValueRealDecisionMaking(realTimeLimits));
            handleTab("otimização");
          }}
        >
          Voltar
        </GradientButton>
      </Buttons>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <RealTimeOptimizationModal
            data={dataRealTime}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      {error && (
        <ErrorAlert
          
          open={error}
          setOpenAlert={handleAlertCloseError}
          message = {posValidationMessage}
          
        />
      )}

      {errorModelValidation.error && (
        <ErrorAlert
          open={errorModelValidation.error}
          setOpenAlert={() =>
            setErrorModelValidation({ error: false, message: "Alerta de erro" })
          }
          message={errorModelValidation.message}
        />
      )}

      {openAlert && (
        <ErrorAlert
          open={openAlert}
          setOpenAlert={handleAlertClose}
          message="As bandas definidas não podem ter números negativos. Por favor altere os campos em vermelho."
        />
      )}
    </Container>
  );
};
