import React from "react";

import { useRouteMatch } from "react-router-dom";

import { NavigationData } from "../../models/NavigationData";

import {
  navigationEquipment,
  navigationProcess,
} from "../../utils/navigations";

import { Link } from "../Link";

import { Container } from "./styles";

interface NavigationProps {
  linkSelected: string;
  setLinkSelected: (linkRoute: string) => void;
}

export const Navigation = ({
  linkSelected,
  setLinkSelected,
}: NavigationProps) => {
  const match = useRouteMatch();

  const navigationLinks = match.path === "/dashboard/equipamento";

  const renderLink = (link: NavigationData, index: number) => (
    <Link
      key={index}
      title={link.label}
      checked={link.label === linkSelected}
      clickLink={() => setLinkSelected(link.route)}
    />
  );

  return (
    <Container>
      {navigationLinks
        ? navigationEquipment.map((link, index) => renderLink(link, index))
        : navigationProcess.map((link, index) => renderLink(link, index))}
    </Container>
  );
};
