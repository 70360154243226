export const navigationEquipment = [
  { label: "PERFORMANCE", route: "performance" },
  { label: "DADOS", route: "dados" },
  { label: "SOBRE", route: "sobre" },
];

export const navigationProcess = [
  { label: "PERFORMANCE", route: "performance" },
  { label: "ANÁLISE", route: "analise" },
  { label: "SIMULAÇÃO", route: "simulacao" },
  { label: "TOMADA DE DECISÃO", route: "tomada-decisao" },
  { label: "PREDIÇÃO DE FALHAS", route: "predicao-falhas" },
  { label: "ALERTAS", route: "alertas" },
  { label: "DADOS", route: "dados" },
];
