import React from "react";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface ErrorAlertProps {
  setOpenAlert: (open: boolean) => void;
  open: boolean;
  message: string;
}

export const ErrorAlert = ({ setOpenAlert, open, message }: ErrorAlertProps) => {
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        {message}
      </Alert>
    </Snackbar>
  );
};
