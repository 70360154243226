import styled from "styled-components";

interface NameProps {
  color: string;
  cursor: string;
  weight: string;
}

export const TreeItem = styled.div`
  margin-left: 15px;
`;

export const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-bottom: 10px;
`;

export const Name = styled.p<NameProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  width: 100%;
  display: block;
  overflow: hidden;
  cursor: ${({ cursor }) => (cursor === "true" ? "pointer" : "default")};
  padding-left: 10px;
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;
