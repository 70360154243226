import {
  TagsAnalyzeTypes,
  TagsAnalyzeState,
  TAGS_SELECTED1,
  TAGS_SELECTED2,
  TAGS_RESULT,
} from "./types";

const initialState: TagsAnalyzeState = {
  tagsArray: [],
  tags1: [],
  tags2: [],
};

export const TagAnalyzeReducer = (
  state = initialState,
  action: TagsAnalyzeTypes
) => {
  switch (action.type) {
    case TAGS_RESULT: {
      return {
        ...state,
        tagsArray: action.payload,
      };
    }
    case TAGS_SELECTED1: {
      return {
        ...state,
        tags1: action.payload,
      };
    }
    case TAGS_SELECTED2: {
      return {
        ...state,
        tags2: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
