import { UserData } from "../../models/UserData";

export interface AuthState {
  user: UserData;
  isAuth: boolean;
  error: boolean;
  errorMessage: string;
}

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILED = "SIGNIN_FAILED";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

export interface SigninSuccess {
  type: typeof SIGNIN_SUCCESS;
  payload: any;
}

export interface SigninFailed {
  type: typeof SIGNIN_FAILED;
  payload: string;
}

export interface AuthSuccess {
  type: typeof AUTH_SUCCESS;
  payload: any;
}

export interface SignOutSuccess {
  type: typeof SIGNOUT_SUCCESS;
  payload: boolean;
}

export type AuthDispatchTypes =
  | SigninSuccess
  | SigninFailed
  | AuthSuccess
  | SignOutSuccess;
