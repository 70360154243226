import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Alert } from "@material-ui/lab";
import { createStyles, makeStyles, Modal, Button, Tooltip } from "@material-ui/core";

import { AppState } from "../../store/rootReducer";
import { setDate } from "../../store/Graphic/actions";
import {
  calculateItems,
  dateSimulation,
  errorDateSimulation,
} from "../../store/Simulation/actions";
import {
  calculateItemsDecisionMaking,
  dateDecisionMaking,
} from "../../store/DecisionMaking/actions";

import { formatDateToDateRange } from "../../utils/formatDateToDateRange";
import {
  datePickerDay,
  datePickerHour,
  datePickerMonth,
  datePickerWeek,
} from "../../utils/buttonsDatePicker";

import { useStyles } from "../AlertError";
import { PerformanceDatePicker } from "../PerformanceDatePicker";
import { GraphicSimulationTables } from "../GraphicSimulationTables";

import {
  DivText,
  Text,
  DivButton,
  ButtonModal,
  DivForm,
  FormGroup,
  InputDate,
  InputTime,
  Label,
  Form,
  Filter,
} from "./styles";

const modalStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      display: "flex",
      flexDirection: "column",
      zIndex: -1,
      alignItems: "center",
      backgroundColor: "#fff",
      overflow: "auto",
    },
    button: {
      width: "40px",
      height: "35px",
      backgroundColor: "rgba(230, 232, 235, 0.5)",
      color: "rgba(0, 0, 0, 0.5)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
    },
    buttonClicked: {
      width: "55px",
      height: "35px",
      background: "linear-gradient(95.44deg, #61C885 0.73%, #1AB1C5 100.24%)",
      color: "#fff",
      border: "1px solid #61C885",
    },
    toolTipSpan: {
      fontSize: "12px"
    },
  })
);

interface SimulationDateRangeProps {
  dateClicked: string;
  typeDateRange: string;
  clickDateRange: (valueButton: string) => void;
}

export const SimulationDateRange = ({
  clickDateRange,
  dateClicked,
  typeDateRange,
}: SimulationDateRangeProps) => {
  const classes = modalStyles();
  const classesError = useStyles();
  const dispatch = useDispatch();

  const erroMessageSimulation = useSelector(
    (state: AppState) => state.simulation.errorSetDateSimulation
  );

  const [open, setOpen] = useState(false);

  const [initial, setInitial] = useState({
    date: "",
    time: "",
  });

  const [calculateItemsDay, setCalculateItemsDay] = useState({
    coef: true,
    pci: true,
  });

  const [calculateItemsHour, setCalculateItemsHour] = useState({
    coef: true,
    pci: true,
  });

  const [calculateItemsWeek, setCalculateItemsWeek] = useState({
    coef: true,
    pci: true,
  });

  const [calculateItemsMonth, setCalculateItemsMonth] = useState({
    coef: true,
    pci: true,
  });

  const initialDate = useSelector(
    (state: AppState) => state.graphic.date.initial
  );
  const finalDate = useSelector((state: AppState) => state.graphic.date.final);

  const initialState = () => {
    if (typeDateRange === "hour") {
      return "24h";
    } else if (typeDateRange === "day") {
      return "7d";
    } else if (typeDateRange === "week") {
      return "1M";
    } else {
      return "3M";
    }
  };

  const [dateClickedModal, setDateClickedModal] = useState(initialState);

  const handleDatePicker = (value: string) => {
    dispatch(setDate(`now-${value}`, "now"));
  };

  const handleDateColor = (value: string) => {
    setDateClickedModal(value);
  };

  const getSimulation = new Date().setDate(new Date().getDate());
  const actualDateSimulation = new Date(getSimulation)
    .toISOString()
    .split("T")[0];

  const handleClick = () => {
    setOpen((state) => !state);
  };

  const handleHour = () => {
    const initialDateFormated = formatDateToDateRange(
      initial.date,
      initial.time
    );

    if (
      initial.date === "" ||
      initial.time === "" ||
      (calculateItemsHour.coef === false && calculateItemsHour.pci === false)
    ) {
      dispatch(
        errorDateSimulation(
          "Não foi possível aplicar um período, por favor tente novamente! O último período selecionado foi mantido"
        )
      );
    } else {
      dispatch(
        dateSimulation(
          Math.round(new Date(initialDateFormated).getTime() / 1000).toString(),
          (
            Math.round(new Date(initialDateFormated).getTime() / 1000) + 3600
          ).toString()
        )
      );
      dispatch(
        dateDecisionMaking(
          Math.round(new Date(initialDateFormated).getTime() / 1000).toString(),
          (
            Math.round(new Date(initialDateFormated).getTime() / 1000) + 3600
          ).toString()
        )
      );
      dispatch(calculateItems(calculateItemsHour.pci, calculateItemsHour.coef));
      dispatch(
        calculateItemsDecisionMaking(
          calculateItemsHour.pci,
          calculateItemsHour.coef
        )
      );
      clickDateRange(typeDateRange);
      setDateClickedModal(initialState);
      dispatch(setDate(`now-${initialState}`, "now"));
    }
  };

  const handleDay = () => {
    const date = new Date(initial.date).setHours(0, 0, 0, 0);
    const newDate = new Date(date).setMinutes(0, 0, 0);
    const initialDate = Math.round(new Date(newDate).getTime() / 1000) + 86400;

    const finalDate = initialDate + 86400;

    if (
      initial.date === "" ||
      (calculateItemsDay.pci === false && calculateItemsDay.coef === false)
    ) {
      dispatch(
        errorDateSimulation(
          "Não foi possível aplicar um período, por favor tente novamente! O último período selecionado foi mantido"
        )
      );
      alert(
        "Não foi possível aplicar um período / variáveis para serem calculadas, por favor tente novamente!  O último período e variáveis selecionadas foi mantido"
      );
    } else {
      dispatch(dateSimulation(initialDate.toString(), finalDate.toString()));
      dispatch(
        dateDecisionMaking(initialDate.toString(), finalDate.toString())
      );
      dispatch(calculateItems(calculateItemsDay.pci, calculateItemsDay.coef));
      dispatch(
        calculateItemsDecisionMaking(
          calculateItemsDay.pci,
          calculateItemsDay.coef
        )
      );
      clickDateRange(typeDateRange);
      setDateClickedModal(initialState);
      dispatch(setDate(`now-${initialState}`, "now"));
    }
  };

  const handleWeek = () => {
    const date = new Date(initial.date).getTime() / 1000 + 10800;
    const day = new Date(initial.date).getDay() + 1;

    const initialDate = date - day * 86400;
    const finalDate = initialDate + 604800;

    if (
      initial.date === "" ||
      (calculateItemsWeek.pci === false && calculateItemsWeek.coef === false)
    ) {
      dispatch(
        errorDateSimulation(
          "Não foi possível aplicar um período, por favor tente novamente! O último período selecionado foi mantido"
        )
      );
      alert(
        "Não foi possível aplicar um período / variáveis para serem calculadas, por favor tente novamente!  O último período e variáveis selecionadas foi mantido"
      );
    } else {
      dispatch(dateSimulation(initialDate.toString(), finalDate.toString()));
      dispatch(
        dateDecisionMaking(initialDate.toString(), finalDate.toString())
      );
      dispatch(calculateItems(calculateItemsWeek.pci, calculateItemsWeek.coef));
      dispatch(
        calculateItemsDecisionMaking(
          calculateItemsWeek.pci,
          calculateItemsWeek.coef
        )
      );
      clickDateRange(typeDateRange);
      setDateClickedModal(initialState);
      dispatch(setDate(`now-${initialState}`, "now"));
    }
  };

  const handleMonth = () => {
    const date = new Date(initial.date).getTime() / 1000 + 10800;
    const day = new Date(initial.date).getDate();

    const initialDate = date - day * 86400;
    const finalDate = initialDate + 2419200;

    if (
      initial.date === "" ||
      (calculateItemsMonth.pci === false && calculateItemsMonth.coef === false)
    ) {
      dispatch(
        errorDateSimulation(
          "Não foi possível aplicar um período, por favor tente novamente! O último período selecionado foi mantido"
        )
      );
      alert(
        "Não foi possível aplicar um período / variáveis para serem calculadas, por favor tente novamente!  O último período e variáveis selecionadas foi mantido"
      );
    } else {
      dispatch(dateSimulation(initialDate.toString(), finalDate.toString()));
      dispatch(
        dateDecisionMaking(initialDate.toString(), finalDate.toString())
      );
      dispatch(
        calculateItems(calculateItemsMonth.pci, calculateItemsMonth.coef)
      );
      dispatch(
        calculateItemsDecisionMaking(
          calculateItemsMonth.pci,
          calculateItemsMonth.coef
        )
      );
      clickDateRange(typeDateRange);
      setDateClickedModal(initialState);
      dispatch(setDate(`now-${initialState}`, "now"));
    }
  };

  const body = (
    <div className={classes.paper}>
      <DivText>
        <Text>Selecione um período</Text>
      </DivText>

      {typeDateRange === "hour" && (
        <>
          <Filter>
            <PerformanceDatePicker
              data={datePickerHour}
              handleDatePicker={handleDatePicker}
              handleDateColor={handleDateColor}
              dateClicked={dateClickedModal}
            />
          </Filter>

          <GraphicSimulationTables
            initialDate={initialDate}
            finalDate={finalDate}
            dateClicked={dateClickedModal}
            typeGraphic="hora"
          />
          <Form>
            <DivForm>
              <FormGroup>
                <Label>Data</Label>
                <InputDate
                  min="2021-06-01"
                  max={actualDateSimulation}
                  type="date"
                  onChange={(e) =>
                    setInitial({ ...initial, date: e.target.value })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>Hora</Label>
                <InputTime
                  type="time"
                  onChange={(e) =>
                    setInitial({ ...initial, time: e.target.value })
                  }
                />
              </FormGroup>
            </DivForm>
          </Form>

          <Form
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">PCI</label>
              <input
                type="checkbox"
                placeholder="PCI"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsHour({
                      ...calculateItemsHour,
                      pci: true,
                    });
                  } else {
                    setCalculateItemsHour({
                      ...calculateItemsHour,
                      pci: false,
                    });
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">COEFICIENTE</label>
              <input
                type="checkbox"
                placeholder="COEFICIENT"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsHour({
                      ...calculateItemsHour,
                      coef: true,
                    });
                  } else {
                    setCalculateItemsHour({
                      ...calculateItemsHour,
                      coef: false,
                    });
                  }
                }}
              />
            </div>
          </Form>
        </>
      )}

      {typeDateRange === "day" && (
        <>
          <Filter>
            <PerformanceDatePicker
              data={datePickerDay}
              handleDatePicker={handleDatePicker}
              handleDateColor={handleDateColor}
              dateClicked={dateClickedModal}
            />
          </Filter>
          <GraphicSimulationTables
            initialDate={initialDate}
            finalDate={finalDate}
            typeGraphic="dia"
            dateClicked={dateClickedModal}
          />
          <Form>
            <DivForm>
              <FormGroup>
                <Label>Data</Label>
                <InputDate
                  min="2021-06-01"
                  defaultValue={0}
                  max={actualDateSimulation}
                  type="date"
                  onChange={(e) =>
                    setInitial({ ...initial, date: e.target.value })
                  }
                />
              </FormGroup>
            </DivForm>
          </Form>

          <Form
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">PCI</label>
              <input
                type="checkbox"
                placeholder="PCI"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsDay({
                      ...calculateItemsDay,
                      pci: true,
                    });
                  } else {
                    setCalculateItemsDay({
                      ...calculateItemsDay,
                      pci: false,
                    });
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">COEFICIENTE</label>
              <input
                type="checkbox"
                placeholder="COEF"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsDay({
                      ...calculateItemsDay,
                      coef: true,
                    });
                  } else {
                    setCalculateItemsDay({
                      ...calculateItemsDay,
                      coef: false,
                    });
                  }
                }}
              />
            </div>
          </Form>
        </>
      )}

      {typeDateRange === "week" && (
        <>
          <Filter>
            <PerformanceDatePicker
              data={datePickerWeek}
              handleDatePicker={handleDatePicker}
              handleDateColor={handleDateColor}
              dateClicked={dateClickedModal}
            />
          </Filter>
          <GraphicSimulationTables
            initialDate={initialDate}
            finalDate={finalDate}
            dateClicked={dateClickedModal}
            typeGraphic="semana"
          />
          <Form>
            <DivForm>
              <FormGroup>
                <Label>Data</Label>
                <InputDate
                  min="2021-06-01"
                  defaultValue={0}
                  max={actualDateSimulation}
                  type="date"
                  onChange={(e) =>
                    setInitial({ ...initial, date: e.target.value })
                  }
                />
              </FormGroup>
            </DivForm>
          </Form>

          <Form
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">PCI</label>
              <input
                type="checkbox"
                placeholder="PCI"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsWeek({
                      ...calculateItemsWeek,
                      pci: true,
                    });
                  } else {
                    setCalculateItemsWeek({
                      ...calculateItemsWeek,
                      pci: false,
                    });
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">COEFICIENTE</label>
              <input
                type="checkbox"
                placeholder="COEF"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsWeek({
                      ...calculateItemsWeek,
                      coef: true,
                    });
                  } else {
                    setCalculateItemsWeek({
                      ...calculateItemsWeek,
                      coef: false,
                    });
                  }
                }}
              />
            </div>
          </Form>
        </>
      )}

      {typeDateRange === "month" && (
        <>
          <Filter>
            <PerformanceDatePicker
              data={datePickerMonth}
              handleDatePicker={handleDatePicker}
              handleDateColor={handleDateColor}
              dateClicked={dateClickedModal}
            />
          </Filter>
          <GraphicSimulationTables
            initialDate={initialDate}
            finalDate={finalDate}
            dateClicked={dateClickedModal}
            typeGraphic="mes"
          />
          <Form>
            <DivForm>
              <FormGroup>
                <Label>Data</Label>
                <InputDate
                  min="2021-06-01"
                  defaultValue={0}
                  max={actualDateSimulation}
                  type="date"
                  onChange={(e) =>
                    setInitial({ ...initial, date: e.target.value })
                  }
                />
              </FormGroup>
            </DivForm>
          </Form>

          <Form
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">PCI</label>
              <input
                type="checkbox"
                placeholder="PCI"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsMonth({
                      ...calculateItemsMonth,
                      pci: true,
                    });
                  } else {
                    setCalculateItemsMonth({
                      ...calculateItemsMonth,
                      pci: false,
                    });
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <label htmlFor="">COEFICIENTE</label>
              <input
                type="checkbox"
                placeholder="COEF"
                defaultChecked
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setCalculateItemsMonth({
                      ...calculateItemsMonth,
                      coef: true,
                    });
                  } else {
                    setCalculateItemsMonth({
                      ...calculateItemsMonth,
                      coef: false,
                    });
                  }
                }}
              />
            </div>
          </Form>
        </>
      )}

      <DivButton>
        <ButtonModal
          typeButton="cancelar"
          onClick={() => {
            handleClick();
            setDateClickedModal(initialState);
            dispatch(setDate(`now-${initialState}`, "now"));
          }}
        >
          CANCELAR
        </ButtonModal>
        <ButtonModal
          typeButton="aplicar"
          onClick={() => {
            handleClick();
            typeDateRange === "hour" && handleHour();
            typeDateRange === "day" && handleDay();
            typeDateRange === "week" && handleWeek();
            typeDateRange === "month" && handleMonth();
          }}
        >
          APLICAR
        </ButtonModal>
      </DivButton>
    </div>
  );

  return (
    <div>
      <Tooltip
            title={<span className={classes.toolTipSpan} >
            {typeDateRange === "hour" && "Regressão Horária"}
            {typeDateRange === "day" && "Regressão Diária"}
            {typeDateRange === "week" && "Regressão Semanal"}
            {typeDateRange === "month" && "Regressão Mensal"}</span>}>
      <Button
        className={
          dateClicked === typeDateRange ? classes.buttonClicked : classes.button
        }
        onClick={handleClick}
      >
        {typeDateRange === "hour" && "Hora"}
        {typeDateRange === "day" && "Dia"}
        {typeDateRange === "week" && "Semana"}
        {typeDateRange === "month" && "Mês"}
      </Button>
      </Tooltip>

      <Modal open={open} onClose={handleClick}>
        {body}
      </Modal>

      {erroMessageSimulation === "" ? (
        ""
      ) : (
        <div className={classesError.root}>
          <Alert variant="filled" severity="error">
            {erroMessageSimulation}
          </Alert>
        </div>
      )}
    </div>
  );
};
