import styled from "styled-components";

import { supportedDevices } from "../../styles/supportedDevices";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  background: #e8ebed;

  min-height: 45px;

  border-radius: 8px;
  margin-bottom: 20px;

  @media ${supportedDevices.tablet} {
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }
`;
