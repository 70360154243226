import { RuleActionData } from "../../models/NotificationData";
import {
  LIST_RULES,
  NotificationsAndRuleDispatchTypes,
  NotificationRuleState,
  ACTION_RULE,
  LIST_NOTIFICATIONS,
  SET_TYPE_NOTIFICATIONS,
} from "./types";

const initialState: NotificationRuleState = {
  notifications: [],
  typeNotification: "Pendente",
  rules: [],
  actionRule: {
    id: undefined,
    typeAction: "",
    rule: {} as RuleActionData,
  },
};

export const NotificationRuleReducer = (
  state = initialState,
  action: NotificationsAndRuleDispatchTypes
) => {
  switch (action.type) {
    case LIST_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_TYPE_NOTIFICATIONS:
      return {
        ...state,
        typeNotification: action.payload,
      };
    case LIST_RULES:
      return {
        ...state,
        rules: action.payload,
      };
    case ACTION_RULE:
      return {
        ...state,
        actionRule: action.payload,
      };
    default:
      return state;
  }
};
