import styled from "styled-components";

export const Container = styled.section`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  background-color: rgb(58, 58, 58);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

export const Logo = styled.div`
  color: #fff;
  height: 30px;
  cursor: pointer;
  margin-left: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ShowTree = styled.div`
  display: flex;
  height: 40px;
  margin: auto 0;
`;
