import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../store/rootReducer";
import {
  tagsSelected1,
  tagsSelected2,
} from "../../../../store/TagAnalyze/actions";
import { setDate } from "../../../../store/Graphic/actions";

import { ComponentData } from "../../../../models/ComponentData";
import { TagProps } from "../../../../models/GraphicData";

import { datePickerAnalyse } from "../../../../utils/buttonsDatePicker";

import { GraphicTagsComparison } from "../../../../components/GraphicTagsComparison";
import { TagSelector } from "../../../../components/TagSelector";

import { Container, Title, Analyze, Filter } from "./styles";
import { PerformanceDatePicker } from "../../../../components/PerformanceDatePicker";

export const AnalyzeProcess = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  const dispatch = useDispatch();

  const initialDate = useSelector(
    (state: AppState) => state.graphic.date.initial
  );
  const finalDate = useSelector((state: AppState) => state.graphic.date.final);

  const tags1 = useSelector((state: AppState) => state.tagsAnalyze.tags1);
  const tags2 = useSelector((state: AppState) => state.tagsAnalyze.tags2);

  const [selectTags1, setSelectTags1] = useState<TagProps[]>([]);
  const [selectTags2, setSelectTags2] = useState<TagProps[]>([]);

  const initialState = "24h";
  const [dateClicked, setDateClicked] = useState(initialState);

  function handleChangeTags1(_: any, values: TagProps[]) {
    setSelectTags1(values);
  }

  function handleChangeTags2(_: any, values: TagProps[]) {
    setSelectTags2(values);
  }

  const handleDatePicker = (value: string) => {
    dispatch(setDate(`now-${value}`, "now"));
  };

  const handleDateColor = (value: string) => {
    setDateClicked(value);
  };

  useEffect(() => {
    tabAccessed("ANÁLISE");
  });

  useEffect(() => {
    dispatch(setDate(`now-${initialState}`, "now"));
  }, [dispatch, initialState]);

  useEffect(() => {
    dispatch(tagsSelected1(selectTags1));
  }, [dispatch, selectTags1]);

  useEffect(() => {
    dispatch(tagsSelected2(selectTags2));
  }, [dispatch, selectTags2]);

  return (
    <Container>
      <Filter>
        <Title>{componentName}</Title>
        <PerformanceDatePicker
          data={datePickerAnalyse}
          handleDatePicker={handleDatePicker}
          handleDateColor={handleDateColor}
          dateClicked={dateClicked}
        />
      </Filter>

      <Analyze>
        <TagSelector onChange={handleChangeTags1} tags={selectTags1} />

        <TagSelector onChange={handleChangeTags2} tags={selectTags2} />
      </Analyze>

      <GraphicTagsComparison
        tags1={tags1}
        tags2={tags2}
        initialDate={initialDate}
        finalDate={finalDate}
      />
    </Container>
  );
};
