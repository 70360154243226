import styled from "styled-components";

export const Container = styled.div`
  button {
    width: 100%;
    height: 45px;

    border: 0;
    border-radius: 8px;

    background: linear-gradient(90deg, #5fc787 0%, #1cb1c4 100%);

    color: #fff;
    font-size: 18px;
    font-weight: bold;

    margin-top: 15px;

    cursor: pointer;
  }

  .alert {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
  }
`;
