import React, { useEffect } from "react";

import { ComponentData } from "../../../../models/ComponentData";

import { Container, Title } from "./styles";

export const DiceEquipment = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  useEffect(() => {
    tabAccessed("DADOS");
  });

  return (
    <Container>
      <Title>{componentName}</Title>
      Dados (Em desenvolvimento)
    </Container>
  );
};
