import styled from "styled-components";

import { supportedDevices } from "../../../../styles/supportedDevices";

export const Title = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-left: -12px;
  }

  svg {
    color: black;
  }

  h1 {
    color: black;
    font-size: 20px;
    font-weight: bold;

    @media ${supportedDevices.laptop} {
      font-size: 32px;
    }
  }
`;

export const ForgotPassMsg = styled.span`
  color: black;
  font-size: 14px;

  @media ${supportedDevices.laptop} {
    p {
      font-size: 18px;
    }
  }
`;
