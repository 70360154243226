import { Dispatch } from "redux";

import { GraphicDispatchTypes, ERROR_SET_DATE, SET_DATE } from "./types";

export const setDate = (initial: string, final: string) => {
  return (dispatch: Dispatch<GraphicDispatchTypes>) => {
    dispatch({
      type: SET_DATE,
      payload: {
        initial: initial,
        final: final,
      },
    });
  };
};

export const erroSetDate = (error: string) => {
  return (dispatch: Dispatch<GraphicDispatchTypes>) => {
    dispatch({
      type: ERROR_SET_DATE,
      payload: error,
    });
  };
};
