import React from "react";

import { useSelector } from "react-redux";

import { Redirect } from "react-router-dom";

import { AppState } from "../../store/rootReducer";

import { RouterLogin } from "./router";

import Logo from "../../assets/logo_nexum.png";
import PoweredByTagna from "../../assets/powered by tagna.png";

import { GlobalStyle, Container, LogoContainer, FormContainer } from "./styles";

export interface LoaderLogin {
  show: boolean;
}

export const Login: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);

  if (auth.isAuth) return <Redirect to="/dashboard" />;
  return (
    <>
      <GlobalStyle />
      <Container data-testid="container">
        <div className="inner-container">
          <div className="outside-logo">
            <img src={Logo} alt="Logo Nexum" />
          </div>
          <div className="card" data-testid="card">
            <LogoContainer>
              <img src={Logo} alt="Logo Nexum" data-testid="logo" />
              <img src={PoweredByTagna} alt="Powered By Tagna" />
            </LogoContainer>
            <FormContainer>
              <RouterLogin />
            </FormContainer>
          </div>
          <div className="outside-logo2">
            <img src={PoweredByTagna} alt="Powered By Tagna" />
          </div>
        </div>
      </Container>
    </>
  );
};
