import { useSelector } from "react-redux";

import { AppState } from "../../store/rootReducer";

import { Container } from "./styles";

export const PreviewAlert = () => {
  const { rule } = useSelector(
    (state: AppState) => state.notificationRule.actionRule
  );

  return (
    <Container criticality={rule.criticality}>
      <hr />

      <div>
        <h3>{rule.title}</h3>
        <p>{rule.message}</p>
      </div>
    </Container>
  );
};
