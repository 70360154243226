import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../store/rootReducer";
import { setDate } from "../../../../store/Graphic/actions";

import { ComponentData } from "../../../../models/ComponentData";

import {
  datePickerDay,
  datePickerHour,
  datePickerMonth,
  datePickerWeek,
} from "../../../../utils/buttonsDatePicker";

import MockCaldeirasHourly from "../../../../mocks/caldeirasHourly.json";
import MockCaldeirasDaily from "../../../../mocks/caldeirasDaily.json";
import MockCaldeirasWeekly from "../../../../mocks/caldeirasWeekly.json";
import MockCaldedirasMonthly from "../../../../mocks/caldeirasMonthly.json";

import { PerformanceDatePicker } from "../../../../components/PerformanceDatePicker";
import { GraphicProcess } from "../../../../components/GraphicProcess";
import { BasePicker } from "../../../../components/BasePicker";

import { Container, BasesAndTitle, Bases, Filter, Title } from "./styles";

export const PerformanceProcess = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  const dispatch = useDispatch();

  const initialDate = useSelector(
    (state: AppState) => state.graphic.date.initial
  );
  const finalDate = useSelector((state: AppState) => state.graphic.date.final);

  const initialState = "24h";
  const [dateClicked, setDateClicked] = useState(initialState);

  const [baseClicked, setBaseClicked] = useState("hora");
  const [datePicker, setDatePicker] = useState(datePickerHour);

  const handleDatePicker = (value: string) => {
    dispatch(setDate(`now-${value}`, "now"));
  };

  const handleDateColor = (value: string) => {
    setDateClicked(value);
  };

  const handleBaseColor = (value: string) => {
    setBaseClicked(value);
  };

  useEffect(() => {
    tabAccessed("PERFORMANCE");
  });

  useEffect(() => {
    dispatch(setDate(`now-${initialState}`, "now"));
  }, [dispatch, initialState]);

  useEffect(() => {
    if (baseClicked === "hora") {
      setDatePicker(datePickerHour);
      setDateClicked("24h");
      dispatch(setDate(`now-${initialState}`, "now"));
    } else if (baseClicked === "dia") {
      setDatePicker(datePickerDay);
      setDateClicked("7d");
      dispatch(setDate(`now-7d`, "now"));
    } else if (baseClicked === "semana") {
      setDatePicker(datePickerWeek);
      setDateClicked("1M");
      dispatch(setDate(`now-1M`, "now"));
    } else if (baseClicked === "mes") {
      setDatePicker(datePickerMonth);
      setDateClicked("3M");
      dispatch(setDate(`now-3M`, "now"));
    }
  }, [baseClicked, dispatch]);

  return (
    <Container>
      <Title>{componentName}</Title>
      <Filter>
        <BasesAndTitle>
          <Bases>
            <p>Bases da regressão: </p>
            <BasePicker
              baseClicked={baseClicked}
              handleBaseColor={handleBaseColor}
            />
          </Bases>
        </BasesAndTitle>

        <PerformanceDatePicker
          data={datePicker}
          handleDatePicker={handleDatePicker}
          handleDateColor={handleDateColor}
          dateClicked={dateClicked}
        />
      </Filter>

      {baseClicked === "hora" && (
        <GraphicProcess
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={MockCaldeirasHourly}
        />
      )}

      {baseClicked === "dia" && (
        <GraphicProcess
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={MockCaldeirasDaily}
          base={baseClicked}
        />
      )}

      {baseClicked === "semana" && (
        <GraphicProcess
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={MockCaldeirasWeekly}
          base={baseClicked}
        />
      )}

      {baseClicked === "mes" && (
        <GraphicProcess
          initialDate={initialDate}
          finalDate={finalDate}
          tagsGraphic={MockCaldedirasMonthly}
          base={baseClicked}
        />
      )}
    </Container>
  );
};
