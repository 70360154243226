import styled from "styled-components";

import { supportedDevices } from "../../styles/supportedDevices";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 15px;

  input {
    font-size: 14px;
  }
`;

export const Label = styled.label`
  color: rgb(58, 58, 58);
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;

  @media ${supportedDevices.laptop} {
    font-size: 18px;
  }
`;

export const ErrorText = styled.p`
  color: #f44336;
  font-size: 12px;
  margin-top: 3px;
  font-weight: 500;
`;
