import React from "react";

import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { AppState } from "../../store/rootReducer";

import { RouterDashboard } from "./router";

import { Header } from "../../components/Header";
import { Tree } from "../../components/Tree";

import { Container, CenterView, Main } from "./styles";

export const Dashboard = () => {
  const { isAuth } = useSelector((state: AppState) => state.auth);

  if (!isAuth) return <Redirect to="/auth" />;

  return (
    <Container>
      <Header />
      <CenterView height={"55px"}>
        <Tree />
        <Main>
          <RouterDashboard />
        </Main>
      </CenterView>
    </Container>
  );
};
