export const formatDateToDateRange = (date: string, time: string) => {
  const dateFormated = new Date(date);
  dateFormated.setHours(Number(time.split(":")[0]));
  dateFormated.setMinutes(Number(time.split(":")[1]));
  dateFormated.setDate(dateFormated.getDate() + 1);
  return dateFormated;
};

export const formatDateTimeAlerts = (date: string) => {
  const newDate = date.replace("UTC", "z");
  return new Date(newDate).toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDateTimeNotifications = (date: string) => {
  const newDate = date.replace("UTC", "z");
  return new Date(newDate).getTime();
};
