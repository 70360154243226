import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Autocomplete } from "@material-ui/lab";
import { Chip, createStyles, makeStyles, TextField } from "@material-ui/core";

import { getTags } from "../../api";

import { AppState } from "../../store/rootReducer";
import { tagsResult } from "../../store/TagAnalyze/actions";

import { TagProps } from "../../models/GraphicData";

import { Container } from "./styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: 20,
    },
    autocomplete: {
      padding: "0 5px",
      border: "1px solid #c4c4c4",
      borderRadius: 6,
      minHeight: "40px",
    },
  })
);

interface TagsSelectedProps {
  tags: TagProps[];
  onChange(event: React.ChangeEvent<{}>, value: TagProps[]): void;
}

export const TagSelector = ({ onChange, tags }: TagsSelectedProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { tagsArray } = useSelector((state: AppState) => state.tagsAnalyze);
  const { identifier } = useSelector(
    (state: AppState) => state.nodeVisualize.node
  );

  useEffect(() => {
    getTags(identifier).then((result) => {
      dispatch(tagsResult(result.data));
    });
  }, [dispatch, identifier]);

  return (
    <Container className={classes.root}>
      <Autocomplete
        className={classes.autocomplete}
        multiple
        limitTags={1}
        noOptionsText="Não encontrado"
        filterSelectedOptions
        options={tagsArray}
        onChange={onChange}
        getOptionLabel={(tag: TagProps) => tag.name}
        getOptionDisabled={() => (tags.length > 9 ? true : false)}
        renderInput={(params) => (
          <TextField
            {...params}
            className="search-input"
            placeholder="Pesquisar Tags"
          />
        )}
        renderTags={(renderTags: TagProps[], getTagProps) =>
          renderTags.map((option: TagProps, index: number) => (
            <Chip
              className="chip"
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </Container>
  );
};
