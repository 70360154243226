import React from "react";

import { GraphicProps } from "../../models/GraphicData";

import { Iframe } from "./styles";

export const GraphicEquipment = ({
  initialDate,
  finalDate,
  base,
  tagsGraphic,
}: GraphicProps) => {
  const variables =
    tagsGraphic !== undefined
      ? tagsGraphic[0].info
          .map((info) => `var-${info.variable}=${info.value}`)
          .join("&")
      : [];

  if (base === "dia") {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/oeEBX3O7k/boiler-daily-general?orgId=2&refresh=5m&theme=light&kiosk&from=${initialDate}&to=${finalDate}&${variables}`}
      />
    );
  }

  if (base === "semana") {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/pYsX9ZKnk/boiler-weekly-general?orgId=2&refresh=5m&kiosk&theme=light&from=${initialDate}&to=${finalDate}&${variables}`}
      />
    );
  }

  if (base === "mes") {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/MyhC9WFnk/boiler-monthly-general?orgId=2&refresh=5m&kiosk&theme=light&from=${initialDate}&to=${finalDate}&${variables}`}
      />
    );
  }

  return (
    <Iframe
      src={`${process.env.REACT_APP_GRAFANA_URL}/d/bU29SKD7k/boiler-hourly-general?orgId=2&refresh=5m&kiosk&theme=light&from=${initialDate}&to=${finalDate}&${variables}`}
    />
  );
};
