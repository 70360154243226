import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
`;

export const BasesAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Bases = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const DivDate = styled.div`
  display: flex;
  gap: 10px;
`;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 10px;
`;
