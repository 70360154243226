import React from "react";

import { Route, Switch, useRouteMatch } from "react-router";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../store/rootReducer";
import { showTab } from "../../store/Visualize/actions";

import { DecisionMakingProcess } from "./components/DecisionMakingProcess";
import { DiceProcess } from "./components/DiceProcess";
import { PerformanceProcess } from "./components/PerformanceProcess";
import { AnalyzeProcess } from "./components/AnalyzeProcess";
import { SimulationProcess } from "./components/SimulationProcess";
import { Rules } from "./components/Rules";
import { Alerts } from "./components/Alerts";

export const RouterNavigation = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const componentName = useSelector(
    (state: AppState) => state.nodeVisualize.node.name
  );

  const handleTabAccessed = (tab: string) => {
    dispatch(showTab(tab));
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/performance`}
        component={(props: string) => (
          <PerformanceProcess
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/analise`}
        component={(props: string) => (
          <AnalyzeProcess
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/predicao-falhas`}
        component={(props: string) => (
          <Rules
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/alertas`}
        component={(props: string) => (
          <Alerts
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/tomada-decisao`}
        component={(props: string) => (
          <DecisionMakingProcess
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/simulacao`}
        component={(props: string) => (
          <SimulationProcess
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/dados`}
        component={(props: string) => (
          <DiceProcess
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />

      <Redirect exact from={`${match.path}`} to={`${match.path}/performance`} />
    </Switch>
  );
};
