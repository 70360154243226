import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 22px;
  background: #e8ebed;
  border-radius: 10px;
  padding: 15px 20px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #7f8898;
    text-align: center;
  }

  .criticality {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 11px;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;

      gap: 5px;

      input {
        width: 20px;
        height: 20px;
      }

      p {
        padding: 0 6px;
        width: 80px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;

        font-size: 14px;
        font-weight: 500;
        color: #7f8898;
      }

      & + label {
        margin-top: 6px;
      }
    }
  }

  .title,
  .message {
    display: flex;
    flex-direction: column;
    gap: 11px;

    textarea {
      width: 140px;
      height: 72px;
      resize: none;
      border: 0;
      border-radius: 6px;
      padding: 10px;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #7f8898;
    }
  }

  .message {
    textarea {
      width: 320px;
    }
  }

  select {
    width: 100%;
    height: 30px;

    border: 0;
    border-radius: 5px;

    padding: 5px;
  }

  select {
    width: 100px;
    color: #7f8898;
  }
`;
