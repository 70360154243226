import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, createStyles, makeStyles, Modal } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { AppState } from "../../store/rootReducer";
import { erroSetDate, setDate } from "../../store/Graphic/actions";

import { formatDateToDateRange } from "../../utils/formatDateToDateRange";

import { useStyles } from "../AlertError";

import {
  DivText,
  Text,
  DivButton,
  ButtonModal,
  DivForm,
  FormGroup,
  InputDate,
  InputTime,
  Label,
  Form,
} from "./styles";

const modalStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      display: "flex",
      flexDirection: "column",
      zIndex: -1,
      alignItems: "center",
      backgroundColor: "#fff",
      overflow: "auto",
    },
    button: {
      width: "35px",
      height: "35px",
      backgroundColor: "rgba(230, 232, 235, 0.5)",
      color: "rgba(0, 0, 0, 0.5)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
    },
    buttonClicked: {
      width: "55px",
      height: "35px",
      background: "linear-gradient(95.44deg, #61C885 0.73%, #1AB1C5 100.24%)",
      color: "#fff",
      border: "1px solid #61C885",
    },
  })
);

interface PerformanceDateRangeProps {
  dateClicked: string;
  typeDateRange: string;
  clickDateRange: (valueButton: string) => void;
}

export const PerformanceDateRange = ({
  clickDateRange,
  dateClicked,
  typeDateRange,
}: PerformanceDateRangeProps) => {
  const classes = modalStyles();
  const classesError = useStyles();

  const dispatch = useDispatch();

  const erroMessage = useSelector(
    (state: AppState) => state.graphic.errorSetDate
  );

  const [open, setOpen] = useState(false);

  const [initial, setInitial] = useState({
    date: "",
    time: "",
  });
  const [final, setFinal] = useState({
    date: "",
    time: "",
  });

  const actualDate = new Date().toISOString().split("T")[0];

  const handleClick = () => {
    setOpen((state) => !state);
  };

  const handleComplet = () => {
    const initialDateFormated = formatDateToDateRange(
      initial.date,
      initial.time
    );
    const finalDateFormated = formatDateToDateRange(final.date, final.time);

    if (
      initial.date === "" ||
      final.date === "" ||
      initial.time === "" ||
      final.time === ""
    )
      dispatch(
        erroSetDate(
          "Não foi possível aplicar um período, por favor tente novamente! O último período selecionado foi mantido"
        )
      );
    else if (initialDateFormated > finalDateFormated)
      dispatch(
        erroSetDate(
          "Data final maior que a data inicial! O último período selecionado foi mantido"
        )
      );
    else {
      dispatch(
        setDate(
          initialDateFormated.getTime().toString(),
          finalDateFormated.getTime().toString()
        )
      );
      clickDateRange(typeDateRange);
    }
  };

  const body = (
    <div className={classes.paper}>
      <DivText>
        <Text>Selecione um período</Text>
      </DivText>

      <Form>
        <DivForm>
          <FormGroup>
            <Label>Data inicial</Label>
            <InputDate
              min="2021-06-01"
              max={actualDate}
              type="date"
              onChange={(e) => setInitial({ ...initial, date: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Label>Hora inicial</Label>
            <InputTime
              type="time"
              onChange={(e) => setInitial({ ...initial, time: e.target.value })}
            />
          </FormGroup>
        </DivForm>

        <DivForm>
          <FormGroup>
            <Label>Data Final</Label>
            <InputDate
              min="2021-06-01"
              max={actualDate}
              type="date"
              onChange={(e) => setFinal({ ...final, date: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Label>Hora Final</Label>
            <InputTime
              type="time"
              onChange={(e) => setFinal({ ...final, time: e.target.value })}
            />
          </FormGroup>
        </DivForm>
      </Form>

      <DivButton>
        <ButtonModal typeButton="cancelar" onClick={() => handleClick()}>
          CANCELAR
        </ButtonModal>
        <ButtonModal
          typeButton="aplicar"
          onClick={() => {
            handleClick();
            handleComplet();
          }}
        >
          APLICAR
        </ButtonModal>
      </DivButton>
    </div>
  );

  return (
    <div>
      <Button
        className={
          dateClicked === typeDateRange ? classes.buttonClicked : classes.button
        }
        onClick={handleClick}
      >
        <DateRange fontSize="small" />
      </Button>

      <Modal open={open} onClose={handleClick}>
        {body}
      </Modal>

      {erroMessage === "" ? (
        ""
      ) : (
        <div className={classesError.root}>
          <Alert variant="filled" severity="error">
            {erroMessage}
          </Alert>
        </div>
      )}
    </div>
  );
};
