import { useSelector } from "react-redux";

import { AppState } from "../../store/rootReducer";
import { Container } from "./styles";

export const PreviewExpression = () => {
  const { rule } = useSelector(
    (state: AppState) => state.notificationRule.actionRule
  );

  const { expression } = rule;

  return (
    <Container>
      <p>{expression}</p>
    </Container>
  );
};
