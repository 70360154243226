import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GraphicProps } from "../../models/GraphicData";
import { setDate } from "../../store/Graphic/actions";

import { Iframe } from "./styles";

export const GraphicSimulationTables = ({
  initialDate,
  finalDate,
  typeGraphic,
  dateClicked
}: GraphicProps) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setDate(`now-${dateClicked}`, "now"));
  }, [dateClicked, dispatch]);
  
  if (typeGraphic === "mes") {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/S0IwFV07z/simulation-monthly-input-dashboard?orgId=2&kiosk&theme=light&from=${initialDate}&to=${finalDate}`}
      />
    );
  } else if (typeGraphic === "dia") {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/GVRTZcH7z/simulation-daily-input-dashboard?orgId=2&kiosk&theme=light&from=${initialDate}&to=${finalDate}`}
      />
    );
  } else if (typeGraphic === "semana") {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/OzycFVA7k/simulation-weekly-input-dashboard?orgId=2&kiosk&theme=light&from=${initialDate}&to=${finalDate}`}
      />
    );
  } else {
    return (
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/y_NaeGN7z/simulation-hourly-input-dashboard?orgId=2&kiosk&theme=light&from=${initialDate}&to=${finalDate}`}
      />
    );
  }
};
