import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
`;

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: 100%;
  height: 100%;

  cursor: pointer;

  border: 1px solid rgb(239, 239, 239);

  background: ${(props) =>
    props.active ? "rgba(0, 158, 226, 0.08);" : "rgb(247, 247, 247)"};

  border-bottom: ${(props) =>
    props.active ? "4px solid rgb(0, 158, 226)" : "none"};

  color: ${(props) => (props.active ? "rgb(0, 158, 226)" : "#111")};
  font-weight: 600;
  font-size: 12px;

  transition: border-bottom 0.2s;
`;
