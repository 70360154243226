import styled from "styled-components";

export const Container = styled.section`
  padding: 20px;
  width: 100%;
  height: calc(100% - 50px);
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 20px;
`;
