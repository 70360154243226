import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { CSVLink } from "react-csv";

import Papa from "papaparse";

import { Button, IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { calculateItems } from "../../../../../../store/Simulation/actions";

import { ComponentSimulationData } from "../../../../../../models/ComponentData";
import { SimulationDataCSV } from "../../../../../../models/SimulationData";

import { TableEntries } from "../../../../../../components/TableEntries";
import { GradientButton } from "../../../../../../components/GradientButton";

import { Container, Filter, Title, Buttons, TitleSimulation } from "./styles";
import { EntriesTable } from "../../../../../../models/TableData";

export const Entries = ({
  componentName,
  handleTab,
}: ComponentSimulationData) => {
  const dispatch = useDispatch();

  const [serie, setSerie] = useState<SimulationDataCSV[]>([]);
  const [haveScenery, setHaveScenery] = useState(false);

  const [scenerys, setScenerys] = useState<EntriesTable[]>([]);

  const [validFile, setValidFile] = useState(true);

  const readFile = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      const csv = reader.result;
      if (
        csv?.toString().split(";")[0] === "Tempo" &&
        csv?.toString().split(";")[1] === "Caldeira1GCO" &&
        csv?.toString().split(";")[2] === "Caldeira1GAF" &&
        csv?.toString().split(";")[3] === "Caldeira1GAC" &&
        csv?.toString().split(";")[4] === "Caldeira1OLEO" &&
        csv?.toString().split(";")[5] === "Caldeira2GCO" &&
        csv?.toString().split(";")[6] === "Caldeira2GAF" &&
        csv?.toString().split(";")[7] === "Caldeira2GAC" &&
        csv?.toString().split(";")[8] === "Caldeira2OLEO" &&
        csv?.toString().split(";")[9] === "Caldeira3GCO" &&
        csv?.toString().split(";")[10] === "Caldeira3GAF" &&
        csv?.toString().split(";")[11] === "Caldeira3GAC" &&
        csv?.toString().split(";")[12] === "Caldeira3OLEO" &&
        csv?.toString().split(";")[13] === "Caldeira4GCO" &&
        csv?.toString().split(";")[14] === "Caldeira4GAF" &&
        csv?.toString().split(";")[15] === "Caldeira4GAC" &&
        csv?.toString().split(";")[16].includes("Caldeira4OLEO")
      ) {
        const data = Papa.parse(csv as string, { header: true });
        setSerie(data.data as SimulationDataCSV[]);
        setHaveScenery(true);
      } else {
        setValidFile(false);
        setOpen(true);
      }
    };
    reader.readAsText(file);
  };

  const handleScenarys = (value: EntriesTable[]) => {
    setScenerys(value);
  };

  const csvData = scenerys.map((item) =>
    item.cells.map((cell) => [cell.value])
  );

  const headers = [
    "Tempo",
    "Caldeira1GCO",
    "Caldeira1GAF",
    "Caldeira1GAC",
    "Caldeira1OLEO",
    "Caldeira2GCO",
    "Caldeira2GAF",
    "Caldeira2GAC",
    "Caldeira2OLEO",
    "Caldeira3GCO",
    "Caldeira3GAF",
    "Caldeira3GAC",
    "Caldeira3OLEO",
    "Caldeira4GCO",
    "Caldeira4GAF",
    "Caldeira4GAC",
    "Caldeira4OLEO",
  ];

  const data = [
    [
      "01/01/2021 10:00:00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ],
  ];

  const handleScenary = (value: boolean) => {
    setHaveScenery(value);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container>
      <div>
        <Filter>
          <Title>{componentName}</Title>
          <div>
            <label htmlFor="file">IMPORTAR DADOS</label>
            <input
              type="file"
              id="file"
              onChange={(e) => {
                if (e.target.files) {
                  readFile(e.target.files[0]);
                }
              }}
            />

            <CSVLink
              headers={headers}
              data={data}
              separator={";"}
              filename="Template.csv"
            >
              BAIXAR TEMPLATE
            </CSVLink>

            <CSVLink
              headers={headers}
              data={csvData}
              separator={";"}
              filename="Cenários exportados.csv"
            >
              EXPORTAR CENÁRIOS
            </CSVLink>
          </div>
        </Filter>

        <TitleSimulation>ENTRADA DE DADOS</TitleSimulation>

        <TableEntries
          serie={serie}
          haveScenerys={handleScenary}
          handleScenerys={handleScenarys}
        />
      </div>

      <Buttons>
        <GradientButton
          onClick={() => {
            dispatch(calculateItems(true, true));
            handleTab("initial");
          }}
        >
          Voltar
        </GradientButton>
        <GradientButton
          disabled={!haveScenery}
          onClick={() => handleTab("simulation")}
        >
          Simular
        </GradientButton>
      </Buttons>

      {!validFile && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          action={action}
          key={"top,center"}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error">
            O arquivo não é válido!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};
