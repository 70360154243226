import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { calculateItemsDecisionMaking } from "../../../../store/DecisionMaking/actions";

import { ComponentData } from "../../../../models/ComponentData";

import { TemplateConfiguration } from "./components/TemplateConfiguration";

import { Container } from "./styles";
import { LimitOptimization } from "./components/LimitOptimization";
import { LimitOnDemandOptimization } from "./components/LimitOnDemandOptimization";
import { RealTimeLimitOptimization } from "./components/RealTimeLimitOptimization";

export const DecisionMakingProcess = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  const dispatch = useDispatch();

  const [tabSimulation, setTabSimulation] = useState("");

  const handleTabSimulation = (tab: string) => {
    setTabSimulation(tab);
  };

  useEffect(() => {
    tabAccessed("TOMADA DE DECISÃO");
    dispatch(calculateItemsDecisionMaking(true, true));
  }, [dispatch, tabAccessed]);

  if (tabSimulation === "otimização") {
    return (
      <LimitOptimization
        componentName={componentName}
        handleTab={handleTabSimulation}
      />
    );
  }

  if (tabSimulation === "otimização-demanda") {
    return (
      <LimitOnDemandOptimization
        componentName={componentName}
        handleTab={handleTabSimulation}
      />
    );
  }

  if (tabSimulation === "otimização-tempo-real") {
    return (
      <RealTimeLimitOptimization
        componentName={componentName}
        handleTab={handleTabSimulation}
      />
    );
  }

  return (
    <Container>
      <TemplateConfiguration
        componentName={componentName}
        handleTab={handleTabSimulation}
      />
    </Container>
  );
};
