import React, { useEffect } from "react";

import { ComponentData } from "../../../../models/ComponentData";

import { Container, Title } from "./styles";

export const AboutEquipment = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  useEffect(() => {
    tabAccessed("SOBRE");
  });

  return (
    <Container>
      <Title>{componentName}</Title>
      Sobre (Em desenvolvimento)
    </Container>
  );
};
