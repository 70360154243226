import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import {
  ArrowDropDown,
  ArrowRight,
  FiberManualRecord,
} from "@material-ui/icons";

import { showVisualize } from "../../../../store/Visualize/actions";
import { AppState } from "../../../../store/rootReducer";

import {
  RenderTreeNodeProps,
  RenderTreeProps,
  TreeData,
} from "../../../../models/TreeData";

import { TreeItem, Item, Name } from "./styles";

export const RenderTree = ({ data }: RenderTreeProps) => {
  return (
    <TreeItem>
      {Array.isArray(data) && data.map((node: TreeData) => 
        <RenderTreeNode key={node.name} node={node} />

      )}
    </TreeItem>
  );
};

const RenderTreeNode = (props: RenderTreeNodeProps) => {
  const { node } = props;

  const [childVisible, setChildVisiblity] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const { name } = useSelector((state: AppState) => state.nodeVisualize.node);

  const setColor = `${childVisible ? "#4CA6A8" : "#78818f"}`;

  const setCursor =
    node.type === "Equipment" || node.type === "ProcessProfile"
      ? "true"
      : "false";

  const runDispatch = () => {
    return dispatch(showVisualize(node));
  };

  return (
    <>
      <Item>
        <div onClick={() => setChildVisiblity((state) => !state)}>
          {node.children && (
            <div>
              {childVisible ? (
                <ArrowDropDown style={{ color: setColor, cursor: "pointer" }} />
              ) : (
                <ArrowRight style={{ color: setColor, cursor: "pointer" }} />
              )}
            </div>
          )}
        </div>

        {!node.children && (
          <div>
            <FiberManualRecord
              style={{
                color: "#78818f",
                fontSize: 10,
              }}
            />
          </div>
        )}

        <Name
          onClick={() => {
            if (node.type === "Equipment") {
              history.push(`${match.path}/equipamento`);
              runDispatch();
            } else if (node.type === "ProcessProfile") {
              history.push(`${match.path}/processo`);
              runDispatch();
            }
          }}
          weight={
            name === node.name && location.pathname !== "/dashboard/initial"
              ? "bold"
              : "normal"
          }
          cursor={setCursor}
          color={childVisible ? "#4CA6A8" : "#78818f"}
        >
          {node.name}
        </Name>
      </Item>

      {node.children && childVisible && (
        <RenderTree
          data={node.children.sort((a, b) => a.name.localeCompare(b.name))}
        />
      )}
    </>
  );
};
