import React from "react";

import {
  GraphicTagsComparationProps,
  TagProps,
} from "../../models/GraphicData";

import { Iframe } from "./styles";

export const GraphicTagsComparison = ({
  initialDate,
  finalDate,
  tags1,
  tags2,
}: GraphicTagsComparationProps) => {
  const identifier1 = tags1.map(
    (tag: TagProps, index) =>
      tag.identifier !== null &&
      `var-tag${index + 1}=${tag.identifier}&var-tagName${index + 1}=${
        tag.name
      }`
  );

  const identifier2 = tags2.map(
    (tag: TagProps, index) =>
      tag.identifier !== null &&
      `var-tag${index + 11}=${tag.identifier}&var-tagName${index + 11}=${
        tag.name
      }`
  );

  const finalIdentifier1 = identifier1.join("&");
  const finalIdentifier2 = identifier2.join("&");

  return (
    <>
      <Iframe
        src={`${process.env.REACT_APP_GRAFANA_URL}/d/02aYbAGnk/tags-comparation?orgId=2&from=${initialDate}&to=${finalDate}&kiosk&theme=light&${finalIdentifier1}&${finalIdentifier2}`}
      />
    </>
  );
};
