import { EntriesTable, TableData } from "../../models/TableData";
import { DateRange } from "../Graphic/types";

export interface SimulationState {
  calculateItems: CalculateData;
  entries: TableData[];
  pciCoeficient: TableData[];
  result: string;
  date: DateRange;
  errorSetDateSimulation: string;
}

export interface CalculateData {
  pci: boolean;
  coef: boolean;
}

export const CALCULATE_ITEMS = "CALCULATE_ITEMS";
export const PCI_COEFICIENT_VALUE = "PCI_COEFICIENT_VALUE";
export const ENTRIES_VALUE = "ENTRIES_VALUE";
export const SET_DATE_SIMULATION = "SET_DATE_SIMULATION";
export const ERROR_SET_DATE_SIMULATION = "ERROR_SET_DATE_SIMULATION";
export const RESULT_VALUE = "RESULT_VALUE";

export interface CalculateItems {
  type: typeof CALCULATE_ITEMS;
  payload: CalculateData;
}

export interface PciCoeficientValue {
  type: typeof PCI_COEFICIENT_VALUE;
  payload: TableData[];
}

export interface EntriesValue {
  type: typeof ENTRIES_VALUE;
  payload: EntriesTable[];
}

export interface SetDateSimulation {
  type: typeof SET_DATE_SIMULATION;
  payload: DateRange;
}

export interface SetErrorSetDateSimulation {
  type: typeof ERROR_SET_DATE_SIMULATION;
  payload: string;
}

export interface ResultValue {
  type: typeof RESULT_VALUE;
  payload: string;
}

export type SimulationDispatchTypes =
  | CalculateItems
  | EntriesValue
  | SetDateSimulation
  | PciCoeficientValue
  | SetErrorSetDateSimulation
  | ResultValue;
