import {
  FlowLimits,
  FuelDemandReal,
  FuelLimits,
} from "../../models/OptimizationData";
import { TableDataDecisionMaking } from "../../models/TableData";
import { DateRange } from "../Graphic/types";

export interface DecisionMakingState {
  calculateItems: CalculateData;
  pciCoeficient: TableDataDecisionMaking[];
  fuelValue: FuelLimits[];
  flowValue: FlowLimits[];
  date: DateRange;
  fuelDemand: FuelDemandReal[];
  fuelReal: FuelDemandReal[];
}

export interface CalculateData {
  pci: boolean;
  coef: boolean;
}

export const CALCULATE_ITEMS = "CALCULATE_ITEMS";
export const PCI_COEFICIENT_VALUE_DECISION_MAKING =
  "PCI_COEFICIENT_VALUE_DECISION_MAKING";
export const SET_DATE_DECISION_MAKING = "SET_DATE_DECISION_MAKING";
export const FUEL_VALUE_DECISION_MAKING = "FUEL_VALUE_DECISION_MAKING";
export const FLOW_VALUE_DECISION_MAKING = "FLOW_VALUE_DECISION_MAKING";
export const FUEL_VALUE_DEMAND_DECISION_MAKING =
  "FUEL_VALUE_DEMAND_DECISION_MAKING";
export const FUEL_VALUE_REAL_DECISION_MAKING =
  "FUEL_VALUE_REAL_DECISION_MAKING";

export interface CalculateItems {
  type: typeof CALCULATE_ITEMS;
  payload: CalculateData;
}

export interface PciCoeficientValue {
  type: typeof PCI_COEFICIENT_VALUE_DECISION_MAKING;
  payload: TableDataDecisionMaking[];
}

export interface SetDateDecisionMaking {
  type: typeof SET_DATE_DECISION_MAKING;
  payload: DateRange;
}

export interface FuelValueDecisionMaking {
  type: typeof FUEL_VALUE_DECISION_MAKING;
  payload: FuelLimits[];
}

export interface FlowValueDecisionMaking {
  type: typeof FLOW_VALUE_DECISION_MAKING;
  payload: FlowLimits[];
}

export interface FuelValueRealDecisionMaking {
  type: typeof FUEL_VALUE_REAL_DECISION_MAKING;
  payload: FuelDemandReal[];
}

export interface FuelValueDemandDecisionMaking {
  type: typeof FUEL_VALUE_DEMAND_DECISION_MAKING;
  payload: FuelDemandReal[];
}

export type DecisionMakingDispatchTypes =
  | CalculateItems
  | SetDateDecisionMaking
  | PciCoeficientValue
  | FuelValueDecisionMaking
  | FlowValueDecisionMaking
  | FuelValueRealDecisionMaking
  | FuelValueDemandDecisionMaking;
