import styled from "styled-components";

interface CriticidadeProps {
  color: string;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 120px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9ca8ba;
    border-radius: 6px;
    margin-left: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9ca8ba;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px 0px #f0f0f0;
  }

  table {
    width: 100%;
    background: #e8ebed;
    padding: 15px 20px;
    border-radius: 16px;
    padding: 15px 20px 10px 20px;

    thead {
      th {
        padding: 0 10px 5px 10px;
        color: #000;
        text-align: center;
        font-size: 12px;
      }
    }

    tbody {
      background-color: #ffffff;

      tr {
        border: 0;
        text-align: center;
        font-size: 12px;

        & + tr {
          padding-top: 5px;
        }

        td:nth-child(1) {
          width: 5%;
        }

        .expression {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            p {
              cursor: pointer;
              padding: 5px;
              border: 0;
              border-radius: 6px;
              font-size: 16px;
              color: #fff;
              background: #7f8898;
            }
          }
        }

        .message {
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;

          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export const Scroll = styled.div``;

export const Criticidade = styled.div<CriticidadeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    font-size: 12px;
    font-weight: 400;
    color: #000;
  }

  div {
    background: ${({ color }) => color};
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`;

export const SelectStatus = styled.select``;

export const OptionStatus = styled.option``;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  background: #e8ebed;

  padding: 0 0 0 2px;

  button {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 0 10px;
    cursor: pointer;
  }
`;
