import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useHistory, useRouteMatch } from "react-router-dom";

import { AppState } from "../../store/rootReducer";

import { navigationProcess } from "../../utils/navigations";

import { RouterNavigation } from "./router";

import { Navigation } from "../../components/Navigation";

import { Container } from "./styles";

export const ProcessDashboard = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const tabSelected = useSelector((state: AppState) => state.nodeVisualize.tab);

  useEffect(() => {
    history.push(`${match.path}/${navigationProcess[0].route}`);
  }, [history, match.path]);

  const handleLinkSelect = (linkRoute: string) => {
    history.push(`${match.path}/${linkRoute}`);
  };

  return (
    <Container>
      <Navigation
        linkSelected={tabSelected}
        setLinkSelected={handleLinkSelect}
      />

      <RouterNavigation />
    </Container>
  );
};
