import { Dispatch } from "redux";
import { EntriesTable, TableData} from "../../models/TableData";
import {
  SimulationDispatchTypes,
  ENTRIES_VALUE,
  PCI_COEFICIENT_VALUE,
  SET_DATE_SIMULATION,
  ERROR_SET_DATE_SIMULATION,
  RESULT_VALUE,
  CALCULATE_ITEMS,
} from "./types";

export const calculateItems = (hasPci: boolean, hasCoef: boolean) => {
  return (dispatch: Dispatch<SimulationDispatchTypes>) => {
    dispatch({
      type: CALCULATE_ITEMS,
      payload: {
        pci: hasPci,
        coef: hasCoef,
      },
    });
  };
};

export const pciCoeficientValue = (pciCoeficient: TableData[]) => {
  return (dispatch: Dispatch<SimulationDispatchTypes>) => {
    dispatch({
      type: PCI_COEFICIENT_VALUE,
      payload: pciCoeficient,
    });
  };
};

export const entriesValue = (entries: EntriesTable[]) => {
  return (dispatch: Dispatch<SimulationDispatchTypes>) => {
    dispatch({
      type: ENTRIES_VALUE,
      payload: entries,
    });
  };
};

export const dateSimulation = (initial: string, final: string) => {
  return (dispatch: Dispatch<SimulationDispatchTypes>) => {
    dispatch({
      type: SET_DATE_SIMULATION,
      payload: {
        initial,
        final,
      },
    });
  };
};

export const errorDateSimulation = (error: string) => {
  return (dispatch: Dispatch<SimulationDispatchTypes>) => {
    dispatch({
      type: ERROR_SET_DATE_SIMULATION,
      payload: error,
    });
  };
};

export const resultSimulation = (request: string) => {
  return (dispatch: Dispatch<SimulationDispatchTypes>) => {
    dispatch({
      type: RESULT_VALUE,
      payload: request,
    });
  };
};
