import { combineReducers } from "redux";

import { AuthReducer as auth } from "./Auth/reducer";
import { NodeVisualizeReducer as nodeVisualize } from "./Visualize/reducer";
import { GraphicReducer as graphic } from "./Graphic/reducer";
import { TagAnalyzeReducer as tagsAnalyze } from "./TagAnalyze/reducer";
import { SimulationReducer as simulation } from "./Simulation/reducer";
import { TreeReducer as tree } from "./Tree/reducer";
import { DecisionMakingReducer as decisionMaking } from "./DecisionMaking/reducer";
import { NotificationRuleReducer as notificationRule } from "./Alert/reducer";

import { AuthState } from "./Auth/types";
import { NodeVisualizeState } from "./Visualize/types";
import { GraphicState } from "./Graphic/types";
import { TagsAnalyzeState } from "./TagAnalyze/types";
import { SimulationState } from "./Simulation/types";
import { TreeState } from "./Tree/types";
import { DecisionMakingState } from "./DecisionMaking/types";
import { NotificationRuleState } from "./Alert/types";

export interface AppState {
  auth: AuthState;
  nodeVisualize: NodeVisualizeState;
  graphic: GraphicState;
  tagsAnalyze: TagsAnalyzeState;
  simulation: SimulationState;
  tree: TreeState;
  decisionMaking: DecisionMakingState;
  notificationRule: NotificationRuleState;
}

export const RootReducer = combineReducers({
  auth,
  nodeVisualize,
  graphic,
  tagsAnalyze,
  simulation,
  tree,
  decisionMaking,
  notificationRule,
});
