export interface GraphicState {
  date: DateRange;
  errorSetDate: string;
}

export interface DateRange {
  initial: string;
  final: string;
}

export const SET_DATE = "SET_DATE";
export const ERROR_SET_DATE = "ERROR_SET_DATE";

export interface SetDate {
  type: typeof SET_DATE;
  payload: DateRange;
}

export interface errorSetDate {
  type: typeof ERROR_SET_DATE;
  payload: string;
}

export type GraphicDispatchTypes = errorSetDate | SetDate;
