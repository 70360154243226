import { Dispatch } from "redux";
import { TreeData } from "../../models/TreeData";
import { TREE, TreeDispatchTypes } from "./types";

export const treeResult = (tree: TreeData[]) => {
  return (dispatch: Dispatch<TreeDispatchTypes>) => {
    dispatch({
      type: TREE,
      payload: tree,
    });
  };
};
