import { CircularProgress } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { TableDataDecisionMaking } from "../../models/TableData";
import { pciCoeficientValueDecisionMaking } from "../../store/DecisionMaking/actions";
import { AppState } from "../../store/rootReducer";

import { Container, Table, Tables, Title } from "./styles";
import { Checkbox } from "../TablesDecisionMaking1/styles";
import { flowValueDecisionMaking } from "../../store/DecisionMaking/actions";
import { FlowLimits } from "../../models/OptimizationData";

interface TablesDecisionMaking2Props {
  loader: boolean;
}

export const TablesDecisionMaking2 = ({
  loader,
}: TablesDecisionMaking2Props) => {
  const dispatch = useDispatch();

  const { flowValue, pciCoeficient } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  const [pciCoeficientUpdated, setPciCoeficientUpdated] =
    useState<TableDataDecisionMaking[]>(pciCoeficient);

  const handleChange = (value: number, profile: string) => {
    const updatePciCoeficient: TableDataDecisionMaking[] = pciCoeficient.map(
      ({ title, table }) => ({
        title,
        table: table.map(({ name, cells }) => ({
          name,
          cells: cells.map((cell) =>
            profile === cell.name
              ? {
                ...cell,
                value: value ? value : 0,
              }
              : cell
          ),
        })),
      })
    );
    console.log("updatePciCoeficient", updatePciCoeficient);
    setPciCoeficientUpdated(updatePciCoeficient);
  };

  function handleChangeCheckbox(value: boolean, index: number) {
    const newFlowValue = flowValue.map((item1) => {
      return {
        ...item1,
        table: item1.table.map((item2, item2Index) => {
          if (index === item2Index)
            return {
              ...item2,
              considered: value,
            };
          return item2;
        }),
      };
    });
    console.log("newFlowValue", newFlowValue);
    dispatch(flowValueDecisionMaking(newFlowValue));
  }

  useEffect(() => {
    dispatch(pciCoeficientValueDecisionMaking(pciCoeficientUpdated));
  }, [dispatch, pciCoeficientUpdated]);

  useEffect(() => {
    setPciCoeficientUpdated(pciCoeficient);
  }, [pciCoeficient]);

  const createRowFuelPCI = (cells: any) => {
    return (
      <>
        <th key={cells[2].name}>
          {cells[2].name === "Rsquared" ? (
            <p>
              {cells[2].value === null
                ? "-"
                : Number(cells[2].value).toFixed(0)}
            </p>
          ) : (
            <input
              type="text"
              name={cells[2].name}
              defaultValue={
                cells[2].value === null
                  ? "-"
                  : Number(cells[2].value).toFixed(0)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          )}
        </th>
        <th key={cells[1].name}>
          {cells[1].name === "Rsquared" ? (
            <p>
              {cells[1].value === null
                ? "-"
                : Number(cells[1].value).toFixed(0)}
            </p>
          ) : (
            <input
              type="text"
              name={cells[1].name}
              defaultValue={
                cells[1].value === null
                  ? "-"
                  : Number(cells[1].value).toFixed(0)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          )}
        </th>
        <th key={cells[0].name}>
          {cells[0].name === "Rsquared" ? (
            <p>
              {cells[0].value === null
                ? "-"
                : Number(cells[0].value).toFixed(0)}
            </p>
          ) : (
            <input
              type="text"
              name={cells[0].name}
              defaultValue={
                cells[0].value === null
                  ? "-"
                  : Number(cells[0].value).toFixed(0)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          )}
        </th>
        <th key={cells[3].name}>
          {cells[3].name === "Rsquared" ? (
            <p>
              {cells[3].value === null
                ? "-"
                : Number(cells[3].value).toFixed(0)}
            </p>
          ) : (
            <input
              type="text"
              name={cells[3].name}
              defaultValue={
                cells[3].value === null
                  ? "-"
                  : Number(cells[3].value).toFixed(0)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          )}
        </th>
      </>
    );
  };

  const createRowFuelCoef = (cells: any, fuel: any, index: number) => {
    const isDisabled = !pciCoeficientUpdated[1].table
      .map((item) =>
        item.cells.some((cell) => !cell.name.includes("R2") && cell.value !== 0)
      )[index];

    return (
      <>
        <th key={`nome-caldeira-${index}`}>
          {fuel.name}
        </th>
        <th key={`checkbox-${index}`}>
          <Checkbox>
            <input
              type="checkbox"
              checked={isDisabled ? false : flowValue[0].table[index].considered}
              disabled={isDisabled}
              onChange={(e) => {
                if (!isDisabled) {
                  handleChangeCheckbox(e.currentTarget.checked, index);
                }
              }}
            />
          </Checkbox>
        </th>
        {cells[2].name !== "Rsquared" && (
          <th key={cells[2].name}>
            <input
              type="text"
              name={cells[2].name}
              defaultValue={
                cells[2].value === null
                  ? "-"
                  : Number(cells[2].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          </th>
        )}
        {cells[1].name !== "Rsquared" && (
          <th key={cells[1].name}>
            <input
              type="text"
              name={cells[1].name}
              defaultValue={
                cells[1].value === null
                  ? "-"
                  : Number(cells[1].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          </th>
        )}
        {cells[0].name !== "Rsquared" && (
          <th key={cells[0].name}>
            <input
              type="text"
              name={cells[0].name}
              defaultValue={
                cells[0].value === null
                  ? "-"
                  : Number(cells[0].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          </th>
        )}
        {cells[3].name !== "Rsquared" && (
          <th key={cells[3].name}>
            <input
              type="text"
              name={cells[3].name}
              defaultValue={
                cells[3].value === null
                  ? "-"
                  : Number(cells[3].value).toFixed(3)
              }
              onChange={(e) =>
                handleChange(
                  Number(e.currentTarget.value),
                  e.currentTarget.name
                )
              }
            />
          </th>
        )}
      </>
    );
  };

  if (loader || pciCoeficient.length === 0) {
    return (
      <Container>
        <CircularProgress
          style={{ color: "#4CA6A8", margin: "0 auto", marginTop: "50px" }}
          size={50}
        />
      </Container>
    );
  }

  return (
    <Tables>
      <Container key={pciCoeficient[0].title}>
        <Title>
          {pciCoeficient[0].title === "PCI" ? "PCI" : "COEFICIENTE"}
        </Title>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>GCO</th>
              <th>GAF</th>
              <th>GAC</th>
              <th>Oleo/GN</th>
            </tr>
          </thead>
          <tbody>
            {pciCoeficient[0].table.map((fuel, index) => (
              <tr key={index}>
                <th>{fuel.name}</th>
                {createRowFuelPCI(fuel.cells)}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Container key={pciCoeficient[1].title}>
        <Title>
          {pciCoeficient[1].title === "PCI" ? "PCI" : "COEFICIENTE"}
        </Title>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Caldeira operando?</th>
              <th>GCO</th>
              <th>GAF</th>
              <th>GAC</th>
              <th>Oleo/GN</th>
            </tr>
          </thead>
          <tbody>
            {pciCoeficient[1].table.map((fuel, index) => (
              <tr key={index}>
                {createRowFuelCoef(fuel.cells, fuel, index)}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Tables>
  );
};
