import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background: #e8ebed;
  margin-bottom: 10px;
  border-radius: 6px;

  p {
    color: #000;
    padding: 5px 10px;
    font-size: 14px;
  }
`;
