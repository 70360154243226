import styled from "styled-components";

interface ButtonProps {
  typeButton: string;
}

export const DivText = styled.div`
  width: 100%;
  height: 100px;
  background: rgb(55, 55, 56);
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const Text = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #fff;
`;

export const DivForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Form = styled.div`
  display: flex;
  gap: 50px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  margin-top: 30px;
`;

export const InputDate = styled.input`
  border-radius: 6px;
  border: 1px solid #b1b2b5;
  height: 36px;
`;

export const InputTime = styled.input`
  width: 90px;
  height: 39px;
  border-radius: 6px;
  border: 1px solid #b1b2b5;
`;

export const DivButton = styled.div`
  margin-top: 30px;
  padding-bottom: 20px;
  display: flex;
  gap: 70px;
`;

export const ButtonModal = styled.button<ButtonProps>`
  width: 100px;
  height: 36px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
  background: ${({ typeButton }) =>
    typeButton === "aplicar"
      ? "linear-gradient(to right, rgb(25, 176, 160), rgb(0, 158, 226))"
      : "transparent"};
  color: ${({ typeButton }) =>
    typeButton === "aplicar" ? "white" : "#f44336"};
  border: ${({ typeButton }) =>
    typeButton === "aplicar" ? "none" : "1px solid #f44336"};
  cursor: pointer;
`;

export const Filter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
`;
