import React from "react";

import { Redirect } from "react-router-dom";
import { Route, Switch, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../store/rootReducer";
import { showTab } from "../../store/Visualize/actions";

import { AboutEquipment } from "./components/AboutEquipment";
import { DiceEquipment } from "./components/DiceEquipment";
import { FailurePredictionEquipment } from "./components/FailurePredictionEquipment";
import { PerformanceEquipment } from "./components/PerfomanceEquipment";

export const RouterNavigation = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const componentName = useSelector(
    (state: AppState) => state.nodeVisualize.node.name
  );

  const handleTabAccessed = (tab: string) => {
    dispatch(showTab(tab));
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/performance`}
        component={(props: string) => (
          <PerformanceEquipment
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/dados`}
        component={(props: string) => (
          <DiceEquipment
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/predicao-falhas`}
        component={(props: string) => (
          <FailurePredictionEquipment
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Route
        path={`${match.path}/sobre`}
        component={(props: string) => (
          <AboutEquipment
            {...props}
            componentName={componentName}
            tabAccessed={handleTabAccessed}
          />
        )}
      />
      <Redirect exact from={`${match.path}`} to={`${match.path}/performance`} />
    </Switch>
  );
};
