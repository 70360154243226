import React from "react";

import LogoImg from "../../assets/nexumtwin.png";

import {
  onDemandOptimizationModal,
  onDemandOptimizationModal2,
} from "../../utils/decisionMaking";

import {
  Container,
  ButtonClose,
  Title,
  TitleTable,
  Table,
  Table2,
  Grid,
  Header,
  Logo,
  ContainerFailure,
  TitleFailure,
} from "./styles";

interface OnDemandOptimizationModalProps {
  handleClose: () => void;
  data: any[];
}

export const OnDemandOptimizationModal = ({
  handleClose,
  data,
}: OnDemandOptimizationModalProps) => {
  const variables = (fuel: string, fuel2: string) => {
    return (
      <>
        {data.map((item) =>
          item.variables.map(
            (item: any) =>
              (item.name.includes(fuel) || item.name.includes(fuel2)) && (
                <td key={item.name + item.value}>{item.value}</td>
              )
          )
        )}
      </>
    );
  };

  const totalsTable = (name: string) => {
    return (
      <>
        {data.map((item) =>
          item.f_total.map(
            (item: any) =>
              item.name === name && (
                <td key={item.name + item.value}>{item.value}</td>
              )
          )
        )}
      </>
    );
  };

  const boilers = (name: string) => {
    return (
      <>
        {data.map((item) =>
          item.f_boilers.map(
            (item: any) =>
              item.name === name && (
                <React.Fragment key={item.name + item.values}>
                  <td>{item.values.C1}</td>
                  <td>{item.values.C2}</td>
                  <td>{item.values.C3}</td>
                  <td>{item.values.C4}</td>
                </React.Fragment>
              )
          )
        )}
      </>
    );
  };

  if (data[0].info.solution_message === "Infeasible solution") {
    return (
      <ContainerFailure>
        <Header>
          <Logo>
            <img src={LogoImg} alt="" />
          </Logo>
          <Title>OPERAÇÃO POR DEMANDA</Title>
          <ButtonClose onClick={() => handleClose()}>X</ButtonClose>
        </Header>

        <TitleFailure>
          Com esses parâmetros de entrada, não foi possível determinar uma
          solução factível. Por favor tente novamente com novos parâmetros.
        </TitleFailure>
      </ContainerFailure>
    );
  }

  return (
    <Container>
      <Header>
        <Logo>
          <img src={LogoImg} alt="" />
        </Logo>
        <Title>OPERAÇÃO POR DEMANDA</Title>
        <ButtonClose onClick={() => handleClose()}>X</ButtonClose>
      </Header>

      <Grid>
        <Table>
          <TitleTable>DISTRIBUIÇÃO DAS VAZÕES DE COMBUSTÍVEIS</TitleTable>
          <table>
            <thead>
              <tr>
                <th>Combustível</th>
                <th>Caldeira 1</th>
                <th>Caldeira 2</th>
                <th>Caldeira 3</th>
                <th>Caldeira 4</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {onDemandOptimizationModal.map((item) => (
                <tr key={item.fuel + item.total}>
                  <td>{item.fuel === "Oleo/GN" ? "Óleo/GN" : item.fuel}</td>
                  {variables(
                    item.variable1 === "Oleo" ? "OL" : item.variable1,
                    item.variable2
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      </Grid>

      <Table2>
        <TitleTable>
          ENERGIA | CONSUMO ESPECÍFICO | PRODUÇÃO DE VAPOR
        </TitleTable>
        <table>
          <thead>
            <tr>
              <th>Combustível</th>
              <th>Caldeira 1</th>
              <th>Caldeira 2</th>
              <th>Caldeira 3</th>
              <th>Caldeira 4</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {onDemandOptimizationModal2.map((item) => (
              <tr key={item.row + item.name + Math.random()}>
                <td>{item.row}</td>
                {boilers(item.name)}
                {totalsTable(item.name)}
              </tr>
            ))}
          </tbody>
        </table>
      </Table2>
    </Container>
  );
};
