import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { actionRule } from "../../store/Alert/actions";
import { AppState } from "../../store/rootReducer";

import copyImg from "../../assets/copy.svg";
import trashImg from "../../assets/trash.svg";

import { Container, Input } from "./styles";
import { Chip } from "@material-ui/core";

export const ExpressionAndDuration = () => {
  const dispatch = useDispatch();

  const { rule, typeAction, id } = useSelector(
    (state: AppState) => state.notificationRule.actionRule
  );

  const { duration } = rule;
  const [seg, setSeg] = useState(duration.split(":")[0]);
  const [min, setMin] = useState(duration.split(":")[1]);
  const [hour, setHour] = useState(duration.split(":")[2]);

  const [segError, setSegError] = useState(false);
  const [minError, setMinError] = useState(false);
  const [hourError, setHourError] = useState(false);

  const handleTime = (value: number, type: string) => {
    if (type === "seg") {
      if (isNaN(value)) {
        setSegError(true);
        return;
      }

      if (value <= 59) {
        if (value < 10) {
          setSeg(`0${value}`);
          setSegError(false);
        } else {
          setSeg(`${value}`);
          setSegError(false);
        }
      } else {
        setSeg(`${value}`);
        setSegError(true);
      }
    }

    if (type === "min") {
      if (isNaN(value)) {
        setMinError(true);
        return;
      }

      if (value <= 59) {
        if (value < 10) {
          setMin(`0${value}`);
          setMinError(false);
        } else {
          setMin(`${value}`);
          setMinError(false);
        }
      } else {
        setMin(`${value}`);
        setMinError(true);
      }
    }

    if (type === "hour") {
      if (isNaN(value)) {
        setHour(`00`);
        setHourError(true);
        return;
      } else {
        setHour(`${value}`);
        setHourError(false);
      }
    }
  };

  useEffect(() => {
    dispatch(
      actionRule({
        id: id,
        typeAction: typeAction,
        rule: {
          criticality: rule.criticality,
          title: rule.title,
          message: rule.message,
          expression: rule.expression,
          duration: `${hour}:${min}:${seg}`,
          variables: rule.variables,
          functions: rule.functions,
          frequency: rule.frequency,
        },
      })
    );
  }, [
    rule.criticality,
    rule.expression,
    rule.message,
    rule.title,
    dispatch,
    hour,
    min,
    seg,
    typeAction,
    rule.variables,
    rule.functions,
    rule.frequency,
    id,
  ]);

  const addParentheses = (value: string) => {
    dispatch(
      actionRule({
        id: id,
        typeAction: typeAction,
        rule: {
          criticality: rule.criticality,
          title: rule.title,
          message: rule.message,
          expression:
            rule.expression === "" ? value : `${rule.expression} ${value}`,
          duration: rule.duration,
          variables: rule.variables,
          functions: rule.functions,
          frequency: rule.frequency,
        },
      })
    );
  };

  const removeAllExpression = () => {
    dispatch(
      actionRule({
        id: id,
        typeAction: typeAction,
        rule: {
          criticality: rule.criticality,
          title: rule.title,
          message: rule.message,
          duration: rule.duration,
          expression: "",
          variables: "",
          functions: "",
          frequency: rule.frequency,
        },
      })
    );
  };

  const scrollToRight = () => {
    const element = document.getElementById("dragAndDrop");

    if (element) {
      element.scrollTo({
        behavior: "smooth",
        left: element.scrollWidth,
      });
    }
  };

  interface Array {
    id: string;
    name: string;
  }

  const [expressionArray, setExpressionArray] = useState<Array[]>([]);

  useEffect(() => {
    const array = rule.expression.split(" ");

    const verify = array.some((item) => item === "");

    if (!verify) {
      const finalArray = array.map((item) => {
        return {
          id: Math.random().toString(),
          name: item,
        };
      });
      setExpressionArray(finalArray);
    } else {
      setExpressionArray([]);
    }

    setTimeout(() => {
      scrollToRight();
    }, 100);
  }, [rule.expression]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(expressionArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setExpressionArray(items);
    dispatch(
      actionRule({
        id: id,
        typeAction: typeAction,
        rule: {
          criticality: rule.criticality,
          title: rule.title,
          message: rule.message,
          expression: items.map((item) => item.name).join(" "),
          duration: rule.duration,
          variables: rule.variables,
          functions: rule.functions,
          frequency: rule.frequency,
        },
      })
    );
  };

  const handleDeleteItem = async (id: string, name: string) => {
    const newArray = expressionArray.filter((item) => item.id !== id);
    setExpressionArray(newArray);

    updateExpression(name, newArray);
  };

  const updateExpression = (name: string, newArray: Array[]) => {
    const newExpressionString = newArray.map((item) => item.name).join(" ");

    const verify = newArray.some((item) => item.name === name);

    if (!verify) {
      const verifyVariables = rule.variables
        .split(",")
        .some((item) => item === name);

      const verifyFunction = rule.functions
        .split(";")
        .some((item) => item === name);

      if (verifyFunction) {
        dispatch(
          actionRule({
            id: id,
            typeAction: typeAction,
            rule: {
              criticality: rule.criticality,
              title: rule.title,
              message: rule.message,
              expression: newExpressionString,
              duration: rule.duration,
              variables: rule.variables,
              functions: rule.functions
                .replace(`${name};`, "")
                .replace(`;${name}`, "")
                .replace(`${name}`, ""),
              frequency: rule.frequency,
            },
          })
        );
      } else if (verifyVariables) {
        dispatch(
          actionRule({
            id: id,
            typeAction: typeAction,
            rule: {
              criticality: rule.criticality,
              title: rule.title,
              message: rule.message,
              expression: newExpressionString,
              duration: rule.duration,
              variables: rule.variables
                .replace(`${name},`, "")
                .replace(`,${name}`, "")
                .replace(`${name}`, ""),
              functions: rule.functions,
              frequency: rule.frequency,
            },
          })
        );
      } else {
        dispatch(
          actionRule({
            id: id,
            typeAction: typeAction,
            rule: {
              criticality: rule.criticality,
              title: rule.title,
              message: rule.message,
              expression: newExpressionString,
              duration: rule.duration,
              variables: rule.variables,
              functions: rule.functions,
              frequency: rule.frequency,
            },
          })
        );
      }
    } else {
      dispatch(
        actionRule({
          id: id,
          typeAction: typeAction,
          rule: {
            criticality: rule.criticality,
            title: rule.title,
            message: rule.message,
            expression: newExpressionString,
            duration: rule.duration,
            variables: rule.variables,
            functions: rule.functions,
            frequency: rule.frequency,
          },
        })
      );
    }
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: "#fff",
    display: "flex",
    padding: 8,
    overflow: "auto",
    gap: 10,
  });

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    height: "32px",

    userSelect: "none",
    padding: "16px 6px",

    borderRadius: "4px",

    background: isDragging
      ? "linear-gradient(90deg, #5fc787 0%, #1cb1c4 100%)"
      : "#f5f5f5",
    border: isDragging ? "1px solid #fafafa" : "1px solid #c4c4c4",
    color: isDragging ? "#fff" : "#000",

    ...draggableStyle,
  });

  return (
    <Container>
      <div className="textarea">
        <div className="drag">
          {expressionArray.length > 0 ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="test" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    id="dragAndDrop"
                  >
                    {expressionArray.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Chip
                              label={item.name}
                              onDelete={() =>
                                handleDeleteItem(item.id, item.name)
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div></div>
          )}
        </div>
        <div className="buttons">
          <div className="double-button">
            <button onClick={() => addParentheses("(")}>(</button>
            <button onClick={() => addParentheses(")")}>)</button>
          </div>
          <button
            onClick={(e) => {
              if (rule.expression != null)
                navigator.clipboard.writeText(rule.expression);
            }}
          >
            <img src={copyImg} alt="" />
          </button>
          <button onClick={removeAllExpression}>
            <img src={trashImg} alt="" />
          </button>
        </div>
      </div>

      <div className="duration">
        <h3>Duração</h3>
        <div className="duration-input">
          <Input
            type="text"
            defaultValue={hour}
            maxLength={2}
            error={hourError}
            onChange={(e) => {
              handleTime(Number(e.target.value), "hour");
            }}
            disabled
          />
          <span>:</span>
          <Input
            type="text"
            defaultValue={min}
            maxLength={2}
            error={minError}
            onChange={(e) => {
              handleTime(Number(e.target.value), "min");
            }}
            disabled
          />
          <span>:</span>
          <Input
            type="text"
            defaultValue={seg}
            maxLength={2}
            error={segError}
            onChange={(e) => {
              handleTime(Number(e.target.value), "seg");
            }}
            disabled
          />
        </div>
        <div className="detail-input">
          <p>h</p>
          <p>min</p>
          <p>seg</p>
        </div>
      </div>
    </Container>
  );
};
