import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 10px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #08a8ba;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
  margin-bottom: 20px;

  thead {
    color: #fff;

    tr {
      border: 1px solid #cfcfcf;

      th {
        background-image: linear-gradient(to right, #19b0a0, #009ee2);
        padding: 3px;
        text-align: center;

        font-size: 1rem;

        border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;

        padding: 10px;
      }
    }
  }

  tbody {
    color: #333;

    tr {
      border: 1px solid #cfcfcf;
      text-align: center;

      td {
        padding: 7px;

        border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;

        padding: 10px;

        input {
          width: 100px;
          height: 20px;
          margin-right: 5px;

          text-align: center;
        }

        span {
          position: absolute;
        }
      }
    }
  }

  .title-table {
    font-size: 18px;
    padding: 10px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  div {
    display: flex;
    gap: 10px;
  }
`;

interface InputProps {
  error?: boolean;
}

export const Input = styled.input`
  width: 60px;
  height: 20px;

  text-align: center;

  border: ${(props: InputProps) =>
    props.error === false ? "1px solid #cfcfcf" : "1px solid red"};
`;
