import { Edit, Delete } from "@material-ui/icons";

import { HeaderData, RuleData } from "../../models/NotificationData";

import { Container, Criticidade, Options } from "./styles";
import { useDispatch } from "react-redux";
import { actionRule } from "../../store/Alert/actions";
import { useState } from "react";
import { Box, CircularProgress, Modal } from "@material-ui/core";

interface RulesTableProps {
  loading: boolean;
  openModal: () => void;
  rules: RuleData[];
  handleDeleteRule: (id: number) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const headers: HeaderData[] = [
  { id: 1, label: "ID" },
  { id: 2, label: "Criticidade" },
  { id: 3, label: "Expressão" },
  { id: 4, label: "Descrição" },
  { id: 5, label: "Mensagem" },
];

function createRow(notification: RuleData) {
  return [
    notification.id,
    criticidadeColor(notification.criticality),
    notification.expression,
    notification.title,
    notification.message,
  ];
}

function criticidadeColor(criticidade: string) {
  let color = "#FF0000";
  let criticidadeText = "Alta";
  if (criticidade === "Media") {
    color = "#FF6600";
    criticidadeText = "Média";
  } else if (criticidade === "Baixa") {
    color = "#009933";
    criticidadeText = "Baixa";
  }

  return (
    <Criticidade color={`${color}`}>
      <div />
      <p>{criticidadeText}</p>
    </Criticidade>
  );
}

export const RulesTable = ({
  loading,
  openModal,
  rules,
  handleDeleteRule,
}: RulesTableProps) => {
  const dispatch = useDispatch();

  const [expressionSelected, setExpressionSelected] = useState<string>("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) {
    return (
      <Container>
        <CircularProgress
          style={{
            color: "#4CA6A8",
            marginTop: "50px",
            marginBottom: "50px",
          }}
          size={50}
        />
      </Container>
    );
  }

  return (
    <Container>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.id}>{header.label}</th>
            ))}
          </tr>
        </thead>

        {rules.length > 0 && (
          <tbody>
            {rules.map((rule: RuleData) => (
              <tr key={rule.id}>
                {createRow(rule).map((cell) =>
                  cell === rule.expression ? (
                    <td className="expression">
                      <div>
                        <p
                          key={Math.random()}
                          onClick={() => {
                            setExpressionSelected(cell);
                            handleOpen();
                          }}
                          className="expression"
                        >
                          Fx
                        </p>
                      </div>
                    </td>
                  ) : (
                    <td
                      className={
                        cell === rule.message || rule.title ? "message" : ""
                      }
                      key={Math.random()}
                    >
                      {cell}
                    </td>
                  )
                )}
                <Options>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(
                        actionRule({
                          typeAction: "edit",
                          id: rule.id,
                          rule: {
                            criticality: rule.criticality,
                            title: rule.title,
                            message: rule.message,
                            expression: rule.expression,
                            duration: rule.duration,
                            functions: rule.functions,
                            variables: rule.variables,
                            frequency: rule.frequency
                          },
                        })
                      );
                      openModal();
                    }}
                  >
                    <Edit
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#7F8898",
                      }}
                    />
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteRule(rule.id);
                    }}
                  >
                    <Delete
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#7F8898",
                      }}
                    />
                  </button>
                </Options>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {rules.length === 0 && (
        <h1
          style={{
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          Nenhuma regra cadastrada.
        </h1>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>{expressionSelected}</Box>
      </Modal>
    </Container>
  );
};
