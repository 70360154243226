import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { ComponentData } from "../../../../models/ComponentData";

import { PciAndCoeficientTable } from "./components/PciAndCoeficientTable";
import { Entries } from "./components/Entries";
import { ResultSimulation } from "./components/ResultSimulation";
import {
  calculateItems,
  resultSimulation,
} from "../../../../store/Simulation/actions";

export const SimulationProcess = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  const dispatch = useDispatch();

  const [tabSimulation, setTabSimulation] = useState("");

  useEffect(() => {
    tabAccessed("SIMULAÇÃO");
    dispatch(resultSimulation(""));
    dispatch(calculateItems(true, true));
  }, [dispatch, tabAccessed]);

  const handleTabSimulation = (tab: string) => {
    setTabSimulation(tab);
  };

  if (tabSimulation === "entries") {
    return (
      <Entries handleTab={handleTabSimulation} componentName={componentName} />
    );
  }

  if (tabSimulation === "simulation") {
    return (
      <ResultSimulation
        handleTab={handleTabSimulation}
        componentName={componentName}
      />
    );
  }

  return (
    <PciAndCoeficientTable
      handleTab={handleTabSimulation}
      componentName={componentName}
    />
  );
};
