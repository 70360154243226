import React from "react";

import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { CircularProgress } from "@material-ui/core";

import { AppState } from "../../store/rootReducer";

import { Container } from "../../components/Loading";

export const Initial = () => {
  const { isAuth } = useSelector((state: AppState) => state.auth);

  return (
    <Container>
      <CircularProgress size={50} />
      <Redirect to={isAuth ? "/dashboard" : "/auth"} />
    </Container>
  );
};
