import { Dispatch } from "redux";
import { NotificationData, RuleData } from "../../models/NotificationData";
import {
  LIST_RULES,
  NotificationsAndRuleDispatchTypes,
  ActionRuleProps,
  ACTION_RULE,
  LIST_NOTIFICATIONS,
  SET_TYPE_NOTIFICATIONS,
} from "./types";

export const listNotifications = (alert: NotificationData[]) => {
  return (dispatch: Dispatch<NotificationsAndRuleDispatchTypes>) => {
    dispatch({
      type: LIST_NOTIFICATIONS,
      payload: alert,
    });
  };
};

export const setTypeNotifications = (type: string) => {
  return (dispatch: Dispatch<NotificationsAndRuleDispatchTypes>) => {
    dispatch({
      type: SET_TYPE_NOTIFICATIONS,
      payload: type,
    });
  };
};

export const listRules = (rules: RuleData[]) => {
  return (dispatch: Dispatch<NotificationsAndRuleDispatchTypes>) => {
    dispatch({
      type: LIST_RULES,
      payload: rules,
    });
  };
};

export const actionRule = (rule: ActionRuleProps) => {
  return (dispatch: Dispatch<NotificationsAndRuleDispatchTypes>) => {
    dispatch({
      type: ACTION_RULE,
      payload: rule,
    });
  };
};
