import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 45px;

  border: 0;
  border-radius: 8px;

  background: linear-gradient(90deg, #5FC787 0%, #1CB1C4 100%);

  color: #fff;
  font-size: 18px;
  font-weight: bold;

  margin: 15px 0;

  cursor: pointer;
`;