import React from "react";

import { createStyles, makeStyles } from "@material-ui/core";

import { Button, Group } from "./styles";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: "60px",
      height: "35px",
      backgroundColor: "rgba(230, 232, 235, 0.5)",
      color: "rgba(0, 0, 0, 0.5)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
    },
    buttonClicked: {
      width: "60px",
      height: "35px",
      background: "linear-gradient(95.44deg, #61C885 0.73%, #1AB1C5 100.24%)",
      color: "#fff",
      border: "1px solid #61C885",
      borderRadius: "5px",
    },
  })
);

interface BasePickerProps {
  baseClicked: string;
  handleBaseColor: (base: string) => void;
}

export const BasePicker = ({
  baseClicked,
  handleBaseColor,
}: BasePickerProps) => {
  const classes = useStyles();

  return (
    <>
      <Group>
        <Button
          className={
            baseClicked === "hora" ? classes.buttonClicked : classes.button
          }
          onClick={() => {
            handleBaseColor("hora");
          }}
        >
          Horário
        </Button>
        <Button
          className={
            baseClicked === "dia" ? classes.buttonClicked : classes.button
          }
          onClick={() => {
            handleBaseColor("dia");
          }}
        >
          Diário
        </Button>
        <Button
          className={
            baseClicked === "semana" ? classes.buttonClicked : classes.button
          }
          onClick={() => {
            handleBaseColor("semana");
          }}
        >
          Semanal
        </Button>
        <Button
          className={
            baseClicked === "mes" ? classes.buttonClicked : classes.button
          }
          onClick={() => {
            handleBaseColor("mes");
          }}
        >
          Mensal
        </Button>
      </Group>
    </>
  );
};
