import styled from "styled-components";

export const Tables = styled.div`
  display: flex;
  gap: 20px;

  margin-bottom: 20px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #7f8898;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
  margin-bottom: 20px;

  thead {
    color: #fff;

    tr {
      border: 1px solid #cfcfcf;

      th {
        background-image: linear-gradient(to right, #19b0a0, #009ee2);
        padding: 3px;
        text-align: center;

        font-size: 0.8rem;

        border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;
      }
    }
  }

  tbody {
    color: #333;

    tr {
      border: 1px solid #cfcfcf;

      th {
        padding: 7px;
        text-align: center;

        border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;

        font-size: 0.8rem;

        input {
          width: 30px;
          height: 20px;

          text-align: center;
        }
      }
    }
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
