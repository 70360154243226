import { TagProps } from "../../models/GraphicData";

export interface TagsAnalyzeState {
  tagsArray: TagProps[];
  tags1: TagProps[];
  tags2: TagProps[];
}

export const TAGS_RESULT = "TAGS_RESULT";
export const TAGS_SELECTED1 = "TAGS_SELECTED1";
export const TAGS_SELECTED2 = "TAGS_SELECTED2";

export interface TagsResult {
  type: typeof TAGS_RESULT;
  payload: TagProps[];
}

export interface TagsSelected1 {
  type: typeof TAGS_SELECTED1;
  payload: TagProps[];
}

export interface TagsSelected2 {
  type: typeof TAGS_SELECTED2;
  payload: TagProps[];
}

export type TagsAnalyzeTypes = TagsResult | TagsSelected1 | TagsSelected2;
