import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { getCurrentUser } from "./api/firebase";

import { authSuccess } from "./store/Auth/actions";

import { Router } from "./router";

import { GlobalStyle } from "./styles";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentUser().then((user) => {
      user !== null && dispatch(authSuccess(user));
    });
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <Router />
    </>
  );
};

export default App;
