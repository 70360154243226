import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";

import { Iframe } from "./styles";

export const GraphicSimulation = () => {
  const { entries } = useSelector((state: AppState) => state.simulation);

  const times = entries[0].table.map(({ cells }) => {
    return {
      cells: cells[0].value,
    };
  });

  const arrayTimes = times.map(({ cells }) => {
    const date = cells.split("/");
    return new Date(`${date[1]}/${date[0]}/${date[2]}`).getTime();
  });

  const maxTime = Math.max(...arrayTimes);
  const minTime = Math.min(...arrayTimes);

  return (
    <Iframe
      src={`${process.env.REACT_APP_GRAFANA_URL}/d/fbo9Y7S7k/simulationdashboard?orgId=2&from=${
        minTime === maxTime ? minTime - 1000 : minTime
      }&to=${maxTime === minTime ? maxTime + 1000 : maxTime}&kiosk&theme=light`}
    />
  );
};
