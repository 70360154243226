import {
  GraphicDispatchTypes,
  GraphicState,
  DateRange,
  SET_DATE,
  ERROR_SET_DATE,
} from "./types";

const initialState: GraphicState = {
  date: {} as DateRange,
  errorSetDate: "",
};

export const GraphicReducer = (
  state = initialState,
  action: GraphicDispatchTypes
) => {
  switch (action.type) {
    case SET_DATE: {
      return {
        date: {
          initial: action.payload.initial,
          final: action.payload.final,
        },
        errorSetDate: "",
      };
    }
    case ERROR_SET_DATE: {
      return {
        ...state,
        errorSetDate: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
