import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden auto;
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 20px;
`;
