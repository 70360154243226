import { useState } from "react";
import { useDispatch } from "react-redux";
import { setTypeNotifications } from "../../store/Alert/actions";
import { Button, Container } from "./styles";

export const NavigationTypeAlert = () => {
  const dispatch = useDispatch();
  const [typeSelected, setTypeSelected] = useState("Pendente");

  const handleTypeSelected = (type: string) => {
    setTypeSelected(type);
    dispatch(setTypeNotifications(type));
  };

  return (
    <Container>
      <Button
        active={typeSelected === "Pendente" && true}
        onClick={() => handleTypeSelected("Pendente")}
      >
        Pendente
      </Button>
      <Button
        active={typeSelected === "Em Analise" && true}
        onClick={() => handleTypeSelected("Em Analise")}
      >
        Em análise
      </Button>
      <Button
        active={typeSelected === "Encerrada" && true}
        onClick={() => handleTypeSelected("Encerrada")}
      >
        Encerrada
      </Button>
    </Container>
  );
};
