import React from "react";

import { Title } from "./styles";

export const InitialDashboard = () => {
  return (
    <Title>
      Navegue na árvore a esquerda e escolha o nível desejado!
    </Title>
  );
};
