export const lastWeekPeriodOfTime = () => {
    const currentDate = new Date();

    const initialDate = new Date(currentDate);
    initialDate.setDate(currentDate.getDate() - 6);

    initialDate.setHours(0,0,0,0)


    const date = new Date(initialDate).getTime() / 1000;
    const day = new Date(initialDate).getDay();

    const initDate = date - day * 86400;
    const finalDate = initDate + 604800;

    return [initDate, finalDate]
}