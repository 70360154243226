import { TreeData } from "../../models/TreeData";

export interface TreeState {
  tree: TreeData[];
}

export const TREE = "TREE";

export interface TreeResult {
  type: typeof TREE;
  payload: TreeData[];
}

export type TreeDispatchTypes = TreeResult;
