import styled, { createGlobalStyle } from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export const GlobalStyle = createGlobalStyle`
  body {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.14),
        rgba(0, 0, 0, 0.25) 33%,
        rgba(58, 58, 58, 0.93)
      ),
      linear-gradient(#616161, #616161);

      @media ${supportedDevices.laptop} {
        background-color: #e6e8eb;
        background-image: none;
      }
  }
`;

export const Container = styled.section`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  .inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .outside-logo {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 20px;

      img {
        width: 180px;
      }

      @media ${supportedDevices.laptop} {
        display: none;
      }
    }

    .outside-logo2 {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      img {
        width: 180px;
      }

      @media ${supportedDevices.laptop} {
        display: none;
      }
    }

    .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      min-height: 60vh;
      border-radius: 10px;
      background-color: white;
      padding: 20px 0px;

      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

      @media ${supportedDevices.laptop} {
        justify-content: flex-start;
        height: 80vh;
        width: 60vw;
        padding: 0;
      }
    }
  }
`;

export const LogoContainer = styled.div`
  display: none;

  @media ${supportedDevices.laptop} {
    display: flex;
    height: 100%;
    padding: 0px 40px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.14),
        rgba(0, 0, 0, 0.25) 33%,
        rgba(58, 58, 58, 0.93)
      ),
      linear-gradient(#616161, #616161);
    flex-flow: column;
    justify-content: center;

    img {
      width: 240px;
    }
  }

  @media ${supportedDevices.laptopL} {
    padding: 0px 80px;

    img {
      width: 320px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 30px;
  width: 80vw;

  @media ${supportedDevices.laptop} {
    padding: 0 60px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 15px;

  Button {
    height: 40px;
    font-size: 12px;
    width: 100%;

    @media ${supportedDevices.laptop} {
      font-size: 14px;
    }
  }

  @media ${supportedDevices.laptop} {
    margin-top: 20px;
  }
`;
