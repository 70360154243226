import { Box, CircularProgress, Modal } from "@material-ui/core";
import { Comment } from "@material-ui/icons";
import { useState } from "react";

import { HeaderData, NotificationData } from "../../models/NotificationData";
import { formatDateTimeAlerts } from "../../utils/formatDateToDateRange";

import { Criticidade } from "../RulesTable/styles";
import { Container } from "./styles";

interface AlertTableProps {
  notifications: NotificationData[];
  loading: boolean;
  onClickSelect: (id: number, select: string, comment: string) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 230,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const headers: HeaderData[] = [
  { id: 1, label: "ID" },
  { id: 2, label: "Criticidade" },
  { id: 3, label: "Profile" },
  { id: 4, label: "Descrição" },
  { id: 5, label: "Primeira Ocorrência" },
  { id: 6, label: "Última Ocorrência" },
  { id: 7, label: "Comentário" },
  { id: 8, label: "ID Regra" },
  { id: 9, label: "Status" },
];

function createRow(notification: NotificationData) {
  return [
    notification.id,
    criticidadeColor(notification.rule.criticality),
    "Caldeiras",
    notification.rule.title,
    formatDateTimeAlerts(notification.createdTimestamp),
    formatDateTimeAlerts(notification.lastNotificationTimestamp),
    notification.comment,
    notification.rule.id,
    notification.status,
  ];
}

function criticidadeColor(criticidade: string) {
  let color = "#FF0000";
  let criticidadeText = "Alta";
  if (criticidade === "Media") {
    color = "#FF6600";
    criticidadeText = "Média";
  } else if (criticidade === "Baixa") {
    color = "#009933";
    criticidadeText = "Baixa";
  }

  return (
    <Criticidade color={`${color}`}>
      <div />
      <p>{criticidadeText}</p>
    </Criticidade>
  );
}

interface CommentAndIdProps {
  id: number;
  comment: string;
  status: string;
}

export const AlertTable = ({
  loading,
  notifications,
  onClickSelect,
}: AlertTableProps) => {
  const [commentAndIdNotification, setCommentAndIdNotification] =
    useState<CommentAndIdProps>({} as CommentAndIdProps);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) {
    return (
      <Container>
        <CircularProgress
          style={{
            color: "#4CA6A8",
            marginTop: "50px",
            marginBottom: "50px",
          }}
          size={50}
        />
      </Container>
    );
  }

  return (
    <Container>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.id}>{header.label}</th>
            ))}
          </tr>
        </thead>

        {notifications.length > 0 && (
          <tbody>
            {notifications.map((notification) => (
              <tr key={notification.id}>
                {createRow(notification).map((row) => (
                  <>
                    {row === notification.comment ? (
                      <td
                        key={Math.random()}
                        onClick={() => {
                          setCommentAndIdNotification({
                            comment:
                              notification.comment === null
                                ? ""
                                : notification.comment,
                            id: notification.id,
                            status: notification.status,
                          });
                          handleOpen();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Comment
                          style={{
                            color:
                              notification.comment === "" ? "#8d8d8d" : "#000",
                          }}
                        />
                      </td>
                    ) : row === notification.status ? (
                      <td key={Math.random()}>
                        <select
                          onChange={(e) => {
                            onClickSelect(
                              notification.id,
                              e.target.value,
                              notification.comment
                            );
                          }}
                          defaultValue={notification.status}
                        >
                          <option
                            selected={notification.status === "Pendente"}
                            value="Pendente"
                          >
                            Pendente
                          </option>
                          <option
                            selected={notification.status === "Em Analise"}
                            value="Em Analise"
                          >
                            Em Analise
                          </option>
                          <option
                            selected={notification.status === "Encerrada"}
                            value="Encerrada"
                          >
                            Encerrada
                          </option>
                        </select>
                      </td>
                    ) : (
                      <td key={Math.random()}>{row}</td>
                    )}
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {notifications.length === 0 && (
        <h1
          style={{
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          Nenhuma notificação encontrada.
        </h1>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "20px",
            }}
          >
            Comentário
          </p>
          <textarea
            defaultValue={commentAndIdNotification.comment}
            onChange={(e) =>
              setCommentAndIdNotification({
                comment: e.target.value,
                id: commentAndIdNotification.id,
                status: commentAndIdNotification.status,
              })
            }
            style={{
              width: "100%",
              height: "80px",
              marginBottom: "20px",
              resize: "none",
              padding: "10px",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <button
              style={{
                minWidth: "86px",
                padding: "6px 16px",
                background: "rgb(72, 72, 72)",
                color: "#fff",
                fontSize: "14px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              CANCELAR
            </button>
            <button
              style={{
                minWidth: "86px",
                padding: "6px 16px",
                backgroundImage:
                  "linear-gradient(to right, rgb(25, 176, 160), rgb(0, 158, 226))",
                color: "#fff",
                fontSize: "14px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                onClickSelect(
                  commentAndIdNotification.id,
                  commentAndIdNotification.status,
                  commentAndIdNotification.comment
                );
                handleClose();
              }}
            >
              SALVAR
            </button>
          </div>
        </Box>
      </Modal>
    </Container>
  );
};
