import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

export const auth = firebase.auth();

export async function getCurrentUser() {
  return new Promise((resolve) =>
    auth.onAuthStateChanged((user) => resolve(user || null))
  );
}

export async function getTokenFromFirebase() {
  return new Promise((resolve) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = auth.currentUser?.getIdToken(false);
        resolve(await idToken);
      }
    });
  });
}

export async function passwordReset(email: string) {
  return auth.sendPasswordResetEmail(email);
}
