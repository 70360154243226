import React from "react";

import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { EquipmentDashboard } from "../EquipmentDashboard";
import { InitialDashboard } from "../InititalDashboard";
import { ProcessDashboard } from "../ProcessDashboard";

export const RouterDashboard = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/initial`} component={InitialDashboard} />
      <Route path={`${match.path}/processo`} component={ProcessDashboard} />
      <Route
        path={`${match.path}/equipamento`}
        component={EquipmentDashboard}
      />
      <Redirect exact from={`${match.path}`} to={`${match.path}/initial`} />
    </Switch>
  );
};
