import styled from "styled-components";

interface HeightPaddingCenterView {
  height: string;
}

export const Container = styled.section`
  background: #d4d4d4;
  width: 100%;
  height: 100%;
`;

export const CenterView = styled.section<HeightPaddingCenterView>`
  width: 100%;
  height: ${({ height }) => `calc(100% - ${height})`};

  display: flex;
  gap: 10px;
  padding: 10px;
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9ca8ba;
    border-radius: 6px;
    margin-left: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9ca8ba;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px 0px #f0f0f0;
  }
`;
