import { ReactNode, useEffect, useState } from "react";

import ReactModal from "react-modal";

interface ModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  children: ReactNode;
}

export const Modal = ({ isOpen, setIsOpen, children }: ModalProps) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      isOpen={modalStatus}
      onRequestClose={setIsOpen}
      ariaHideApp={false}
      style={{
        content: {
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-550px",
          marginTop: "-300px",
          width: "1100px",
          height: "605px",
          overflow: "auto",
          padding: "10px"
        },
        overlay: {
          backgroundColor: "#121214e6",
        },
      }}
    >
      {children}
    </ReactModal>
  );
};
