import styled from "styled-components";

import { supportedDevices } from "../../../../styles/supportedDevices";

export const LoginTitle = styled.h1`
  color: black;
  font-size: 20px;
  font-weight: bold;

  @media ${supportedDevices.laptop} {
    font-size: 32px;
  }
`;
