import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { DeleteOutlineOutlined } from "@material-ui/icons";

import { SimulationDataCSV } from "../../models/SimulationData";

import { entriesValue } from "../../store/Simulation/actions";

import {
  headerTable,
  bodyTable,
  formatDateScenery,
  defaultValueDateScenery,
  commaToDot,
} from "../../utils/entriesTable";

import { GradientButton } from "../GradientButton";

import { Container, Title, Table } from "./styles";
import { AppState } from "../../store/rootReducer";
import { EntriesTable } from "../../models/TableData";

interface TableEntriesProps {
  serie: SimulationDataCSV[];
  haveScenerys: (value: boolean) => void;
  handleScenerys: (value: EntriesTable[]) => void;
}

export const TableEntries = ({
  serie,
  haveScenerys,
  handleScenerys,
}: TableEntriesProps) => {
  const dispatch = useDispatch();

  const { entries } = useSelector((state: AppState) => state.simulation);

  const [scenerys, setScenerys] = useState<EntriesTable[]>(entries[0].table);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (serie.length > 0) {
      const serieArray = [];
      for (let i = 0; i < serie.length; i++) {
        if (serie[i].Tempo !== "") {
          serieArray.push({
            name: `Cenário ${Math.random()}`,
            cells: [
              {
                name: "Tempo",
                value: serie[i].Tempo,
              },
              {
                name: "C1VAZAOGCO",
                value: commaToDot(serie[i].Caldeira1GCO),
              },
              {
                name: "C1VAZAOGAF",
                value: commaToDot(serie[i].Caldeira1GAF),
              },
              {
                name: "C1VAZAOGAC",
                value: commaToDot(serie[i].Caldeira1GAC),
              },
              {
                name: "C1VAZAOOLEO/GN",
                value: commaToDot(serie[i].Caldeira1OLEO),
              },
              {
                name: "C2VAZAOGCO",
                value: commaToDot(serie[i].Caldeira2GCO),
              },
              {
                name: "C2VAZAOGAF",
                value: commaToDot(serie[i].Caldeira2GAF),
              },
              {
                name: "C2VAZAOGAC",
                value: commaToDot(serie[i].Caldeira2GAC),
              },
              {
                name: "C2VAZAOOLEO/GN",
                value: commaToDot(serie[i].Caldeira2OLEO),
              },
              {
                name: "C3VAZAOGCO",
                value: commaToDot(serie[i].Caldeira3GCO),
              },
              {
                name: "C3VAZAOGAF",
                value: commaToDot(serie[i].Caldeira3GAF),
              },
              {
                name: "C3VAZAOGAC",
                value: commaToDot(serie[i].Caldeira3GAC),
              },
              {
                name: "C3VAZAOOLEO/GN",
                value: commaToDot(serie[i].Caldeira3OLEO),
              },
              {
                name: "C4VAZAOGCO",
                value: commaToDot(serie[i].Caldeira4GCO),
              },
              {
                name: "C4VAZAOGAF",
                value: commaToDot(serie[i].Caldeira4GAF),
              },
              {
                name: "C4VAZAOGAC",
                value: commaToDot(serie[i].Caldeira4GAC),
              },
              {
                name: "C4VAZAOOLEO/GN",
                value: commaToDot(serie[i].Caldeira4OLEO),
              },
            ],
          });
        }
      }
      handleLoaderTable();
      setScenerys(serieArray);
    }
  }, [serie]);

  const handleNewScenery = () => {
    const date = new Date().setSeconds(0);
    const dateFinal = new Date(date).toLocaleString().split(":");

    setScenerys([
      ...scenerys,
      { name: `Cenário ${Math.random()}`, cells: bodyTable(dateFinal) },
    ]);
  };

  const handleLoaderTable = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const handleDeleteScenery = (nameScenery: string) => {
    const newScenerys = scenerys.filter(
      (scenery) => scenery.name !== nameScenery
    );
    setScenerys(newScenerys);
  };

  const handleSceneryValue = (
    value: string,
    name: string,
    sceneryName: string
  ) => {
    if (name === "Tempo") {
      setScenerys(
        scenerys.map((item: any) => {
          if (item.name === sceneryName) {
            return {
              name: item.name,
              cells: item.cells.map((cell: any) => {
                if (cell.name === name) {
                  return {
                    name: cell.name,
                    value: formatDateScenery(value),
                  };
                }
                return cell;
              }),
            };
          }
          return item;
        })
      );
    } else {
      setScenerys(
        scenerys.map((item: any) => {
          if (item.name === sceneryName) {
            return {
              name: item.name,
              cells: item.cells.map((cell: any) => {
                if (cell.name === name) {
                  return {
                    name: cell.name,
                    value:
                      value === ""
                        ? "0"
                        : value.includes(",")
                        ? commaToDot(value)
                        : value,
                  };
                }
                return cell;
              }),
            };
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    handleScenerys(scenerys);
    dispatch(entriesValue(scenerys));
    scenerys.length === 0 ? haveScenerys(false) : haveScenerys(true);
  }, [dispatch, handleScenerys, haveScenerys, scenerys]);

  if (loader) {
    return (
      <Container>
        <Title>Carregando...</Title>
      </Container>
    );
  }

  return (
    <Container>
      <Title>VAZÃO</Title>
      <Table>
        <thead>
          <tr>
            <th rowSpan={2}>Tempo</th>
            {headerTable.map((item, index) => (
              <th key={index} colSpan={4}>
                {item.caldeira}
              </th>
            ))}
            <th rowSpan={2}>Acão</th>
          </tr>
          <tr>
            {headerTable.map((item) =>
              item.fuel.map((fuel, index) => <th key={index}>{fuel}</th>)
            )}
          </tr>
        </thead>
        <tbody>
          {scenerys.map((item) => (
            <tr key={item.name}>
              {item.cells.map((cell, index) => (
                <td key={index}>
                  {cell.name === "Tempo" ? (
                    <input
                      type="datetime-local"
                      defaultValue={defaultValueDateScenery(cell.value)}
                      name={cell.name}
                      onChange={(e) =>
                        handleSceneryValue(
                          e.target.value,
                          e.target.name,
                          item.name
                        )
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      defaultValue={cell.value}
                      name={cell.name}
                      onChange={(e) => {
                        handleSceneryValue(
                          e.target.value,
                          e.target.name,
                          item.name
                        );
                      }}
                    />
                  )}
                </td>
              ))}
              <td onClick={() => handleDeleteScenery(item.name)}>
                <DeleteOutlineOutlined
                  style={{ color: "#D12C38", cursor: "pointer" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <GradientButton
        onClick={() => {
          handleNewScenery();
        }}
        style={{
          fontSize: ".7rem",
        }}
      >
        Adicionar cenário
      </GradientButton>
    </Container>
  );
};
