import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: space-between;

  }
`;

export const Logo = styled.div`
  color: #fff;
  height: 30px;
  margin-left: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  div {
    display: flex;
    gap: 10px;
  }
`;

export const ButtonClose = styled.button`
  width: 44px;
  height: 37px;
  margin-right: 20px;

  font-size: 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  background-image: linear-gradient(to right, #19b0a0, #009ee2);

  cursor: pointer;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
  margin-bottom: 20px;

  thead {
    color: #fff;
    border-radius: 30px;

    tr {
      border: 1px solid #ffffff;
      border-radius: 30px;

      th {
        background: none;
        padding: 3px;
        text-align: center;

        font-size: 1.2rem;

        border: 1px solid #ffffff;
        font-weight: 600;
      }

      .comb {
        width: 120px;
      }

      .param {
        width: 280px;
      }

      .totalFlow {
        width: 300px;
      }
    }
  }

  tbody {
    color: #ffffff;

    tr {
      border: 1px solid #ffffff;

      td {
        padding: 7px;
        text-align: center;
        border: 1px solid #ffffff;

        font-weight: 300;

        font-size: 20px;
      }

      .prioridade {
        font-weight: 700;
        font-size: 20px;
      }

      .combInfo {
        background-color: #4b4848;
      }

      .numbers {
        font-size: 24px;
        padding: 5px;
      }
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 1.5rem;
`;

export const Info = styled.div`
  width: 100%;
  height: 44px;
  background: #4b4848;
  border: 1px solid #ffffff;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  text-align: center;

  margin: 0 auto;

  p {
    width: 100%;
    text-align: center;
  }
`;
