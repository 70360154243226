import styled from "styled-components";

export interface HorizontalCollapseProps {
  width: string;
  duration?: number;
  curve?: string;
}

export const HorizontalCollapse = styled.div`
  margin: 0 auto;
  width: ${(props: HorizontalCollapseProps) => props.width};
  transition: width
    ${(props: HorizontalCollapseProps) =>
      `${props.duration || "300ms"} ${props.curve || "ease-in-out"}`};
`;
