import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { CircularProgress, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ArrowBack } from "@material-ui/icons";

import { passwordReset } from "../../../../api/firebase";

import { InputError } from "../../../../validations/typeValidation";

import { HorizontalCollapse } from "../../../../components/Animations";
import { CustomTextField } from "../../../../components/CustomTextField";
import { GradientButton } from "../../../../components/GradientButton";
import { Spacer } from "../../../../components/Spacer";
import { useStyles } from "../../../../components/AlertError";

import { ButtonsContainer } from "../../styles";
import { Title, ForgotPassMsg } from "./styles";

interface ErrorSubmit {
  message: string;
}

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const classesError = useStyles();

  const [email, setEmail] = useState("");

  const [error, setError] = useState<InputError>({} as InputError);

  const [errorSubmit, setErrorSubmit] = useState<ErrorSubmit>({
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  function goBack() {
    history.goBack();
  }

  function handleChange(
    _: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setEmail(event.target.value);
  }

  async function requestNewPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (email.length > 0) {
      setIsLoading(true);
      try {
        await passwordReset(email);
        setErrorSubmit({ message: "" });
        goBack();
      } catch (e) {
        setErrorSubmit({
          message:
            "Não foi possível encaminhar o e-mail, verifique se ele realmente está cadastrado.",
        });
      }
      setIsLoading(false);
    } else
      setError({
        field: "email",
        message: "Digite seu email!",
      });
    setIsLoading(false);
  }

  return (
    <>
      <form onSubmit={requestNewPassword} data-testid="forgotPassForm">
        <Title>
          <IconButton onClick={goBack} data-testid="backButton">
            <ArrowBack fontSize="large" />
          </IconButton>
          <h1>Recuperar Senha</h1>
        </Title>
        <ForgotPassMsg>Insira seu email cadastrado</ForgotPassMsg>
        <CustomTextField
          name="email"
          label="Email"
          placeholder="example@email.com"
          handleChange={handleChange}
          type="email"
          value={email}
          error={error?.message}
          isDisabled={isLoading}
          cleanError={() => setError({} as InputError)}
        />
        <ButtonsContainer>
          <HorizontalCollapse width={isLoading ? "20%" : "100%"}>
            <GradientButton type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress color="secondary" size={25} />
              ) : (
                "enviar"
              )}
            </GradientButton>
          </HorizontalCollapse>
          <Spacer height={15} />
        </ButtonsContainer>
      </form>
      {errorSubmit.message === "" ? (
        ""
      ) : (
        <div className={classesError.root}>
          <Alert variant="filled" severity="error">
            {errorSubmit.message}
          </Alert>
        </div>
      )}
    </>
  );
};
