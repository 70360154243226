import React from "react";

import { Container } from "./styles";

interface LinkProps {
  title: string;
  checked: boolean;
  clickLink: () => void;
}

export const Link = ({ title, checked, clickLink }: LinkProps) => {
  return (
    <Container onClick={() => clickLink()} active={checked}>
      {title}
    </Container>
  );
};
