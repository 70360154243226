import firebase from "firebase";
import { Dispatch } from "redux";
import { auth } from "../../api/firebase";

import {
  AuthDispatchTypes,
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  AUTH_SUCCESS,
  SIGNOUT_SUCCESS,
} from "./types";

export const signIn = (email: string, password: string) => {
  return (dispatch: Dispatch<AuthDispatchTypes>) => {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          dispatch({
            type: SIGNIN_SUCCESS,
            payload: result.user,
          });
        })
        .catch((error) => {
          dispatch({
            type: SIGNIN_FAILED,
            payload: error.message,
          });
        });
    });
  };
};

export const authSuccess = (user: any) => {
  return (dispatch: Dispatch<AuthDispatchTypes>) => {
    dispatch({
      type: AUTH_SUCCESS,
      payload: user,
    });
  };
};

export const signOut = () => {
  return (dispatch: Dispatch<AuthDispatchTypes>) => {
    auth.signOut().then(() =>
      dispatch({
        type: SIGNOUT_SUCCESS,
        payload: false,
      })
    );
  };
};
