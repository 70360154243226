import React, { useEffect } from "react";

import { ComponentData } from "../../../../models/ComponentData";

import { Container, Title } from "./styles";

export const FailurePredictionEquipment = ({
  componentName,
  tabAccessed,
}: ComponentData) => {
  useEffect(() => {
    tabAccessed("PREDIÇÃO DE FALHAS");
  });

  return (
    <Container>
      <Title>{componentName}</Title>
      Predição de Falhas (Em desenvolvimento)
    </Container>
  );
};
