import { Dispatch } from "redux";

import { TreeData } from "../../models/TreeData";

import {
  VisualizesDispatchTypes,
  NODE_VISUALIZE,
  TAB_VISUALIZE,
} from "./types";

export const showVisualize = (node: TreeData) => {
  return (dispatch: Dispatch<VisualizesDispatchTypes>) => {
    dispatch({
      type: NODE_VISUALIZE,
      payload: node,
    });
  };
};

export const showTab = (tab: string) => {
  return (dispatch: Dispatch<VisualizesDispatchTypes>) => {
    dispatch({
      type: TAB_VISUALIZE,
      payload: tab,
    });
  };
};
