import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { dateDecisionMaking } from "../../../../../../store/DecisionMaking/actions";

import { SimulationDatePicker } from "../../../../../../components/SimulationDatePicker";
import { GradientButton } from "../../../../../../components/GradientButton";
import { TablesDecisionMaking1 } from "../../../../../../components/TablesDecisionMaking1";
import { TablesDecisionMaking2 } from "../../../../../../components/TablesDecisionMaking2";

import {
  Container,
  Filter,
  Title,
  Buttons,
  TitleDecisionMaking,
} from "./styles";
import { lastWeekPeriodOfTime } from "../../../../../../utils/dateManager";

interface TemplateConfigurationProps {
  componentName: string;
  handleTab: (value: string) => void;
}

export const TemplateConfiguration = ({
  componentName,
  handleTab,
}: TemplateConfigurationProps) => {
  const dispatch = useDispatch();

  const initialState = "InitialDate";
  const [dateClicked, setDateClicked] = useState(initialState);

  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    const currentDate = new Date();

    const arrayDatePeriod =  lastWeekPeriodOfTime();

    dispatch(dateDecisionMaking(arrayDatePeriod[0].toString(), arrayDatePeriod[1].toString()));
  }, [dispatch]);

  const handleDatePicker = (value: string) => {
    const arrayDatePeriod = lastWeekPeriodOfTime();

    dispatch(dateDecisionMaking(arrayDatePeriod[0].toString(), arrayDatePeriod[1].toString()));
  };

  const handleDateColor = (value: string) => {
    setDateClicked(value);
  };

  const handleTransfer = () => {
    setLoader2(true);
    setTimeout(() => {
      setLoader2(false);
    }, 1000);
  };

  const handleLoader = (value: boolean) => {
    setLoader(value);
  };

  return (
    <Container>
      <Filter>
        <Title>{componentName}</Title>
        <SimulationDatePicker
          handleDatePicker={handleDatePicker}
          handleDateColor={handleDateColor}
          dateClicked={dateClicked}
        />
      </Filter>

      <TitleDecisionMaking>CONFIGURAÇÃO DO MODELO</TitleDecisionMaking>

      <TablesDecisionMaking1 handleLoader={handleLoader} loader2={loader2} />
      <GradientButton
        style={{ width: "100%" }}
        disabled={loader}
        onClick={() => {
          handleTransfer();
        }}
      >
        Transferir
      </GradientButton>
      <TablesDecisionMaking2 loader={loader2} />
      <Buttons>
        <GradientButton onClick={() => handleTab("otimização")}>
          Avançar
        </GradientButton>
      </Buttons>
    </Container>
  );
};
