import { useSelector } from "react-redux";

import { AppState } from "../../store/rootReducer";

import { Modal } from "../Modal";
import { CreateAlert } from "../CreateAlert";
import { PreviewAlert } from "../PreviewAlert";

import { Container } from "./styles";
import { CreateExpression } from "../CreateExpression";
import { ExpressionAndDuration } from "../ExpressionAndDuration";
import { useState } from "react";
import { ErrorAlert } from "../ErrorAlert";
import { RuleActionData } from "../../models/NotificationData";
import { PreviewExpression } from "../PreviewExpression";

interface ModalAddAlertProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleActionRule: (type: string, rule: RuleActionData, id?: number) => void;
}

export const ModalAddAlert = ({
  setIsOpen,
  isOpen,
  handleActionRule,
}: ModalAddAlertProps) => {
  const { actionRule } = useSelector(
    (state: AppState) => state.notificationRule
  );

  const [openRuleExpression, setOpenRuleExpression] = useState(false);
  const [openRuleMessage, setOpenRuleMessage] = useState(false);
  const [openRuleDuration, setOpenRuleDuration] = useState(false);

  const handleSubmit = async (type: string) => {
    const duration = actionRule.rule.duration.split(":");
    if (Number(duration[1]) > 59 || Number(duration[2]) > 59) {
      setOpenRuleDuration(true);
      return;
    } else if (actionRule.rule.expression === "") {
      setOpenRuleExpression(true);
      return;
    } else if (actionRule.rule.message === "" || actionRule.rule.title === "") {
      setOpenRuleMessage(true);
      return;
    } else {
      if (type === "add") {
        await handleActionRule("add", actionRule.rule);
      } else {
        await handleActionRule("update", actionRule.rule, actionRule.id);
      }
      setIsOpen();
    }
  };

  const handleAlertClose = (type: string) => {
    if (type === "duration") {
      setOpenRuleDuration(false);
    } else if (type === "alert") {
      setOpenRuleMessage(false);
    } else if (type === "expression") {
      setOpenRuleExpression(false);
    }
  };

  return (
    <Modal setIsOpen={setIsOpen} isOpen={isOpen}>
      <Container>
        <div className="expression">
          <CreateExpression />
          <ExpressionAndDuration />
        </div>

        <div className="previewExpression">
          <PreviewExpression />
        </div>

        <div className="alert">
          <CreateAlert />
          <PreviewAlert />
        </div>

        <button onClick={() => handleSubmit(actionRule.typeAction)}>
          {actionRule.typeAction === "edit"
            ? "EDITAR REGRA"
            : "CADASTRAR REGRA"}
        </button>
      </Container>

      {openRuleExpression && (
        <ErrorAlert
          open={openRuleExpression}
          setOpenAlert={() => handleAlertClose("expression")}
          message="A expressão deve ser preenchida corretamente, por favor, preencha a expressão."
        />
      )}

      {openRuleDuration && (
        <ErrorAlert
          open={openRuleDuration}
          setOpenAlert={() => handleAlertClose("duration")}
          message="O tempo deve ser preenchido corretamente, por favor, preencha o tempo."
        />
      )}

      {openRuleMessage && (
        <ErrorAlert
          open={openRuleMessage}
          setOpenAlert={() => handleAlertClose("alert")}
          message="O título e a mensagem devem ser preenchidos corretamente, por favor, preencha o título e a mensagem."
        />
      )}
    </Modal>
  );
};
