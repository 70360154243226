import { UserData } from "../../models/UserData";
import {
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  AUTH_SUCCESS,
  AuthDispatchTypes,
  AuthState,
  SIGNOUT_SUCCESS,
} from "./types";

const initialState: AuthState = {
  user: {} as UserData,
  isAuth: false,
  errorMessage: "",
  error: false,
};

export const AuthReducer = (
  state = initialState,
  action: AuthDispatchTypes
) => {
  switch (action.type) {
    case SIGNIN_SUCCESS: {
      return {
        ...state,
        user: { email: action.payload.email, uid: action.payload.uid },
        isAuth: true,
        error: false,
        errorMessage: "",
      };
    }
    case SIGNIN_FAILED: {
      return {
        ...state,
        isAuth: false,
        user: { email: "", uid: "" },
        error: true,
        errorMessage: action.payload,
      };
    }
    case AUTH_SUCCESS: {
      return {
        ...state,
        user: { email: action.payload.email, uid: action.payload.uid },
        isAuth: true,
        error: false,
        errorMessage: "",
      };
    }
    case SIGNOUT_SUCCESS: {
      return {
        user: {},
        isAuth: action.payload,
        error: false,
        errorMessage: "",
      };
    }
    default: {
      return state;
    }
  }
};
